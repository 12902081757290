import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import FavoriteList from './FavoritesList'
import SearchResultList from '../SearchResultList'
import OnlyOnline from '../../components/OnlyOnline'
import { connect } from 'react-redux'
import withApollo from 'react-apollo/withApollo'
import SubscriptionBox from '../../components/SubscriptionBox'
import Query from 'react-apollo/Query'
import { userQueries } from '../../graphql/queries'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    background: '#F7F7F7',
    boxSizing: 'border-box',
    paddingBottom: 15,
  },
  title: {
    color: '#505152',
    padding: 8,
    borderTop: '1px solid #e8e8e8',
    borderBottom: '1px solid #e8e8e8',
    paddingLeft: 22,
  },
})

class SearchResults extends Component {
  constructor(props) {
    super(props)
    this.client = null
    this.state = {
      hideByExpiration: false,
    }
  }

  hideByExpirationSignature = () => {
    this.setState({ hideByExpiration: true })
  }

  constructDate = (stringData) => {
    let arrData = stringData.split('/')
    let stringFormatada = arrData[1] + '-' + arrData[0] + '-' + arrData[2]
    return stringFormatada
  }

  render() {
    const { classes, searchTerm } = this.props
    const { hideByExpiration } = this.state
    return (
      <OnlyOnline>
        <Query query={userQueries.GET_USER} fetchPolicy={'cache-and-network'}>
          {({ loading, error, data = {} }) => {
            const { user = {} } = data

            if (user.expirationDate) {
              let dataExpiracao = user.expirationDate
              let dataAtual = new Date()
              if (dataAtual > dataExpiracao) {
                this.hideByExpirationSignature()
              }
            }

            if (!searchTerm) {
              return (
                <div className={classes.root}>
                  {!hideByExpiration && user.status === 'trial' ? (
                    <SubscriptionBox daysRemaining={user.daysRemaining} />
                  ) : null}
                  <Typography className={classes.title} variant={'title'}>
                    Favoritos
                  </Typography>
                  <FavoriteList />
                </div>
              )
            } else {
              return (
                <div className={classes.root}>
                  {!hideByExpiration && user.status === 'trial' ? (
                    <SubscriptionBox daysRemaining={user.daysRemaining} />
                  ) : null}
                  <SearchResultList />
                </div>
              )
            }
          }}
        </Query>
      </OnlyOnline>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    searchTerm: state.search.term,
  }
}

const mapDispatchToProps = () => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withApollo(SearchResults)))
