import React from 'react'
import BaseQuote from './BaseQuote'

const Vancouver = React.memo(props => {
  const { contentId, onConfirm } = props

  const label = 'Vancouver'
  const successMessage = 'Citação padrão Vancouver copiada com sucesso'

  return (
    <BaseQuote
      label={label}
      content={contentId}
      type={label.toLowerCase()}
      successMessage={successMessage}
      onConfirm={onConfirm}
    />
  )
})

export default Vancouver
