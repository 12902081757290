import React, { Component } from 'react'
import withRouter from 'react-router-dom/withRouter'
import Route from 'react-router-dom/Route'
import { AnimatedSwitch } from 'react-router-transition'
import {
  Login,
  NotFound,
  Content,
  Layout,
  Profile,
  DirectAuth,
  CidNoAuth,
  CovidNoAuth,
  CovidContent,
  Walkthrough
} from '../containers'
import PrivateRoute from '../components/PrivateRoute'

const bounceTransitionGo = {
  // start in a transparent, uptranslated state
  atEnter: {
    translate: -100
  },
  // leave in a transparent, downtranslated state
  atLeave: {
    translate: 100
  },
  // and rest at an opaque, normally-translated state
  atActive: {
    translate: 0
  }
}

const bounceTransitionBack = {
  // start in a transparent, uptranslated state
  atEnter: {
    translate: 100
  },
  // leave in a transparent, downtranslated state
  atLeave: {
    translate: -100
  },
  // and rest at an opaque, normally-translated state
  atActive: {
    translate: 0
  }
}

const opacityTransition = {
  // start in a transparent, uptranslated state
  atEnter: {
    opacity: 0,
    translate: 0
  },
  // leave in a transparent, downtranslated state
  atLeave: {
    opacity: 0,
    translate: 0
  },
  // and rest at an opaque, normally-translated state
  atActive: {
    opacity: 1,
    translate: 0
  }
}

function mapStyles(styles) {
  return {
    opacity: styles.opacity ? styles.opacity : 1,
    transform: `translateX(${styles.translate}%)`
  }
}

class AppRoutes extends Component {
  render() {
    const { location } = this.props

    const getAnimationTransition = pathname => {
      if (pathname.includes('login')) {
        return opacityTransition
      }
      return location.pathname.includes('conteudo') || location.pathname.includes('meu-perfil')
        ? bounceTransitionBack
        : bounceTransitionGo
    }

    const { atEnter, atLeave, atActive } = getAnimationTransition(location.pathname)

    return (
      <AnimatedSwitch
        atEnter={atEnter}
        atLeave={atLeave}
        atActive={atActive}
        mapStyles={(...args) => {
          return mapStyles(...args)
        }}
        className="switch-wrapper-app"
      >
        <Route exact path="/login" component={Login} />
        <Route exact path="/auth" component={DirectAuth} />
        <Route exact path="/busca-de-cid" component={CidNoAuth} />
        <Route exact path="/tutorial" component={Walkthrough} />
        {/* <Route exact path="/covid-19" component={CovidNoAuth} /> */}
        <Route exact path="/conteudo-sobre-covid/:id" component={CovidContent} />
        <PrivateRoute exact path="/conteudo/:id" component={Content} />
        <PrivateRoute exact path="/meu-perfil" component={Profile} />
        <PrivateRoute path="/" component={Layout} />
        <Route component={NotFound} />
      </AnimatedSwitch>
    )
  }
}

export default withRouter(AppRoutes)
