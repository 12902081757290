const compute = ({ options = [], storedOptions = [] }) => {
  const computed = (options || []).map(option => ({
    ...option,
    checked: true
  }))

  return (computed || []).map(option => {
    const found = (storedOptions || []).find(({ key }) => key === option.key)

    if (found && !!Object.keys(found).length) {
      return {
        ...option,
        checked: found.checked
      }
    } else {
      return option
    }
  })
}

const prepareOptions = options =>
  (options || []).map(({ nome: label, chave: key, ...rest }) => ({
    ...rest,
    key,
    label,
    value: key
  }))

const areAllChecked = options => (options || []).every(({ checked }) => checked === true)

const toggleAll = options => {
  const checked = areAllChecked(options || []) ? false : true

  return (options || []).map(option => ({
    ...option,
    checked
  }))
}

const stringfyPositiveOptions = options =>
  (options || [])
    .filter(option => option.checked)
    .map(({ key }) => key)
    .join(',')

const readingTimesFilterApplyed = options => {
  let count = 0

  options.map(item => {
    if(item.checked)
      count += 1
  })

  if(options.length === count || count === 0){
    return false
  }
  else{
    return true
  }
}

const resetFilterReadingTimes = options => {
  const checked = true

  return (options || []).map(option => ({
    ...option,
    checked
  }))
}

export { areAllChecked, compute, prepareOptions, stringfyPositiveOptions, toggleAll, readingTimesFilterApplyed, resetFilterReadingTimes }
