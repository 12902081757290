import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Query from 'react-apollo/Query'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import CircularProgress from '@material-ui/core/CircularProgress'
import { feedbackQueries } from '../graphql/queries'
import TextField from '@material-ui/core/TextField'
import { feedbackMutations } from '../graphql/mutations'
import Mutation from 'react-apollo/Mutation'
import IconButton from '@material-ui/core/IconButton'
import IconClose from '../icons/IconClose'
import Rating from './Rating'
import Snackbar from '@material-ui/core/Snackbar'
import { googleAnalytics } from '../utils'

const styles = (theme) => ({
  paper: {
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    '& > div': {
      overflow: 'inherit',
    },
  },
  progress: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto',
  },
  content: {
    minHeight: 60,
    overflow: 'hidden',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: -20,
    right: -20,
    '&, &:hover, &:focus': {
      background: '#229FFF',
      color: '#fff',
      width: 40,
      height: 40,
      marginLeft: 'auto',
    },
  },
})

class NoteDialog extends React.Component {
  constructor(props) {
    super(props)
    this.comentarioInputRef = React.createRef()
    this.notaInputRef = React.createRef()
    this.state = {
      ratingValue: 0,
      feedbackMessage: 'Obrigado por sua avaliação',
      feedbackShow: false,
    }
  }

  handleCancel = () => {
    this.props.onClose()
  }

  renderFeedback = ({ loading, error, data = {}, addingNote }) => {
    const { classes } = this.props
    const { feedback = {} } = data
    if (loading || addingNote) {
      return <CircularProgress className={classes.progress} size={30} />
    }

    return (
      <div className={classes.container}>
        <Rating
          inputRef={this.notaInputRef}
          onChangeValue={(ratingValue) => {
            this.setState({ ratingValue })
          }}
          defaultValue={(feedback || {}).nota || 0}
        />
        <TextField
          id="comentarios-content"
          label="Comentário"
          multiline
          rowsMax="8"
          autoFocus
          fullWidth
          defaultValue={(feedback || {}).comentario || ''}
          className={classes.textField}
          inputProps={{
            ref: this.comentarioInputRef,
          }}
          margin="normal"
        />
      </div>
    )
  }

  render() {
    const { value, searchOrder, classes, contentId, ...other } = this.props

    return (
      <Dialog
        maxWidth="xs"
        fullWidth
        onEntering={this.handleEntering}
        aria-labelledby="confirmation-dialog-title"
        className={classes.root}
        {...other}
      >
        <IconButton
          className={classes.closeButton}
          onClick={() => {
            googleAnalytics(
              'acao_ctdo_texto_avaliacao_back',
              'menu superior da integra do conteudo',
              'Se clica na opcao X - para fechar janela',
              'Clique'
            )
            this.handleCancel()
          }}
          aria-label="Sair"
        >
          <IconClose style={{ fontSize: 20 }} viewBox={'0 0 20 20'} />
        </IconButton>
        <Query
          query={feedbackQueries.GET_FEEDBACK}
          fetchPolicy={'cache-and-network'}
          variables={{ conteudo: contentId }}
          onCompleted={({ metatag: { valor: feedbackMessage } = {} }) =>
            this.setState({ feedbackMessage, feedbackShow: false })
          }
        >
          {({ loading, error, data = {} }) => {
            return (
              <Mutation mutation={feedbackMutations.ADD_FEEDBACK}>
                {(addFeedback, { loading: addingNote }) => {
                  return (
                    <form
                      disabled={loading || error}
                      onSubmit={(e) => {
                        e.preventDefault()
                        addFeedback({
                          variables: {
                            conteudoId: contentId,
                            nota: this.notaInputRef.current.value,
                            comentario: this.comentarioInputRef.current.value,
                          },
                        })
                      }}
                    >
                      <DialogContent className={classes.content}>
                        {this.renderFeedback({ loading, error, data, addingNote })}
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => {
                            googleAnalytics(
                              'acao_ctdo_texto_avaliacao_cancel',
                              'menu superior da integra do conteudo',
                              'Se clica na opcao cancelar envio comentario',
                              'Clique'
                            )
                            this.handleCancel()
                          }}
                          color={'default'}
                        >
                          Cancelar
                        </Button>

                        <Button
                          disabled={loading || !Number((this.notaInputRef.current || {}).value)}
                          type={'submit'}
                          color="primary"
                          onClick={() => {
                            this.setState({ feedbackShow: true })
                            googleAnalytics(
                              'acao_ctdo_texto_avaliacao_send',
                              'menu superior da integra do conteudo',
                              'Se clica na opcao enviar comentario',
                              'Clique'
                            )
                          }}
                        >
                          Enviar
                        </Button>
                        <Snackbar
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                          autoHideDuration={2000}
                          open={this.state.feedbackShow}
                          onClose={() => {
                            this.props.onClose()
                          }}
                          message={<span id="feedback-message">{this.state.feedbackMessage}</span>}
                        />
                      </DialogActions>
                    </form>
                  )
                }}
              </Mutation>
            )
          }}
        </Query>
      </Dialog>
    )
  }
}

export default withStyles(styles)(NoteDialog)
