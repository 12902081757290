/*
*
import makeFilter from '../utils/filter'
const {
    getListOfInitialOptions,
    getInitialFilterOptions,
    getAllFiltersChecked,
    getPlainAllFiltersChecked,
    allOptionsChecked,
    stringifyOptions,
    parseOptions,
    getPlainInitialFilterOptions,
    filterOrOrderApplied
  } = makeFilter(filterOptions)
*/
const makeFilter = filterOptions => {
  const getListOfInitialOptions = () => {
    return ['artigo', 'capitulo', 'consulta_rapida_e_recomendacoes', 'diagnostico_e_tratamento']
  }
  const getInitialFilterOptions = () =>
    filterOptions.map(item => {
      if (getListOfInitialOptions().includes(item.value)) {
        return { ...item, checked: true }
      } else {
        return { ...item, checked: false }
      }
    })
  const getAllFiltersChecked = () => filterOptions.map(item => ({ ...item, checked: true }))

  const getPlainAllFiltersChecked = () => {
    return getAllFiltersChecked()
      .map(item => item.value)
      .join(',')
  }

  const allOptionsChecked = options =>
    !options.find(option => {
      return !option.checked
    })

  const stringifyOptions = options => {
    return options.join(',')
  }

  const parseOptions = options => {
    return options.split(',')
  }

  const getPlainInitialFilterOptions = () => {
    return stringifyOptions(getInitialFilterOptions())
  }

  const getOptionsArray = () => {
    const filterOptionsLength = parseOptions(getPlainAllFiltersChecked())
    return filterOptionsLength.length
  }

  const filterOrOrderApplied = ({ searchFilter, searchRange }) => {
    const options = parseOptions(searchFilter)
    const lengthAllOptions = getOptionsArray()
    return searchRange || options.length !== lengthAllOptions
  }

  return {
    getListOfInitialOptions,
    getInitialFilterOptions,
    getAllFiltersChecked,
    getPlainAllFiltersChecked,
    allOptionsChecked,
    stringifyOptions,
    parseOptions,
    getPlainInitialFilterOptions,
    filterOrOrderApplied
  }
}

export default makeFilter
