import React from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Info from '@material-ui/icons/Info'
import Share from '@material-ui/icons/Share'
import withRouter from 'react-router-dom/withRouter'
import Query from 'react-apollo/Query'
import { connect } from 'react-redux'
import { contentQueries } from '../graphql/queries'
import RenderContent from '../components/RenderContent'
import NoteIconToolbar from '../components/NoteIconToolbar'
import ToggleFavoriteButton from '../components/ToggleFavoriteButton'
import NoteDialog from '../components/NoteDialog'
import AuthorDialog from '../components/AuthorDialog'
import ImageZoomDialog from '../components/ImageZoomDialog'
import Hammer from 'react-hammerjs'
import ContentInfoDialog from '../components/ContentInfoDialog'
import ShareDialog from '../components/ShareDialog'
import FontZoom from '../components/FontZoom'
import ContentError from '../components/ContentError'
import * as loggerClient from '../services/loggerClient'
import Divider from '@material-ui/core/Divider'
import { contentScrollActions } from '../actions'
import { device, highlight, googleAnalytics } from '../utils'
import contentHistoryStack from '../services/contentHistoryStack'
import ContentStackModal from '../components/ContentStackModal'
import { startAnimation } from '../fixGestureNavigationIOS'
import requestAnimationFrame from 'raf'
import { removeLoggerToken } from '../services/loggerClient/local'
import DeletedContentError from '../components/DeletedContentError'
import SearchboxHighlight from '../components/SearchboxHiglight'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    boxSizing: 'border-box',
    background: '#f7f7f7',
  },
  appBar: {
    backgroundColor: '#002d72',
    zIndex: theme.zIndex.drawer + 1,
  },
  toolBar: {
    padding: '0 8px',
  },
  menuLink: {
    display: 'flex',
    textDecoration: 'none',
    padding: '12px 16px',
    '& > svg': {
      color: 'rgba(0, 0, 0, 0.54) !important',
    },
    '&.menu-link-active': {
      background: '#eee',
      '& > div span': {
        color: '#0080E2',
      },
      '& > svg': {
        color: '#0080E2 !important',
      },
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  menuButton: {
    margin: '0',
  },
  errorContainer: {
    height: '100%',
  },
  toolbarUser: {
    color: 'inherit',
    fontSize: '.9rem',
    display: 'block',
  },
  toolbarMail: {
    color: 'inherit',
    fontSize: '.7rem',
  },
  toolBarRight: {
    margin: '0 8px',
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  childrenWrapper: {
    width: '100%',
    height: 'calc(100% - 55px)',
    '-webkit-overflow-scrolling': 'touch' /* Lets it scroll lazy */,
    overflow: 'auto',
    background: '#fff',
    '@media only screen and (max-width: 900px) and (orientation: landscape)': {
      height: 'calc(100% - 47px)',
    },
  },
  disclaimer: {
    textAlign: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 16,
    marginBottom: 16,
  },
})

class Content extends React.PureComponent {
  constructor(props) {
    super(props)
    this.contentWrapperRef = React.createRef()
    this.state = {
      open: false,
      messagesOpen: false,
      showNote: false,
      showAuthor: false,
      showZoomImage: false,
      pointerType: undefined,
      showInfo: false,
      showShare: false,
      contentLogged: false,
      deletedContent: false,
      searchText: '',
      debouncedSearchText: '',
      isDebouncing: false,
    }
  }

  logContent = ({ content }) => {
    const { location = {} } = this.props
    const { state: locationState = {} } = location

    if (!this.state.contentLogged) {
      this.setState({ contentLogged: true })
      loggerClient.onShowContent({
        termo: locationState.termo,
        correspondencias: locationState.correspondencias,
        posicao: locationState.posicao,
        score: locationState.score,
        conteudo: this.props.match.params.id,
        tipo: content.tipo,
        origem: locationState.from,
        contexto: locationState.contexto,
        filtro: locationState.filtro,
        filtroLeitura: locationState.filtroLeitura,
      })
    }
  }

  closeZoomImage = () => {
    this.setState({
      showZoomImage: false,
    })
  }

  handleDrawerOpen = () => {
    this.setState({ open: true })
  }

  handleDrawerClose = () => {
    this.setState({ open: false })
  }

  getMainContentStyles = () => {
    const { safeArea = true } = this.props
    const styles = {}
    if (!safeArea) {
      styles.padding = 0
    }
    Object.freeze(styles)
    return styles
  }

  toggleDrawer = (open) => () => {
    this.setState({
      open,
    })
  }

  handleDrawerMessageOpen = () => {
    this.setState({ messagesOpen: true })
  }

  handleDrawerMessageClose = () => {
    this.setState({ messagesOpen: false })
  }

  toggleDrawerMessage = (messagesOpen) => () => {
    this.setState({
      messagesOpen,
    })
  }

  openNoteDialog = () => {
    this.setState({
      showNote: true,
    })
  }

  closeNoteDialog = () => {
    this.setState({
      showNote: false,
    })
  }

  closeAuthor = () => {
    this.setState({
      showAuthor: false,
    })
  }

  showAuthor = (id) => {
    this.setState({
      showAuthor: id,
    })
  }

  closeInfo = () => {
    this.setState({
      showInfo: false,
    })
  }

  openInfo = () => {
    this.setState({
      showInfo: true,
    })
  }

  closeShare = () => {
    this.setState({
      showShare: false,
    })
  }

  openShare = () => {
    this.setState({
      showShare: true,
    })
  }

  setInitialScrollTop = (scrollTop) => {
    this.initialScrollTop = scrollTop
  }

  recycleScrollMethod = () => {
    this.contentWrapperRef.current.scrollTo(0, this.initialScrollTop)
  }

  onContentScroll = (e) => {
    requestAnimationFrame(() => {
      const contentWrapper = e.target.querySelector('#ContentPreview-wrapper')
      this.props.setContentScroll({
        scrollTop: e.srcElement.scrollTop,
        clientHeight: contentWrapper.clientHeight,
        contentId: this.props.match.params.id,
      })
    })
  }

  componentDidMount() {
    const { location = {}, history } = this.props
    const { state = {} } = location

    if (state.openNotes) {
      setTimeout(() => {
        this.openNoteDialog()
      }, 300)
      history.replace(location.path, { ...state, openNotes: false })
    }

    this.setInitialScrollTop(this.props.scrollTop)

    if (device.isIosStandaloneMode()) {
      this.contentWrapperRef.current.addEventListener('scroll', this.onContentScroll)
    }
    this.historyListener = this.props.history.listen((newHistory, action) => {
      if (newHistory.hash && action === 'POP') {
        this.props.history.goBack()
        this.props.history.replace(newHistory)
      }
    })
  }

  componentWillUnmount() {
    removeLoggerToken()
    if (device.isIosStandaloneMode()) {
      this.contentWrapperRef.current.removeEventListener('scroll', this.onContentScroll)
    }
    this.historyListener && this.historyListener()
  }

  getContainerClasses = (error) => {
    const { classes = {} } = this.props
    const containerClasses = error ? 'errorContainer' : 'contentContainer'
    return classes[containerClasses]
  }

  _onText = (text) => {
    this.setState({ searchText: text, isDebouncing: true }, () => {
      clearTimeout(this.debounceSearchKey)

      this.debounceSearchKey = setTimeout(() => {
        this.setState({ debouncedSearchText: text, isDebouncing: false })
      }, 300)
    })
  }

  decodeHtml = (html) => {
    var textarea = document.createElement('textarea')
    textarea.innerHTML = html
    return textarea.value
  }

  highlightHtml = (contentString, searchText) => {
    return highlight.highlightHtml(contentString, searchText)
  }

  highlightContent = (rawData) => {
    const { content: rawContent } = rawData

    const { debouncedSearchText, isDebouncing } = this.state

    if (!debouncedSearchText || isDebouncing) {
      return rawData
    }

    const htmlContent = rawContent.conteudoHtml
    const decodedHtml = this.decodeHtml(htmlContent)
    const highlightedHtml = this.highlightHtml(decodedHtml, debouncedSearchText)

    return { ...rawData, content: { ...rawContent, conteudoHtml: highlightedHtml } }
  }

  clearSearchText = () => {
    this.setState({ searchText: '', debouncedSearchText: '' })
  }

  goBack = () => {

    const { REACT_APP_BULLETIN_URL, REACT_APP_PAYWALL_URL } = process.env

    if (document.referrer.match(REACT_APP_BULLETIN_URL) || document.referrer.match(REACT_APP_PAYWALL_URL)) {
      window.location = '/'
    }
    else {
      this.props.history.goBack()
    }
  }

  render() {
    const { classes } = this.props
    const { deletedContent, searchText } = this.state
    const mainContentStyles = this.getMainContentStyles()

    return (
      <Query
        query={contentQueries.GET_CONTENT}
        fetchPolicy={'cache-and-network'}
        variables={{ id: this.props.match.params.id }}
        onCompleted={(data = {}) => {
          const { content = {} } = data

          if (content.deletedAt) {
            this.setState({ deletedContent: true })
          }

          this.logContent({ content })
        }}
      >
        {({ loading, error, data = {}, refetch, networkStatus }) => {
          const dataContent = this.highlightContent(data)

          return (
            <div className={classNames(classes.root, 'transition-item detail-page')}>
              <AppBar position="absolute" className={classNames(classes.appBar)}>
                <Toolbar disableGutters={true} className={classNames(classes.toolBar, 'container')}>
                  <IconButton
                    color="inherit"
                    aria-label="Voltar"
                    onClick={() => {
                      startAnimation()
                      this.goBack()
                    }}
                    className={classNames(classes.menuButton)}
                  >
                    <ArrowBackIcon />
                  </IconButton>

                  <Typography variant="title" color="inherit" noWrap />
                  <div className={classes.toolBarRight}>
                    <SearchboxHighlight
                      value={searchText}
                      disabled={
                        loading ||
                        error ||
                        deletedContent ||
                        ((data || {}).content || {}).tipo === 'cid10' ||
                        ((data || {}).content || {}).tipo === 'cid11'
                      }
                      onChange={(event) => this._onText(event.target.value)}
                      onStateChange={this.clearSearchText}
                      tipo={((data || {}).content || {}).tipo || ''}
                    >

                    <IconButton
                      color="inherit"
                      style={{
                        color:
                          loading || error || deletedContent
                            ? 'rgba(255,255,255,.4)'
                            : '#fff',
                      }}
                      aria-label="open drawer"
                      className={classNames(classes.menuButton)}
                      disabled={loading || error || deletedContent}
                      onClick={() => {
                        this.openShare()
                        loggerClient.onInteract({
                          evento: 'click',
                          conteudo: `${this.props.match.params.id}`,
                          tipo: `${((data || {}).content || {}).tipo}`,
                          contexto: 'compartilhamento',
                        })
                      }}
                    >
                      <Share />
                    </IconButton>

                      {((data || {}).content || {}).tipo !== 'cid10' &&
                        ((data || {}).content || {}).tipo !== 'cid11' && (
                          <IconButton
                            color="inherit"
                            style={{
                              color:
                                loading || error || deletedContent
                                  ? 'rgba(255,255,255,.4)'
                                  : '#fff',
                            }}
                            aria-label="open drawer"
                            className={classNames(classes.menuButton)}
                            disabled={loading || error || deletedContent}
                            onClick={() => {
                              this.openInfo()
                            }}
                          >
                            <Info />
                          </IconButton>
                        )}

                      <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        style={{
                          color:
                            loading || error || deletedContent ? 'rgba(255,255,255,.4)' : '#fff',
                        }}
                        onClick={() => {
                          this.openNoteDialog()
                        }}
                        disabled={loading || error || deletedContent}
                        className={classNames(classes.menuButton)}
                      >
                        <NoteIconToolbar
                          id={this.props.match.params.id}
                          content={(data || {}).content}
                          disabled={loading || error || deletedContent}
                        />
                      </IconButton>

                      <FontZoom disabled={loading || error || deletedContent} />

                      <ToggleFavoriteButton
                        id={this.props.match.params.id}
                        content={(data || {}).content}
                        disabled={loading || error || deletedContent}
                      />
                    </SearchboxHighlight>
                  </div>
                </Toolbar>
              </AppBar>
              <main className={classes.content} style={mainContentStyles}>
                <div className={classes.toolbar} />
                <div
                  ref={this.contentWrapperRef}
                  id="Content-Wrapper"
                  className={classNames(classes.childrenWrapper)}
                >
                  {((data || {}).content || {}).deletedAt ? (
                    <DeletedContentError />
                  ) : (
                    <>
                      {error ? (
                        <ContentError
                          error={error}
                          refetch={() => refetch()}
                          networkStatus={networkStatus}
                        />
                      ) : (
                        <Hammer
                          onTap={(e) => {
                            if (e.target.tagName === 'IMG') {
                              this.setState({ showZoomImage: e.target, pointerType: e.pointerType })
                            }
                            if ('content' in e.target.dataset) {
                              contentHistoryStack.push({ id: e.target.dataset.href })
                            }
                          }}
                        >
                          <div
                            id={'ContentContainer'}
                            className={classNames('container', this.getContainerClasses(error))}
                          >
                            <RenderContent
                              recycleScrollMethod={this.recycleScrollMethod}
                              queryInfos={{
                                loading,
                                error,
                                data: dataContent,
                                refetch,
                                networkStatus,
                              }}
                              contentId={this.props.match.params.id}
                              handleAuthorClick={(id) => {
                                googleAnalytics(
                                  'acao_ctdo_texto_autor',
                                  'integra do conteudo',
                                  'Se clica no nome dos autores',
                                  'Clique'
                                )
                                this.showAuthor(id)
                              }}
                            />
                          </div>
                        </Hammer>
                      )}
                    </>
                  )}

                  {!!(data.metatag || {}).valor && !!data.content && !loading && !error && (
                    <React.Fragment>
                      <Divider />
                      <div className={classes.disclaimer}>
                        <Typography style={{ fontSize: 16, color: '#757575' }}>
                          {(data.metatag || {}).valor}
                        </Typography>
                        <Typography gutterBottom style={{ fontSize: 16, color: '#757575' }}>
                          Ref: {this.props.match.params.id}
                        </Typography>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </main>
              <NoteDialog
                open={this.state.showNote}
                contentId={this.props.match.params.id}
                contentType={((data || {}).content || {}).tipo}
                onClose={() => {
                  this.closeNoteDialog()
                }}
              />
              <AuthorDialog
                open={this.state.showAuthor}
                onClose={() => {
                  this.closeAuthor()
                }}
              />

              <ImageZoomDialog
                open={!!this.state.showZoomImage}
                image={this.state.showZoomImage}
                pointerType={this.state.pointerType}
                onClose={() => {
                  this.closeZoomImage()
                }}
              />

              <ContentInfoDialog
                open={this.state.showInfo}
                contentId={this.props.match.params.id}
                content={(data || {}).content || {}}
                onClose={() => {
                  this.closeInfo()
                }}
              />

              <ShareDialog
                open={this.state.showShare}
                contentId={this.props.match.params.id}
                content={(data || {}).content || {}}
                onClose={() => {
                  this.closeShare()
                }}
              />

              <ContentStackModal
                open={contentHistoryStack.active()}
                currentContent={(data || {}).content}
                currentContentStackId={contentHistoryStack.getCurrentScreen().id}
              />
            </div>
          )
        }}
      </Query>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    scrollTop: state.contentScroll.scrollTop,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setContentScroll: ({ scrollTop, clientHeight, contentId }) => {
      dispatch(contentScrollActions.setScroll({ scrollTop, clientHeight, contentId }))
    },
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Content))
)
