import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Snackbar from '@material-ui/core/Snackbar'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import OnlyOnline from './OnlyOnline'
import { validators } from '../utils'
import CircularProgress from '@material-ui/core/CircularProgress'
import Slide from '@material-ui/core/Slide'
import FormHelperText from '@material-ui/core/FormHelperText'
import IconButton from '@material-ui/core/IconButton'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import * as API from '../api'

const styles = theme => ({
  paper: {
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper
  },
  root: {
    '& > div': {
      overflow: 'inherit'
    }
  },
  progress: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto'
  },
  content: {
    minHeight: 60,
    position: 'relative'
  },
  toolBar: {
    padding: '0 8px'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  toolBarRight: {
    marginLeft: 'auto',
    margin: '0 8px'
  },
  closeButton: {
    position: 'absolute',
    top: -20,
    right: -20,
    '&, &:hover, &:focus': {
      background: '#229FFF',
      color: '#fff',
      width: 40,
      height: 40,
      marginLeft: 'auto'
    }
  },
  form: {
    marginTop: 8
  },
  formControl: {
    marginTop: 8,
    marginBottom: 8
  },
  appBar: {
    backgroundColor: '#002d72',
    zIndex: theme.zIndex.drawer + 1
  }
})

function Transition(props) {
  return <Slide direction="up" {...props} />
}

class UpdatePasswordDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      formUser: {
        email: '',
        password: '',
        newPassword: '',
        confirmPassword: ''
      },
      loading: false,
      errorMessage: '',
      serverErrors: {
        password: false,
        newPassword: false,
        confirmPassword: false
      },
      formTouched: false
    }
  }

  handleCancel = () => {
    this.props.onClose()
  }

  setServerError = ({ name, message }) => {
    this.setState(state => ({
      ...state,
      serverErrors: {
        ...state.serverErrors,
        [name]: message
      },
      formTouched: true
    }))
  }

  clearServerErrors = () => {
    this.setState(state => ({
      ...state,
      serverErrors: {
        password: false,
        newPassword: false,
        confirmPassword: false
      }
    }))
  }

  onSuccess = newPassword => {
    this.props.onSuccess('Senha alterada com sucesso.', { newPassword })
  }

  handleOk = () => {
    this.props.onClose()
  }

  changeNote = anotacao => {
    this.setState({ anotacao })
  }

  renderSpinner = () => {
    const { classes } = this.props
    return <CircularProgress className={classes.progress} size={30} />
  }

  changeUser = (event = {}) => {
    const { target = {} } = event
    const { value, name } = target

    this.setState(state => ({
      formUser: {
        ...state.formUser,
        [name]: value
      },
      serverErrors: {
        ...state.serverErrors,
        [name]: false
      }
    }))
  }

  validatesForm = ({ password, newPassword, email, confirmPassword }) => {
    const errors = {}
    const { serverErrors } = this.state

    if (!password) {
      errors.password = 'Informe sua senha atual.'
    } else if (serverErrors.password) {
      errors.password = serverErrors.password
    }

    if (!newPassword) {
      errors.newPassword = 'Informe uma nova senha.'
    } else if (serverErrors.newPassword) {
      errors.newPassword = serverErrors.newPassword
    }

    if (!validators.email(email)) {
      errors.email = 'Informe um e-mail válido.'
    }

    if (!email) {
      errors.email = 'Informe seu e-mail.'
    }

    if (this.props.email) {
      delete errors.email
    }

    if (this.props.password) {
      delete errors.password
    }

    if (newPassword !== confirmPassword) {
      errors.confirmPassword = 'Senhas não correspondem'
    }

    return {
      errors,
      valid: JSON.stringify(errors) === '{}'
    }
  }

  renderUserForm = () => {
    const { classes, email, password } = this.props
    const { formUser: user = {}, formTouched } = this.state
    const { errors } = this.validatesForm(user)

    return (
      <form className={classes.form}>
        {!email && (
          <FormControl
            fullWidth
            error={formTouched && errors.email}
            className={classes.formControl}
          >
            <InputLabel htmlFor="user-email">E-mail</InputLabel>
            <Input id="user-email" name={'email'} value={user.email} onChange={this.changeUser} />
            {formTouched && errors.email && <FormHelperText>{errors.email}</FormHelperText>}
          </FormControl>
        )}
        {!password && (
          <FormControl
            fullWidth
            error={formTouched && errors.password}
            className={classes.formControl}
          >
            <InputLabel htmlFor="user-password">Senha atual</InputLabel>
            <Input
              id="user-password"
              name={'password'}
              type={'password'}
              value={user.password}
              onChange={this.changeUser}
            />
            {formTouched && errors.password && <FormHelperText>{errors.password}</FormHelperText>}
          </FormControl>
        )}

        <FormControl
          fullWidth
          error={formTouched && errors.newPassword}
          className={classes.formControl}
        >
          <InputLabel htmlFor="user-newpassword">Nova senha</InputLabel>
          <Input
            id="user-newpassword"
            name={'newPassword'}
            type={'password'}
            value={user.newPassword}
            onChange={this.changeUser}
          />
          {formTouched &&
            errors.newPassword && <FormHelperText>{errors.newPassword}</FormHelperText>}
        </FormControl>

        <FormHelperText style={{ fontSize: 14, marginBottom: 10 }}>
          Sua nova senha deve conter no mínimo 8 caracteres, tendo maiúsculas, minúsculas, números e
          caracteres especiais.
        </FormHelperText>

        <FormControl
          fullWidth
          error={formTouched && errors.confirmPassword}
          className={classes.formControl}
        >
          <InputLabel htmlFor="user-confirmpassword">Confirmar nova senha</InputLabel>
          <Input
            id="user-confirmpassword"
            name={'confirmPassword'}
            type={'password'}
            value={user.confirmPassword}
            onChange={this.changeUser}
          />
          {formTouched &&
            errors.confirmPassword && <FormHelperText>{errors.confirmPassword}</FormHelperText>}
        </FormControl>
      </form>
    )
  }

  handleEntering = () => {
    this.setState({
      errorMessage: '',
      formUser: {
        email: '',
        password: '',
        newPassword: '',
        confirmPassword: ''
      },
      formTouched: false
    })
  }

  openErrorMessage = message => {
    this.setState({
      errorMessage: message
    })
  }

  closeErrorMessage = () => {
    this.setState({
      errorMessage: ''
    })
  }

  renderUser = ({ loading }) => {
    if (loading) {
      return this.renderSpinner()
    }

    return this.renderUserForm()
  }

  handleSubmit = ({ formUser }) => {
    if (this.validatesForm(formUser).valid) {
      this.setState({ loading: true })
      API.auth
        .refreshPassword({
          password: this.props.password || formUser.password,
          newPassword: formUser.newPassword,
          email: this.props.email || formUser.email
        })
        .then(response => {
          this.setState({ loading: false })
          if (response.ok) {
            return this.onSuccess(formUser.newPassword)
          }

          if (response.status === 422) {
            this.setServerError({ name: 'newPassword', message: 'Senha fraca.' })
            return this.openErrorMessage('Senha fraca.')
          }

          if (response.status === 401) {
            this.setServerError({ name: 'password', message: 'Senha atual incorreta' })
            return this.openErrorMessage('Senha atual incorreta.')
          }

          this.openErrorMessage('Estamos com problemas, tente mais tarde.')
        })
        .catch(err => {
          this.setState({ loading: false })
          this.openErrorMessage('Estamos com problemas, tente mais tarde.')
        })
    } else {
      this.setState({
        formTouched: true
      })
      this.openErrorMessage('Preencha todos os campos corretamente.')
    }
  }

  render() {
    const { value, searchOrder, classes, firstLogin, message, ...other } = this.props
    const { formUser, errorMessage, loading } = this.state

    return (
      <Dialog
        maxWidth="xs"
        fullScreen
        onEntering={this.handleEntering}
        aria-labelledby="confirmation-dialog-title"
        TransitionComponent={Transition}
        className={classes.root}
        {...other}
      >
        <AppBar className={classes.appBar}>
          <Toolbar disableGutters={true} className={classNames(classes.toolbar, 'container')}>
            <IconButton color="inherit" onClick={this.props.onClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="title" color="inherit" className={classes.flex}>
              {firstLogin ? 'Criar nova senha' : 'Alterar senha'}
            </Typography>
            <div className={classes.toolBarRight}>
              <Button
                color="inherit"
                disabled={loading}
                style={{ color: loading ? 'rgba(255,255,255,.4)' : '#fff' }}
                onClick={() => {
                  this.handleSubmit({ formUser })
                }}
              >
                Salvar
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <OnlyOnline>
          <DialogContent className={classNames(classes.content, 'container')}>
            <div className={classes.toolbar} />
            {this.renderUser({ loading })}
          </DialogContent>
        </OnlyOnline>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={3500}
          onClose={() => {
            this.closeErrorMessage()
          }}
          open={!!errorMessage}
          ContentProps={{
            'aria-describedby': 'login-error'
          }}
          message={<span id="login-error">{errorMessage}</span>}
        />
      </Dialog>
    )
  }
}

export default withStyles(styles)(UpdatePasswordDialog)
