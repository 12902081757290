import Tabs from '@material-ui/core/Tabs'
import withStyles from '@material-ui/core/styles/withStyles'

const CustomTabs = withStyles(() => {
  return {
    flexContainer: {
      backgroundColor: '#fff',
      justifyContent: 'space-around'
    }
  }
})(Tabs)

export default CustomTabs
