import gql from 'graphql-tag'

export const SEARCH_CID = gql`
  query cid($term: String!, $offset: Int, $filter: String) {
    cid(term: $term, offset: $offset, filter: $filter) {
      total
      items {
        id
        nome
        codigo
        tipo
      }
    }
  }
`
