import React from 'react'
import { TextBlock, RoundShape } from 'react-placeholder/lib/placeholders'

const FavoriteItem = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '12px 22px',
        justifyContent: 'center'
      }}
    >
      <RoundShape
        style={{ width: 48, height: 48, flex: 'none', marginRight: 16 }}
        color="#d7d7d7"
      />
      <TextBlock rows={2} color="#d7d7d7" />
    </div>
  )
}

export default FavoriteItem
