import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import Popper from '@material-ui/core/Popper'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import AdvancedSearchDialog from '../../components/AdvancedSearchDialog'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'
import SwapVertIcon from '@material-ui/icons/SwapVert'
import AdvancedSearchIcon from '@material-ui/icons/Search'
import FilterListIcon from '@material-ui/icons/FilterList'
import FilterDialog from '../../components/FilterDialog'
import OrderDialog from '../../components/OrderDialog'
import ClearIcon from '@material-ui/icons/Clear'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    minHeight: '100%',
    flex: 1,
    padding: 8,
  },
  optionsButton: {
    color: '#fff',
    fontSize: 13,
    height: 'auto',
    boxShadow: 'none',
    textTransform: 'none',
    padding: '4px 16px',
    marginLeft: 5,
    minHeight: 28,
    '&:hover, &:focus': {
      background: '#959595',
    },
  },
  actionButton: {
    display: 'flex',
    flex: 1,
    justifyContent: 'left',
    color: '#454545',
    fontSize: 13,
    height: 'auto',
    boxShadow: 'none',
    textTransform: 'none',
    padding: '10px',
    paddingRight: '35px',
    margin: '5px',
    minHeight: 28,
  },
  optionsButtonIcon: {
    fontSize: 22,
  },
  actionButtonIcon: {
    width: 25,
    height: 25,
    marginRight: 15,
  },
})

const OptionsMenu = ({
  classes,
  filterOrOrderApplied,
  readingTimesFilterApplyed,
  searchOrder,
  changeFilter,
  searchRange,
  searchFilter,
  filter,
  filterOptions,
  readingTimes,
  changeReadingTime,
  handleClick,
  orderIsOpen,
  filterIsOpen,
  advancedSearchIsOpen,
  anchorEl,
  popperOpen,
  closeOrder,
  closeFilter,
  closeAdvancedSearch,
  openOrder,
  openFilter,
  openAdvancesSearch,
  resetFilter
}) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button
        variant="extendedFab"
        aria-label="Opções"
        onClick={handleClick}
        className={classes.optionsButton}
        style={{
          backgroundColor:
            filterOrOrderApplied || readingTimesFilterApplyed ? '#229FFF' : '#B0B0B0',
        }}
      >
        <SettingsApplicationsIcon className={classes.optionsButtonIcon} />
        Opções
      </Button>
      <Popper open={popperOpen} anchorEl={anchorEl} placement={'bottom-end'} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={classes.optionsWrapper}>
              <Button aria-label="Ordenar" onClick={openOrder} className={classes.actionButton}>
                <SwapVertIcon className={classes.actionButtonIcon} />
                Ordenar
              </Button>
              <Button aria-label="Filtrar" onClick={openFilter} className={classes.actionButton}>
                <FilterListIcon className={classes.actionButtonIcon} />
                Filtrar
              </Button>
              <Button
                aria-label="Avançada"
                onClick={openAdvancesSearch}
                className={classes.actionButton}
              >
                <AdvancedSearchIcon className={classes.actionButtonIcon} />
                Avançada
              </Button>
            </Paper>
          </Fade>
        )}
      </Popper>
      <OrderDialog open={orderIsOpen} onClose={closeOrder} value={searchOrder} />
      {filterIsOpen && (
        <FilterDialog
          open={filterIsOpen}
          onClose={closeFilter}
          changeFilter={changeFilter}
          range={searchRange}
          filter={searchFilter}
          filterOptions={filterOptions}
          readingTimes={readingTimes}
          changeReadingTime={changeReadingTime}
        />
      )}
      <AdvancedSearchDialog open={advancedSearchIsOpen} onClose={closeAdvancedSearch} />
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(OptionsMenu)
