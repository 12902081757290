import React from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CircularProgress from '@material-ui/core/CircularProgress'
import withRouter from 'react-router-dom/withRouter'
import { startAnimation } from '../../fixGestureNavigationIOS'
import SearchBarCid from '../Cid/SearchBar'
import Cid from '../Cid'

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    boxSizing: 'border-box',
    background: '#f7f7f7'
  },
  appBar: {
    backgroundColor: '#002d72',
    zIndex: theme.zIndex.drawer + 1
  },
  progress: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  menuLink: {
    display: 'flex',
    textDecoration: 'none',
    padding: '12px 16px',
    '& > svg': {
      color: 'rgba(0, 0, 0, 0.54) !important'
    },
    '&.menu-link-active': {
      background: '#eee',
      '& > div span': {
        color: '#0080E2'
      },
      '& > svg': {
        color: '#0080E2 !important'
      }
    }
  },
  menuButton: {
    margin: '0'
  },
  toolBarRight: {
    marginLeft: 'auto',
    margin: '0 8px'
  },
  itemText: {
    '& > *': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  },
  lockIcon: {
    marginRight: 5,
    fontSize: 18
  }
})

const Wrapper = withStyles({
  root: {
    flexGrow: 1,
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    boxSizing: 'border-box'
  }
})(({ classes, children, ...rest }) => {
  return (
    <div
      {...rest}
      className={classNames(classes.root, 'transition-item list-page')}
      style={{ background: '#f7f7f7' }}
    >
      {children}
    </div>
  )
})

const ChildrenWrapper = withStyles({
  childrenWrapper: {
    width: '100%',
    height: 'calc(100% - 55px)',
    '-webkit-overflow-scrolling': 'touch',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '@media only screen and (max-width: 900px) and (orientation: landscape)': {
      height: 'calc(100% - 47px)'
    }
  }
})(({ classes, children }) => {
  return (
    <div id={'infinite-scroll-wrapper'} className={classNames(classes.childrenWrapper)}>
      {children}
    </div>
  )
})

class CidNoAuth extends React.Component {
  constructor(props) {
    super(props)
    this.contentWrapperRef = React.createRef()
    this.state = {
      snackMessage: ''
    }
  }

  renderSpinner = () => {
    const { classes } = this.props
    return <CircularProgress className={classes.progress} size={30} />
  }

  getMainContentStyles = () => {
    const { safeArea = true } = this.props
    const styles = {}
    if (!safeArea) {
      styles.padding = 0
    }
    Object.freeze(styles)
    return styles
  }

  render() {
    const { classes } = this.props
    const mainContentStyles = this.getMainContentStyles()
    return (
      <Wrapper>
        <div className={classNames(classes.root, 'transition-item detail-page')}>
          <AppBar position="absolute" className={classNames(classes.appBar)}>
            <Toolbar disableGutters={true} className={classNames(classes.toolBar, 'container')}>
              <IconButton
                color="inherit"
                aria-label="Voltar"
                onClick={() => {
                  startAnimation()
                  this.props.history.goBack()
                }}
                className={classNames(classes.menuButton)}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="title" color="inherit" noWrap>
                Busca de CID
              </Typography>
              <div className={classes.toolBarRight} />
            </Toolbar>
          </AppBar>
          <main className={classes.content} style={mainContentStyles}>
            <div className={classes.toolbar} />
            <ChildrenWrapper>
              <div className="switch-wrapper switch-wrapper-search">
                <div>
                  <Cid />
                </div>
              </div>
            </ChildrenWrapper>
          </main>
        </div>
      </Wrapper>
    )
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(CidNoAuth))
