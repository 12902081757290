import React from 'react'
import NoteItem from './NoteItem'
import Divider from '@material-ui/core/Divider'

const NoteList = (
  <div>
    {[1, 2, 3, 4].map((item, index) => (
      <React.Fragment key={index}>
        <NoteItem />
        {index !== 3 && <Divider />}
      </React.Fragment>
    ))}
  </div>
)

export default NoteList
