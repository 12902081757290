import React from 'react'
import FavoritePlaceholder from './FavoritePlaceholder'
import withStyles from '@material-ui/core/styles/withStyles'
import Link from 'react-router-dom/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ItemIcon from '../../components/ItemIcon'
import ReactPlaceholder from 'react-placeholder'
import { userInfoQueries } from '../../graphql/queries'
import Query from 'react-apollo/Query'
import ErrorHandling from '../../components/ErrorHandling'
import * as textUtils from '../../utils/text'
import FavoriteListPlaceholder from '../../components/Placeholder/Favorite/FavoriteList'
import * as loggerClient from '../../services/loggerClient'
import { startAnimation } from '../../fixGestureNavigationIOS'
import { googleAnalytics } from '../../utils'

const styles = (theme) => ({
  root: {
    flex: 1,
    display: 'flex',
  },
  list: {
    paddingTop: 0,
    width: '100%',
  },
  item: {
    color: '#002D72',
    flex: 1,
    paddingLeft: 22,
    paddingRight: 22,
    alignItems: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    '& span': {
      display: '-webkit-box',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 2,
      '-webkit-box-orient': 'vertical',
    },
  },
  wrapperIcon: {
    boxSizing: 'border-box',
    width: 48,
    height: 48,
    flex: 'none',
    marginRight: 16,
    borderRadius: '100%',
    border: '2px solid #184496',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '40px',
    },
    background: '#184496',
  },
  errorWrapper: {
    display: 'flex',
    height: '100%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
})

class FavoriteList extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <Query query={userInfoQueries.GET_USER_INFOS}>
        {({ loading, error, data, refetch, networkStatus }) => {
          if (loading) {
            return (
              <ReactPlaceholder
                children={<div />}
                showLoadingAnimation={true}
                type="media"
                ready={false}
                rows={2}
                customPlaceholder={FavoriteListPlaceholder}
              />
            )
          }

          if (error)
            return (
              <ErrorHandling
                error={error}
                refetch={() => refetch()}
                networkStatus={networkStatus}
              />
            )

          if (!(data.favorites || []).length) {
            return <FavoritePlaceholder className={classes.root} />
          }
          return (
            <div className={classes.root}>
              <List className={classes.list}>
                {data.favorites.map(({ titulo, tipo, principioAtivo, _id }) => {
                  return (
                    <div key={_id}>
                      <ListItem
                        button
                        component={Link}
                        to={{
                          pathname: `/conteudo/${_id}`,
                          state: {
                            from: 'favoritos',
                            contexto: 'favoritos',
                          },
                        }}
                        className={classes.item}
                        onClick={() => {
                          googleAnalytics(
                            'menu_favoritos_item',
                            'acesso menu',
                            'acesso a um item da lista obtida',
                            'Clique'
                          )
                          startAnimation()
                          loggerClient.onClickItem({
                            conteudo: `${_id}`,
                            tipo,
                            origem: 'favoritos',
                            contexto: 'favoritos',
                          })
                        }}
                      >
                        <ItemIcon tipo={tipo} />
                        <span>
                          {textUtils.truncateEllipsis(titulo || principioAtivo, 45, true)}
                        </span>
                      </ListItem>
                      <Divider />
                    </div>
                  )
                })}
              </List>
            </div>
          )
        }}
      </Query>
    )
  }
}

export default withStyles(styles, { withTheme: true })(FavoriteList)
