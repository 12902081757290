import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import IconClose from '../icons/IconClose'
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share'
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'
import ContentCopy from '@material-ui/icons/ContentCopy'
import Query from 'react-apollo/Query'
import { userQueries } from '../graphql/queries'
import CircularProgress from '@material-ui/core/CircularProgress'
import { googleAnalytics } from '../utils'

const styles = (theme) => ({
  root: {
    '& > div': {
      overflow: 'inherit',
    },
  },
  progress: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto',
  },
  content: {
    minHeight: 60,
    overflow: 'hidden',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: -20,
    right: -20,
    '&, &:hover, &:focus': {
      background: '#229FFF',
      color: '#fff',
      width: 40,
      height: 40,
      marginLeft: 'auto',
    },
  },
  progressWrapper: {
    padding: 54,
  },
  progress: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto',
  }
})

class ShareDialog extends React.Component {
  constructor(props) {
    super(props)
  }

  handleCancel = () => {
    this.props.onClose()
    googleAnalytics(
      'acao_ctdo_share_menu_back',
      'menu superior da janela de compartilhamento',
      'Se clica na opcao X - para voltar o conteúdo - em compartilhamento',
      'Clique'
    )
  }

  copyToClipboard = (e) => {
    this.textInput.select()
    document.execCommand('copy')
    e.target.focus()
  }

  renderSpinner = () => {
    const { classes } = this.props
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress className={classes.progress} size={30} />
      </div>
    )
  }

  renderFeedback = (contentId) => {
    const { classes } = this.props
    return (
      <Query query={userQueries.GET_USER}>
        {({ loading, error, data: userInfo }) => {
          if (loading || error) return this.renderSpinner()
          const urlPaywall = `${process.env.REACT_APP_PAYWALL_URL}/${contentId}?ref=${((userInfo || {}).user || {}).userid}`
          return (
            <div className={classes.container}>
              <FacebookShareButton
                style={{ paddingRight: 12 }}
                url={urlPaywall}
                quote={'InforMED'}
                description={'InforMED'}
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <FacebookMessengerShareButton
                style={{ paddingRight: 12 }}
                url={urlPaywall}
                quote={'InforMED'}
                description={'InforMED'}
              >
                <FacebookMessengerIcon size={32} round />
              </FacebookMessengerShareButton>
              <TwitterShareButton
                style={{ paddingRight: 12 }}
                url={urlPaywall}
                quote={'InforMED'}
                description={'InforMED'}
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <TelegramShareButton
                style={{ paddingRight: 12 }}
                url={urlPaywall}
                quote={'InforMED'}
                description={'InforMED'}
              >
                <TelegramIcon size={32} round />
              </TelegramShareButton>
              <WhatsappShareButton
                style={{ paddingRight: 12 }}
                className={classes.displayShare}
                url={urlPaywall}
                quote={'InforMED'}
                description={'InforMED'}
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <LinkedinShareButton
                style={{ paddingRight: 12 }}
                url={urlPaywall}
                quote={'InforMED'}
                description={'InforMED'}
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <EmailShareButton url={urlPaywall} quote={'InforMED'} description={'InforMED'}>
                <EmailIcon size={32} round />
              </EmailShareButton>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <TextField
                  inputRef={(textarea) => (this.textInput = textarea)}
                  id="share-content"
                  label="URL"
                  multiline
                  rowsMax="8"
                  autoFocus
                  fullWidth
                  defaultValue={urlPaywall}
                  className={classes.textField}
                  inputProps={{
                    ref: this.comentarioInputRef,
                  }}
                  margin="normal"
                />
                <IconButton
                  style={{ alignSelf: 'center', color: '#AAA' }}
                  onClick={this.copyToClipboard}
                >
                  <ContentCopy />
                </IconButton>
              </div>
            </div>
          )
        }}
      </Query>
    )
  }

  render() {
    const { classes, contentId, ...other } = this.props

    return (
      <Dialog
        maxWidth="xs"
        fullWidth
        onEntering={this.handleEntering}
        aria-labelledby="confirmation-dialog-title"
        className={classes.root}
        {...other}
      >
        <IconButton
          className={classes.closeButton}
          onClick={() => {
            googleAnalytics(
              'acao_ctdo_share_menu_back',
              'menu superior da janela de compartilhamento',
              'Se clica na opcao X - para voltar o conteúdo - em compartilhamento',
              'Clique'
            )
            this.handleCancel()
          }}
          aria-label="Sair"
        >
          <IconClose style={{ fontSize: 20 }} viewBox={'0 0 20 20'} />
        </IconButton>

        <form
          onSubmit={(e) => {
            e.preventDefault()
          }}
        >
          <DialogContent className={classes.content}>
            {this.renderFeedback(contentId)}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.handleCancel()
              }}
              color={'default'}
            >
              Cancelar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

export default withStyles(styles)(ShareDialog)
