import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import LatestContentsList from './LatestContentsList'
import SearchResultList from '../SearchResultList'
import OnlyOnline from '../../components/OnlyOnline'
import { connect } from 'react-redux'
import withApollo from 'react-apollo/withApollo'
import Button from '@material-ui/core/Button'
import { searchActions } from '../../actions'
import { safeLocalStorage, googleAnalytics } from '../../utils'
import Redirect from 'react-router-dom/Redirect'
import OptionMenu from '../SearchResultList/OptionsMenu'
import Query from 'react-apollo/Query'
import { typeGroupQueries, readingTimeQueries, userQueries } from '../../graphql/queries'
import makeFilter from '../../utils/filter'
import {
  compute,
  areAllChecked,
  prepareOptions,
  readingTimesFilterApplyed,
  resetFilterReadingTimes,
} from '../../utils/readingTimeFilter'
import { resetFilter } from '../../actions/search'
import ClearIcon from '@material-ui/icons/Clear'
import SubscriptionPlaceholder from '../../components/Placeholder/Subscription/SubscriptionWarn'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    background: '#F7F7F7',
    boxSizing: 'border-box',
    paddingBottom: 15,
  },
  title: {
    color: '#505152',
    padding: 8,
    borderTop: '1px solid #e8e8e8',
    borderBottom: '1px solid #e8e8e8',
    paddingLeft: 22,
    display: 'flex',
    alignItems: 'center',
    '@media only screen and (max-width: 480px)': {
      fontSize: '1em',
    },
  },
  markSearch: {
    color: '#fff',
    fontSize: 13,
    flexShrink: 0,
    height: 'auto',
    boxShadow: 'none',
    textTransform: 'none',
    margin: 0,
    minHeight: 28,
    background: '#c50000',
    '&:hover, &:focus': {
      background: '#c50000',
    },
  },
  actionsLine: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 12px',
  },
  clearButton: {
    marginRight: '12px',
    padding: 0,
  },
})

class Home extends Component {
  constructor(props) {
    super(props)
    this.client = null
    this.searchLoggerTimeoutKey = undefined
    this.state = {
      orderIsOpen: false,
      filterIsOpen: false,
      advancedSearchIsOpen: false,
      lastTerm: '',
      anchorEl: null,
      popperOpen: false,
      hideByExpiration: false,
    }
  }

  handleClick = (event) => {
    const currentTarget = event.currentTarget
    this.setState((state) => {
      return {
        anchorEl: currentTarget,
        popperOpen: !state.popperOpen,
      }
    })
    this.props.changeClosePopper(!this.props.popperOpen)
  }

  componentDidMount = async () => {
    await googleAnalytics('inicio', 'home', 'Acesso a home - ponto 0 da experiência', 'Acesso')
  }

  closeOrder = () => {
    this.setState({
      orderIsOpen: false,
      anchorEl: null,
    })
    this.props.changeClosePopper(false)
  }

  closeFilter = () => {
    this.setState({
      filterIsOpen: false,
      anchorEl: null,
    })
    this.props.changeClosePopper(false)
  }

  closeAdvancedSearch = () => {
    this.setState({
      advancedSearchIsOpen: false,
      anchorEl: null,
    })
    this.props.changeClosePopper(false)
  }

  openOrder = () => {
    this.setState({
      orderIsOpen: true,
    })
  }

  openFilter = () => {
    this.setState({
      filterIsOpen: true,
    })
  }

  openAdvancesSearch = () => {
    this.setState({
      advancedSearchIsOpen: true,
    })
  }

  closeDropdown = () => {
    if (this.props.popperOpen) {
      this.setState({ popperOpen: false })
      this.props.changeClosePopper(false)
    }
  }

  resetFilterAllChecked = (filter, filterReadingTimes) => {
    const newOptions = resetFilterReadingTimes(filterReadingTimes)
    this.props.changeReadingTime(newOptions)
    this.props.resetFilter(filter.getPlainAllFiltersChecked())
  }

  constructDate = (stringData) => {
    let arrData = stringData.split('/')
    let stringFormatada = arrData[1] + '-' + arrData[0] + '-' + arrData[2]
    return stringFormatada
  }

  hideByExpirationSignature = () => {
    this.setState({ hideByExpiration: true })
  }

  render() {
    const {
      classes,
      searchFilter = '',
      searchRange,
      changeFilter,
      changeReadingTime,
      searchOrder,
      readingTimesStoredOptions,
      searchTerm,
      changeTerm,
      popperOpen,
    } = this.props
    const { constructDate, hideByExpiration, hideByExpirationSignature } = this

    const showWalkthrough = safeLocalStorage() && safeLocalStorage().getItem('showWalkthrough')

    if (!showWalkthrough) {
      return (
        <Redirect
          to={{
            pathname: '/tutorial',
            state: {
              fromLogin: true,
            },
          }}
        />
      )
    }

    return (
      <Query query={userQueries.GET_USER} fetchPolicy={'cache-and-network'}>
        {({ loading, error, data = {} }) => {
          const { user = {} } = data

          if (user.expirationDate) {
            let dataExpiracao = user.expirationDate
            let dataAtual = new Date()
            if (dataAtual > dataExpiracao) {
              hideByExpirationSignature()
            }
          } else {
            user.expirationDate = '12-12-2021'
          }

          if (!hideByExpiration && user.status !== 'expirado') {
            return (
              <Query query={readingTimeQueries.GET_READING_TIMES}>
                {({ loading, error, data: { readingTime = [] } = {} }) => {
                  if (loading || error) {
                    return null
                  }

                  const readingTimesOptions = prepareOptions(readingTime)

                  const filterApplyed = readingTimesFilterApplyed(readingTimesStoredOptions)

                  const readingTimes = compute({
                    options: readingTimesOptions,
                    storedOptions: readingTimesStoredOptions,
                  })

                  return (
                    <Query query={typeGroupQueries.SEARCH_GROUP_TYPES}>
                      {({ loading, error, data }) => {
                        if (loading || error) {
                          return null
                        }

                        const filterOptions = ((data || {}).typeGroup || [])
                          .sort(function (a, b) {
                            return a.ordem > b.ordem ? 1 : b.ordem > a.ordem ? -1 : 0
                          })
                          .map(({ key, nome, tipos }) => ({
                            key,
                            label: nome,
                            value: tipos.join(','),
                          }))

                        const filter = makeFilter(filterOptions)

                        const filterOrOrderApplied = () => {
                          return filter.filterOrOrderApplied({ searchFilter, searchRange })
                        }

                        return (
                          <OnlyOnline>
                            {!searchTerm ? (
                              <div className={classes.root}>
                                <div className={classes.actionsLine}>
                                  {/*           <Button
                                    variant="extendedFab"
                                    aria-label="Opções"
                                    onClick={async () => {
                                      changeTerm(`"covid-19"`)
                                      await googleAnalytics(
                                        'acao_busca_covid',
                                        'home',
                                        'Se clica no item COVID-19',
                                        'Clique'
                                      )
                                    }}
                                    className={classes.markSearch}
                                  >
                                    COVID-19
                                  </Button> */}
                                  <div style={{ display: 'flex' }}>
                                    {filterOrOrderApplied() || filterApplyed ? (
                                      <Button
                                        aria-label="Filtrar"
                                        className={classes.clearButton}
                                        color={'secondary'}
                                        onClick={() =>
                                          this.resetFilterAllChecked(filter, readingTimesOptions)
                                        }
                                      >
                                        <ClearIcon className={classes.actionButtonIcon} />
                                        Limpar filtro
                                      </Button>
                                    ) : null}
                                    <OptionMenu
                                      filterOrOrderApplied={filterOrOrderApplied()}
                                      readingTimesFilterApplyed={filterApplyed}
                                      searchOrder={searchOrder}
                                      filter={filter}
                                      changeFilter={changeFilter}
                                      searchRange={searchRange}
                                      searchFilter={searchFilter}
                                      filterOptions={filterOptions}
                                      resetFilter={resetFilter}
                                      readingTimes={readingTimes}
                                      changeReadingTime={changeReadingTime}
                                      handleClick={this.handleClick}
                                      orderIsOpen={this.state.orderIsOpen}
                                      filterIsOpen={this.state.filterIsOpen}
                                      advancedSearchIsOpen={this.state.advancedSearchIsOpen}
                                      anchorEl={this.state.anchorEl}
                                      popperOpen={popperOpen}
                                      closeOrder={this.closeOrder}
                                      closeFilter={this.closeFilter}
                                      closeAdvancedSearch={this.closeAdvancedSearch}
                                      openOrder={this.openOrder}
                                      openFilter={this.openFilter}
                                      openAdvancesSearch={this.openAdvancesSearch}
                                    />
                                  </div>
                                </div>
                                <Typography className={classes.title} variant={'title'}>
                                  <span>Acessados recentemente</span>{' '}
                                </Typography>
                                <LatestContentsList />
                              </div>
                            ) : (
                              <div className={classes.root}>
                                <SearchResultList />
                              </div>
                            )}
                          </OnlyOnline>
                        )
                      }}
                    </Query>
                  )
                }}
              </Query>
            )
          } else {
            return (
              <div className={classes.root}>
                <SubscriptionPlaceholder />
              </div>
            )
          }
        }}
      </Query>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    searchTerm: state.search.term,
    searchOrder: state.search.order,
    searchFilter: state.search.filter.filter,
    readingTimesStoredOptions: state.search.readingTime || [],
    searchRange: state.search.filter.range,
    searchSpecialty: state.search.filter.specialty,
    filterLenght: state.search.filterLenght,
    popperOpen: state.search.popperOpen,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeTerm: (term) => {
      dispatch(searchActions.changeTerm(term))
    },
    changeFilter: (filter) => {
      dispatch(searchActions.changeFilter(filter))
    },
    resetFilter: (filter) => {
      dispatch(searchActions.resetFilter(filter))
    },
    changeSpecialty: (specialty) => {
      dispatch(searchActions.changeSpecialty(specialty))
    },
    changeFilterLenght: (filterLenght) => {
      dispatch(searchActions.changeFilterLenght(filterLenght))
    },
    changeReadingTime: (readingTime) => {
      dispatch(searchActions.changeReadingTime(readingTime))
    },
    changeClosePopper: (popper) => {
      dispatch(searchActions.changeClosePopper(popper))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withApollo(Home)))
