import aulaIcon from './svg/aula.svg'
import capituloIcon from './svg/capitulo.svg'
import fluxogramaIcon from './svg/fluxograma.svg'
import imagemIcon from './svg/imagem.svg'
import medicamentoIcon from './svg/medicamento.svg'
import tabelaIcon from './svg/tabela.svg'
import artigoIcon from './svg/artigo.svg'
import diretrizIcon from './svg/diretriz.svg'
import podcastIcon from './svg/podcast.svg'
import webinarIcon from './svg/webinar.svg'
import consultaRapidaERecomendacoesIcon from './svg/consulta_rapida_e_recomendacoes.svg'
import diagnosticoETratamentoIcon from './svg/diagnostico_e_tratamento.svg'
import habilidadesClinicasIcon from './svg/habilidades_clinicas.svg'
import cid10 from './svg/cid10.svg'
import cid11 from './svg/cid11.svg'

const contentTypeIcons = {
  aula: aulaIcon,
  capitulo: capituloIcon,
  figura: imagemIcon,
  fluxograma: fluxogramaIcon,
  medicamento: medicamentoIcon,
  tabela: tabelaIcon,
  diretriz: diretrizIcon,
  artigo: artigoIcon,
  podcast: podcastIcon,
  webinar: webinarIcon,
  consulta_rapida_e_recomendacoes: consultaRapidaERecomendacoesIcon,
  cid10,
  diagnostico_e_tratamento: diagnosticoETratamentoIcon,
  habilidades_clinicas: habilidadesClinicasIcon,
  cid11
}

export default contentTypeIcons
