import gql from 'graphql-tag'

export const GET_USER = gql`
  query {
    user {
      userid
      email
      name
      lastname
      gender
      speciality
      origin
      daysRemaining
      expirationDate
      status
    }
  }
`
