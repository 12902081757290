/* eslint-disable react/no-multi-comp */

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputLabel from '@material-ui/core/InputLabel'
import Divider from '@material-ui/core/Divider'
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import listYears from '../utils/listYears'
import makeFilter from '../utils/filter'
import { areAllChecked, toggleAll, compute } from '../utils/readingTimeFilter'

const yearsList = listYears(2000)

const styles = theme => ({
  paper: {
    width: '100%',
    maxWidth: 286,
    minWidth: 286,
    backgroundColor: theme.palette.background.paper
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    minWidth: 91,
    '&:first-child': {
      marginRight: theme.spacing.unit
    },
    '&:last-child': {
      marginLeft: theme.spacing.unit
    }
  },
  actionButton: {
    background: 'transparent',
    border: '1px solid #229FFF',
    color: '#229FFF',
    fontSize: 13,
    height: 'auto',
    boxShadow: 'none',
    textTransform: 'none',
    marginLeft: 'auto',
    marginTop: 5,
    padding: '0 10px',
    minHeight: 25,
    '&:hover, &:focus': {
      background: '#229FFF',
      color: '#fff'
    }
  }
})

class FilterDialog extends React.Component {
  constructor(props) {
    super(props)

    const {
      allOptionsChecked,
      getAllFiltersChecked,
      stringifyOptions,
      getInitialFilterOptions
    } = makeFilter(props.filterOptions)

    this.allOptionsChecked = allOptionsChecked
    this.getAllFiltersChecked = getAllFiltersChecked
    this.stringifyOptions = stringifyOptions
    this.getInitialFilterOptions = getInitialFilterOptions

    this.state = {}
    this.state.range = props.range
    this.state.filter = props.filter
    const [initialYear, finalYear] = this.state.range.split(',')
    this.state.initialYear = Number(initialYear) || ''
    this.state.finalYear = Number(finalYear) || ''

    this.state.options = this.getInitialFilterOptions()

    const { readingTimes: _readingTimes } = props

    this.areAllChecked = areAllChecked
    this.toggleAll = toggleAll

    this.state.readingTimes = _readingTimes
  }

  handleChangeInitialYear = e => {
    this.setState({ initialYear: Number(e.target.value) || '' })
  }

  handleChangeFinalYear = e => {
    this.setState({ finalYear: Number(e.target.value) || '' })
  }

  handleEntering = () => {
    const [initialYear, finalYear] = this.props.range.split(',')

    this.setState(state => {
      let options = state.options.map(item => {
        return { ...item, checked: this.props.filter.includes(item.value) }
      })

      if (this.allOptionsChecked(options)) {
        options = this.getAllFiltersChecked()
      }

      return {
        initialYear: Number(initialYear) || '',
        finalYear: Number(finalYear) || '',
        options
      }
    })
  }

  handleCancel = () => {
    this.props.onClose()
  }

  handleOk = () => {
    const { initialYear, finalYear, options, readingTimes } = this.state
    const { changeFilter, changeReadingTime, onClose } = this.props
    const range =
      initialYear || finalYear
        ? [initialYear || yearsList[0], finalYear || yearsList[yearsList.length - 1]].join(',')
        : ''
    const filter = options.reduce((list, item) => {
      if (item.checked === true) {
        return [...list, item.value]
      }
      return list
    }, [])

    changeFilter({
      range,
      filter: this.stringifyOptions(filter)
    })

    changeReadingTime(readingTimes)

    onClose()
  }

  handleChangeFilter = value => {
    this.setState(state => {
      return {
        ...state,
        options: state.options.map(option => {
          if (option.value === value) {
            return { ...option, checked: !option.checked }
          }
          return option
        })
      }
    })
  }

  checkAllFilterOptions = () => {
    this.setState(state => ({
      ...state,
      options: state.options.map(option => {
        return { ...option, checked: true }
      })
    }))
  }

  uncheckAllFilterOptions = () => {
    this.setState(state => ({
      ...state,
      options: state.options.map(option => {
        return { ...option, checked: false }
      })
    }))
  }

  handleChangeAllOptionsCheck = () => {
    if (this.allOptionsChecked(this.state.options)) {
      this.uncheckAllFilterOptions()
    } else {
      this.checkAllFilterOptions()
    }
  }

  handleReverseAllOptionsCheck = () => {
    this.setState(state => {
      const predicate = option => {
        return { ...option, checked: !option.checked }
      }

      return {
        ...state,
        options: state.options.map(predicate)
      }
    })
  }

  handleReverseAllOptionsCheckReadingTimes = () => {
    this.setState(state => {
      const predicate = option => {
        return { ...option, checked: !option.checked }
      }

      return {
        ...state,
        readingTimes: state.readingTimes.map(predicate)
      }
    })
  }

  handleCheckAllReadingTimes = () => {
    this.setState(state => ({
      ...state,
      readingTimes: this.toggleAll(state.readingTimes)
    }))
  }

  handleChangeReadingTime = _key => {
    this.setState(state => ({
      ...state,
      readingTimes: state.readingTimes.map(({ key, checked, ...rest }) => ({
        ...rest,
        checked: _key === key ? !checked : checked,
        key
      }))
    }))
  }

  render() {
    const { handleChangeAllOptionsCheck, props } = this
    const { value, searchOrder, classes, changeFilter, changeReadingTime, ...other } = props

    const { paper } = classes

    return (
      <Dialog
        maxWidth="xs"
        onEntering={this.handleEntering}
        aria-labelledby="confirmation-dialog-title"
        classes={{ paper }}
        {...other}
      >
        <DialogTitle id="confirmation-dialog-title">Filtrar por:</DialogTitle>
        <DialogContent>
          <div className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="age-native-simple">Ano início</InputLabel>
              <Select
                native
                value={this.state.initialYear}
                onChange={this.handleChangeInitialYear}
                input={<Input id="age-native-simple" />}
              >
                <option value="" />
                {yearsList.map(year => {
                  return (
                    <option value={year} key={year}>
                      {year}
                    </option>
                  )
                })}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="age-simple">Ano fim</InputLabel>
              <Select
                native
                value={this.state.finalYear}
                onChange={this.handleChangeFinalYear}
                input={<Input id="age-simple" />}
              >
                <option value="" />
                {yearsList.filter(year => year >= this.state.initialYear).map(year => {
                  return (
                    <option value={year} key={year}>
                      {year}
                    </option>
                  )
                })}
              </Select>
            </FormControl>
          </div>
          <div style={{ marginTop: '18px', marginBottom: '8px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ paddingRight: '10px', whiteSpace: 'nowrap' }}>Tipo de conteúdo</span>
              <div style={{ borderBottom: '1px solid #D3D3D3', width: '100%' }} />
            </div>
          </div>
          <FormGroup>
            <div style={{ display: 'flex', marginTop: 10 }}>
              <Button
                color="primary"
                variant={'extendedFab'}
                aria-label="Inverter seleção"
                className={classes.actionButton}
                onClick={() => this.handleReverseAllOptionsCheck()}
              >
                Inverter seleção
              </Button>
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.allOptionsChecked(this.state.options)}
                    onChange={e => {
                      handleChangeAllOptionsCheck(e)
                    }}
                    value={'all'}
                  />
                }
                label={<strong>Todos</strong>}
              />
            </div>
            {this.state.options.map(option => (
              <div key={option.key}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={option.checked}
                      onChange={() => {
                        this.handleChangeFilter(option.value)
                      }}
                      value={option.value}
                    />
                  }
                  label={option.label}
                />
              </div>
            )
            )}
            {this.state.readingTimes.length ? (
              <>
                <div style={{ marginTop: '8px', marginBottom: '8px' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ paddingRight: '10px', whiteSpace: 'nowrap' }}>
                      Tempo de leitura
                    </span>
                    <div style={{ borderBottom: '1px solid #D3D3D3', width: '100%' }} />
                  </div>
                </div>
                <div style={{ display: 'flex', marginTop: 10 }}>
                  <Button
                    color="primary"
                    variant={'extendedFab'}
                    aria-label="Inverter seleção"
                    className={classes.actionButton}
                    onClick={() => this.handleReverseAllOptionsCheckReadingTimes()}
                  >
                    Inverter seleção
                  </Button>
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.areAllChecked(this.state.readingTimes)}
                        onChange={this.handleCheckAllReadingTimes}
                        value={'all_reading_times'}
                      />
                    }
                    label={<strong>Todos</strong>}
                  />
                </div>
              </>
            ) : null}
            {this.state.readingTimes &&
              (this.state.readingTimes || []).map(option => {
                return (
                  <div key={option.key}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={option.checked}
                          onChange={() => {
                            this.handleChangeReadingTime(option.value)
                          }}
                          value={option.value}
                        />
                      }
                      label={option.label}
                    />
                  </div>
                )
              })}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={this.handleOk}
            disabled={!this.state.options.find(option => option.checked)}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(FilterDialog)
