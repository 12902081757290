import React from 'react'
import useSpeechToText from 'react-hook-speech-to-text'
import SpeechToTextIcon from '../containers/Layout/SearchBar/SpeechToTextIcon'

const SpeechToTextComponent = (props) => {
  const { searchTerm, runSearch, changePopper, spokedPhrase } = props

  const {
    error,
    isRecording,
    results,
    startSpeechToText,
    stopSpeechToText,
    setResults,
  } = useSpeechToText({
    continuous: false,
    useLegacyResults: false,
    onStartSpeaking: () => console.log('chamou'),
  })

  if (error) return <p>Web Speech API is not available in this browser</p>

  const micIcon = () => {
    return isRecording ? '#229FFF' : '#7575759c'
  }

  const stopAndSearch = () => {
    stopSpeechToText()
    runSearch((results[0] || {}).transcript || '')
  }

  let speech = ''

  if (isRecording) {
    speech = (results[0] || {}).transcript || ''
  } else {
    if ((results[0] || {}).transcript || '') {
      setResults([])
    }
  }

  spokedPhrase(speech)

  return (
    <SpeechToTextIcon
      enabledSpeech={isRecording ? stopAndSearch : startSpeechToText}
      searchTerm={searchTerm}
      micIcon={micIcon}
      changePopper={changePopper}
    />
  )
}

export default SpeechToTextComponent
