import React from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux'
import { searchCidActions } from '../../../actions'
import CustomSearchbar from './CustomSearchbar'
import RelativeWrapper from './RelativeWrapper'
import { googleAnalytics } from '../../../utils'

const WrapperSearch = withStyles({
  wrapperSearch: {
    paddingTop: 16,
    paddingRight: 8,
    paddingBottom: 16,
    paddingLeft: 8,
    background: '#F7F7F7',
    position: 'relative',
    zIndex: 999,
  },
})(({ classes, children, ...rest }) => {
  return (
    <div {...rest} className={classNames(classes.wrapperSearch, 'container')}>
      {children}
    </div>
  )
})

class SearchBarLayout extends React.PureComponent {
  runSearch = (query) => {
    googleAnalytics(
      'menu_buscacid_busca',
      'acesso menu',
      'se digita algo para pesquisa CID',
      'Clique'
    )
    this.setState({ searchTerm: query })
    this.props.changeTerm(query)
  }

  render() {
    const { runSearch } = this
    const { searchTerm = '' } = this.props
    return (
      <WrapperSearch>
        <RelativeWrapper>
          <CustomSearchbar searchTerm={searchTerm} runSearch={runSearch} />
        </RelativeWrapper>
      </WrapperSearch>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    searchTerm: state.searchCid.term,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeTerm: (term) => {
      dispatch(searchCidActions.changeTerm(term))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBarLayout)
