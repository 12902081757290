export const CHANGE_TERM_CID = 'CHANGE_TERM_CID'
export const CHANGE_FILTER_CID = 'CHANGE_FILTER_CID'
export const RESET_FILTER_CID = 'RESET_FILTER_CID'

export const changeTerm = term => {
  return dispatch => {
    dispatch({ type: CHANGE_TERM_CID, term })
  }
}

export const changeFilter = filter => {
  return dispatch => {
    dispatch({ type: CHANGE_FILTER_CID, filter })
  }
}

export const resetFilter = () => {
  return dispatch => {
    dispatch({ type: RESET_FILTER_CID })
  }
}
