import React from 'react'
import { TextBlock } from 'react-placeholder/lib/placeholders'

const NoteItem = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '12px 22px',
        justifyContent: 'center'
      }}
    >
      <TextBlock rows={2} color="#d7d7d7" />
    </div>
  )
}

export default NoteItem
