import React from 'react'
import NotePlaceholder from './NotePlaceholder'
import withStyles from '@material-ui/core/styles/withStyles'
import Link from 'react-router-dom/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ReactPlaceholder from 'react-placeholder'
import { userInfoQueries } from '../../graphql/queries'
import Query from 'react-apollo/Query'
import ErrorHandling from '../../components/ErrorHandling'
import * as textUtils from '../../utils/text'
import contentTypeIcons from '../../icons/contentTypeIcons'
import penIcon from '../../icons/svg/pen.svg'
import NoteListPlaceholder from '../../components/Placeholder/NoteList'
import * as loggerClient from '../../services/loggerClient'
import { startAnimation } from '../../fixGestureNavigationIOS'
import { googleAnalytics } from '../../utils'

const styles = (theme) => ({
  root: {
    flex: 1,
    display: 'flex',
  },
  list: {
    paddingTop: 0,
    width: '100%',
  },
  item: {
    flex: 1,
    display: 'block',
    paddingLeft: 22,
    paddingRight: 22,
    alignItems: 'center',
  },
  wrapperIcon: {
    boxSizing: 'border-box',
    width: 48,
    height: 48,
    flex: 'none',
    marginRight: 16,
    borderRadius: '100%',
    border: '2px solid #184496',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '40px',
    },
    background: '#184496',
  },
  errorWrapper: {
    display: 'flex',
    height: '100%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  note: {
    display: '-webkit-box',
    wordBreak: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineClamp: 3,
    '-webkit-box-orient': 'vertical',
    lineHeight: '1.4',
    color: '#707070',
    fontWeight: '400',
  },
  title: {
    display: '-webkit-box',
    wordBreak: 'break-word',
    marginTop: 10,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineClamp: 1,
    lineHeight: '1.4',
    '-webkit-box-orient': 'vertical',
    color: '#002D72',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  noteIcon: {
    width: 20,
    display: 'inline-block',
    verticalAlign: '-0.1em',
    marginRight: 8,
  },
  contentTypeIcon: {
    width: 20,
    display: 'inline-block',
    verticalAlign: '-0.1em',
    marginRight: 8,
    background: '#184496',
    borderRadius: '50%',
  },
})

class FavoriteList extends React.Component {
  renderTypeIcon = (type) => {
    const { classes } = this.props
    return (
      <img
        className={classes.contentTypeIcon}
        src={contentTypeIcons[type]}
        aria-hidden={true}
        alt={type}
      />
    )
  }
  render() {
    const { classes } = this.props
    return (
      <Query query={userInfoQueries.GET_USER_INFOS}>
        {({ loading, error, data, refetch, networkStatus }) => {
          if (loading) {
            return (
              <ReactPlaceholder
                children={<div />}
                showLoadingAnimation={true}
                type="media"
                ready={false}
                rows={2}
                customPlaceholder={NoteListPlaceholder}
              />
            )
          }

          if (error)
            return (
              <ErrorHandling
                error={error}
                refetch={() => refetch()}
                networkStatus={networkStatus}
              />
            )

          if (!(data.notes || []).length) {
            return <NotePlaceholder className={classes.root} />
          }
          return (
            <div className={classes.root}>
              <List className={classes.list}>
                {data.notes.map(({ anotacao, conteudo }) => {
                  return (
                    <div key={conteudo._id}>
                      <ListItem
                        button
                        className={classes.item}
                        component={Link}
                        to={{
                          pathname: `/conteudo/${conteudo._id}`,
                          state: {
                            from: 'anotacoes',
                            contexto: 'anotacoes',
                            openNotes: true,
                          },
                        }}
                        onClick={() => {
                          googleAnalytics(
                            'menu_anotações_item',
                            'acesso menu',
                            'acesso a um item da lista obtida',
                            'Clique'
                          )
                          startAnimation()
                          loggerClient.onClickItem({
                            conteudo: `${conteudo._id}`,
                            tipo: `${conteudo.tipo}`,
                            origem: 'anotacoes',
                            contexto: 'anotacoes',
                          })
                        }}
                      >
                        <div className={classes.note}>
                          <img
                            className={classes.noteIcon}
                            src={penIcon}
                            aria-hidden={true}
                            alt={'icone anotação'}
                          />
                          {anotacao}
                        </div>
                        <div className={classes.title}>
                          {this.renderTypeIcon(conteudo.tipo)}
                          {textUtils.truncateEllipsis(
                            conteudo.titulo || conteudo.principioAtivo,
                            45,
                            true
                          )}
                        </div>
                      </ListItem>
                      <Divider />
                    </div>
                  )
                })}
              </List>
            </div>
          )
        }}
      </Query>
    )
  }
}

export default withStyles(styles, { withTheme: true })(FavoriteList)
