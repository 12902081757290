import React from 'react'
import SearchResultPlaceholder from './SearchResultPlaceholder'
import withStyles from '@material-ui/core/styles/withStyles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ResultItemIcon from './ResultItemIcon'
import ReactPlaceholder from 'react-placeholder'
import { covidQueries } from '../../graphql/queries'
import { searchActions } from '../../actions'
import Query from 'react-apollo/Query'
import ErrorHandling from '../../components/ErrorHandling'
import * as textUtils from '../../utils/text'
import Link from 'react-router-dom/Link'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import FilterListIcon from '@material-ui/icons/FilterList'
import SwapVertIcon from '@material-ui/icons/SwapVert'
import AdvancedSearchIcon from '@material-ui/icons/Search'
import SearchResultListScroll from './SearchResultListScroll'
import SearchResultListPlaceholder from '../../components/Placeholder/SearchResult/List'
import LoadMorePlaceholder from '../../components/Placeholder/SearchResult/LoadMore'
import OrderDialog from '../../components/OrderDialog'
import CustomTabs from '../../components/CustomTabs'
import Tab from '@material-ui/core/Tab'
import { startAnimation } from '../../fixGestureNavigationIOS'
import { availableSpecialties } from '../../utils'
import Popper from '@material-ui/core/Popper'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import AdvancedSearchDialog from '../../components/AdvancedSearchDialog'
import TimerIcon from '@material-ui/icons/Timer'

const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex',
    paddingBottom: 15
  },
  list: {
    paddingTop: 0,
    width: '100%'
  },
  item: {
    flex: 1,
    paddingLeft: 22,
    paddingRight: 22,
    alignItems: 'center',
    wordBreak: 'break-word'
  },
  itemTitle: {
    color: '#002D72',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginTop: 4,
    '& span': {
      display: '-webkit-box',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 2,
      '-webkit-box-orient': 'vertical'
    }
  },
  resultPlaceholderWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  itemChapter: {
    fontStyle: 'italic',
    marginTop: 4,
    '& span': {
      display: '-webkit-box',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 2,
      '-webkit-box-orient': 'vertical'
    }
  },
  itemBook: {
    fontSize: 14,
    marginTop: 4,
    '& span': {
      display: '-webkit-box',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 2,
      '-webkit-box-orient': 'vertical'
    }
  },
  itemAuthors: {
    color: '#606060',
    fontSize: 14,
    marginTop: 4,
    '& span': {
      display: '-webkit-box',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 2,
      '-webkit-box-orient': 'vertical'
    }
  },
  itemDescription: {
    fontSize: 14,
    marginTop: 4,
    '& em': {
      fontWeight: 'bold',
      fontStyle: 'normal'
    },
    '& > div': {
      display: '-webkit-box',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 3,
      '-webkit-box-orient': 'vertical'
    }
  },
  wrapperIcon: {
    boxSizing: 'border-box',
    width: 48,
    height: 48,
    flex: 'none',
    marginRight: 16,
    borderRadius: '100%',
    border: '2px solid #184496',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '40px'
    },
    background: '#184496'
  },
  errorWrapper: {
    display: 'flex',
    height: '100%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  optionsButton: {
    color: '#fff',
    fontSize: 13,
    height: 'auto',
    boxShadow: 'none',
    textTransform: 'none',
    marginLeft: 5,
    minHeight: 28,
    '&:hover, &:focus': {
      background: '#959595'
    }
  },
  actionButton: {
    display: 'flex',
    flex: 1,
    justifyContent: 'left',
    color: '#454545',
    fontSize: 13,
    height: 'auto',
    boxShadow: 'none',
    textTransform: 'none',
    padding: '10px',
    paddingRight: '35px',
    margin: '5px',
    minHeight: 28
  },
  optionsButtonIcon: {
    fontSize: 22
  },
  actionButtonIcon: {
    width: 25,
    height: 25,
    marginRight: 15
  },
  title: {
    color: '#505152',
    padding: 8,
    borderTop: '1px solid #e8e8e8',
    borderBottom: '1px solid #e8e8e8',
    paddingLeft: 22,
    paddingRight: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  numberResults: {
    color: '#002D72',
    fontSize: 12,
    fontWeight: 'bold'
  },
  copyright: {
    fontSize: 14,
    color: '#2e415f',
    marginTop: 5
  },
  customTab: {
    flex: 1
  },
  optionsWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  copyrightIcon: {
    width: '1rem',
    verticalAlign: 'middle',
    marginLeft: 5,
    marginRight: 3
  }
})

class FavoriteList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      orderIsOpen: false,
      filterIsOpen: false,
      advancedSearchIsOpen: false,
      lastTerm: '',
      anchorEl: null,
      popperOpen: false
    }
  }

  componentDidMount() {
    this.props.changeTerm(`"covid-19"`)
  }

  handleClick = event => {
    const currentTarget = event.currentTarget
    this.setState(state => {
      return {
        anchorEl: currentTarget,
        popperOpen: !state.popperOpen
      }
    })
  }

  closeOrder = () => {
    this.setState({
      orderIsOpen: false,
      anchorEl: null,
      popperOpen: false
    })
  }

  closeFilter = () => {
    this.setState({
      filterIsOpen: false,
      anchorEl: null,
      popperOpen: false
    })
  }

  closeAdvancedSearch = () => {
    this.setState({
      advancedSearchIsOpen: false,
      anchorEl: null,
      popperOpen: false
    })
  }

  openOrder = () => {
    this.setState({
      orderIsOpen: true
    })
  }

  openFilter = () => {
    this.setState({
      filterIsOpen: true
    })
  }

  openAdvancesSearch = () => {
    this.setState({
      advancedSearchIsOpen: true
    })
  }

  closeDropdown = () => {
    if (this.state.popperOpen) {
      this.setState({ popperOpen: false })
    }
  }

  renderSearchResultTitle = data => {
    const {
      classes,
      searchOrder,
      searchSpecialty,
      changeSpecialty
    } = this.props

    return (
      <div>
        <div className={classes.title}>
          <div>
            <Popper
              open={this.state.popperOpen}
              anchorEl={this.state.anchorEl}
              placement={'bottom-end'}
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper className={classes.optionsWrapper}>
                    <Button
                      aria-label="Ordenar"
                      onClick={this.openOrder}
                      className={classes.actionButton}
                    >
                      <SwapVertIcon className={classes.actionButtonIcon} />
                      Ordenar
                    </Button>
                    <Button
                      aria-label="Filtrar"
                      onClick={this.openFilter}
                      className={classes.actionButton}
                    >
                      <FilterListIcon className={classes.actionButtonIcon} />
                      Filtrar
                    </Button>
                    <Button
                      aria-label="Avançada"
                      onClick={this.openAdvancesSearch}
                      className={classes.actionButton}
                    >
                      <AdvancedSearchIcon className={classes.actionButtonIcon} />
                      Avançada
                    </Button>
                  </Paper>
                </Fade>
              )}
            </Popper>
            <OrderDialog
              open={this.state.orderIsOpen}
              onClose={this.closeOrder}
              value={searchOrder}
            />
            <AdvancedSearchDialog
              open={this.state.advancedSearchIsOpen}
              onClose={this.closeAdvancedSearch}
            />
          </div>
        </div>
        <CustomTabs
          value={searchSpecialty || 0}
          onChange={(event, newValue) => changeSpecialty(newValue)}
          aria-label={'Especialidades'}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {availableSpecialties.map(specialty => (
            <Tab className={classes.customTab} label={specialty} />
          ))}
        </CustomTabs>
      </div>
    )
  }

  render() {
    const { classes, searchSpecialty } = this.props
    return (
      <Query
        query={covidQueries.SEARCH_CONTENT}
        variables={{
          term: `"covid-19"`,
          offset: 0,
          specialty: availableSpecialties[searchSpecialty]
        }}
      >
        {({ loading, error, data, refetch, networkStatus, fetchMore }) => {
          if (loading) {
            return (
              <ReactPlaceholder
                children={<div />}
                showLoadingAnimation={true}
                type="media"
                ready={false}
                rows={2}
                customPlaceholder={SearchResultListPlaceholder}
              />
            )
          }

          if (error)
            return (
              <ErrorHandling
                error={error}
                refetch={() => refetch()}
                networkStatus={networkStatus}
              />
            )

          if (!((data.covid || {}).items || []).length) {
            return (
              <div className={classes.resultPlaceholderWrapper}>
                {this.renderSearchResultTitle(data)}
                <SearchResultPlaceholder />
              </div>
            )
          }

          return (
            <SearchResultListScroll
              loadMore={() => {
                fetchMore({
                  variables: {
                    offset: data.covid.items.length
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev
                    return {
                      ...prev,
                      covid: {
                        ...prev.covid,
                        items: [...prev.covid.items, ...fetchMoreResult.covid.items]
                      }
                    }
                  }
                })
              }}
              useWindow={false}
              hasMore={data.covid.items.length < data.covid.total}
              loader={
                <div key={'placeholder-loading'}>
                  <ReactPlaceholder
                    children={<div />}
                    showLoadingAnimation={true}
                    type="media"
                    ready={false}
                    rows={2}
                    customPlaceholder={LoadMorePlaceholder}
                  />
                </div>
              }
            >
              {this.renderSearchResultTitle(data)}
              <div className={classes.root}>
                <List className={classes.list}>
                  {data.covid.items.map(
                    (
                      {
                        titulo,
                        principioAtivo,
                        tipo,
                        id,
                        autores,
                        tituloCapitulo,
                        tituloLivro,
                        descricao,
                        highlight,
                        autoresDiretriz,
                        copyright,
                        minutosLeitura = 1
                      }
                    ) => {
                      if (minutosLeitura == 0) {
                        minutosLeitura = 1
                      }
                      return (
                        <div key={id}>
                          <ListItem
                            button
                            className={classes.item}
                            component={Link}
                            to={`/conteudo-sobre-covid/${id}`}
                            onClick={() => {
                              startAnimation()
                            }}
                          >
                            <ResultItemIcon tipo={tipo} id={id} />
                            <div>
                              <div className={classes.itemTitle}>
                                <span>
                                  {textUtils.truncateEllipsis(
                                    titulo || principioAtivo || '',
                                    120,
                                    true
                                  )}
                                </span>
                              </div>
                              {tituloCapitulo && (
                                <div className={classes.itemChapter}>
                                  <span>
                                    {textUtils.truncateEllipsis(tituloCapitulo, 120, true)}
                                  </span>
                                </div>
                              )}

                              {tituloLivro && (
                                <div className={classes.itemBook}>
                                  <span>
                                    Livro: {textUtils.truncateEllipsis(tituloLivro, 120, true)}
                                  </span>
                                </div>
                              )}
                              <div className={classes.itemAuthors}>
                                <span>
                                  {textUtils.truncateEllipsis(
                                    autoresDiretriz || autores,
                                    120,
                                    true
                                  )}
                                </span>
                              </div>
                              <div className={classes.itemDescription}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: textUtils.truncateEllipsis(
                                      highlight || descricao || '',
                                      160,
                                      true
                                    )
                                  }}
                                />
                              </div>
                              {minutosLeitura ? (
                                <div className={classes.copyright}>
                                  {tipo === 'podcast' ? 'Realização' : 'Publicação'}: {copyright}
                                  {<TimerIcon className={classes.copyrightIcon} />} {minutosLeitura}{' '}
                                  min de {['aula', 'habilidades_clinicas', 'webinar', 'podcast'].includes(tipo) ? 'duração' : 'leitura' }
                                </div>
                              ) : (
                                <div className={classes.copyright}>
                                  {tipo === 'podcast' ? 'Realização' : 'Publicação'}: {copyright}
                                </div>
                              )}
                            </div>
                          </ListItem>
                          <Divider />
                        </div>
                      )
                    }
                  )}
                </List>
              </div>
            </SearchResultListScroll>
          )
        }}
      </Query>
    )
  }
}

const mapStateToProps = state => {
  return {
    searchOrder: state.search.order,
    searchFilter: state.search.filter.filter,
    searchRange: state.search.filter.range,
    searchSpecialty: state.search.filter.specialty
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeFilter: filter => {
      dispatch(searchActions.changeFilter(filter))
    },
    resetFilter: () => {
      dispatch(searchActions.resetFilter())
    },
    changeSpecialty: specialty => {
      dispatch(searchActions.changeSpecialty(specialty))
    },
    changeTerm: term => {
      dispatch(searchActions.changeTerm(term))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(withStyles(styles, { withTheme: true })(FavoriteList))
