import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './normalize.css'
import 'react-placeholder/lib/reactPlaceholder.css'
import 'informed-shared/content-styles/dist/content.css'
import './main.css'
import 'typeface-roboto'
import * as serviceWorker from './serviceWorker'
import { theme } from './muiTheme'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import ApolloProvider from 'react-apollo/ApolloProvider'
import { ConnectedRouter } from 'connected-react-router'
import { Offline } from 'react-detect-offline'
import Snackbar from '@material-ui/core/Snackbar'
import { Provider } from 'react-redux'
import client from './store/apolloClient'
import store, { history, persistor } from './store'
import AddToHomeScreen from './components/AddToHomeScreen'
import { PersistGate } from 'redux-persist/integration/react'
import 'inobounce'
import ApolloClientService from './services/ApolloClientService'
import './shims'
import fixGestureNavigationIOS from './fixGestureNavigationIOS'
import serviceWorkerConfig from './serviceWorkerConfig'
import { safeLocalStorage } from './utils'
import firebase from 'firebase/app'
import 'firebase/analytics'

const { REACT_APP_BUGSNAG, REACT_APP_RELEASE_STAGE } = process.env

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBtpV9Slx7qoEmmOmwNty3xS48WhAbTdzE',
  authDomain: 'informed-d44df.firebaseapp.com',
  databaseURL: 'https://informed-d44df.firebaseio.com',
  projectId: 'informed-d44df',
  storageBucket: 'informed-d44df.appspot.com',
  messagingSenderId: '888233343591',
  appId: '1:888233343591:web:e9815df9a97d2188bdb2c2',
  measurementId: 'G-4MPXQ3Q3HS',
}

if (safeLocalStorage() && safeLocalStorage().removeItem) {
  safeLocalStorage().removeItem('persist:INFORMED_SEARCH')
}

const supportsHistory = 'pushState' in window.history

firebase.initializeApp(firebaseConfig)

fixGestureNavigationIOS()

ReactDOM.render(
  <>
    <Offline polling={{ enabled: false }}>
      <Snackbar message={'Percebemos que você está offline.'} open={true} />
    </Offline>

    <AddToHomeScreen />

    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter forceRefresh={!supportsHistory} history={history}>
          <ApolloProvider
            client={client}
            ref={(apolloProviderRef) => ApolloClientService.setApolloProvider(apolloProviderRef)}
          >
            <MuiThemeProvider theme={theme}>
              <App />
            </MuiThemeProvider>
          </ApolloProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </>,
  document.getElementById('root')
)
serviceWorker.register(serviceWorkerConfig)
