import firebase from 'firebase/app'
import 'firebase/analytics'

const googleAnalytics = async (event, category, action, type) => {
  await firebase.analytics().logEvent(event, {
    category,
    action,
    type,
  })
}

export default googleAnalytics
