import * as favoriteMutations from './favorite'
import * as authMutations from './auth'
import * as noteMutations from './note'
import * as messagesMutations from './messages'
import * as userMutations from './user'
import * as feedbackMutations from './feedback'
import * as sessionMutations from './session'

export {
  favoriteMutations,
  authMutations,
  noteMutations,
  messagesMutations,
  userMutations,
  feedbackMutations,
  sessionMutations
}
