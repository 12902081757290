import React from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import LatestSearchesBox from '../../../components/LatestSearchesBox'
import { connect } from 'react-redux'
import { searchActions, userActions } from '../../../actions'
import HistoryWrapperAndIcon from './HistoryWrapperAndIcon'
import CustomSearchbar from './CustomSearchbar'
import RelativeWrapper from './RelativeWrapper'
import SubscriptionBox from '../../../components/SubscriptionBox'
import Query from 'react-apollo/Query'
import { userQueries } from '../../../graphql/queries'
import SpeechToText from '../../../components/SpeechToText'

const WrapperSearch = withStyles({
  wrapperSearch: {
    paddingTop: 16,
    paddingRight: 8,
    paddingBottom: 16,
    paddingLeft: 8,
    background: '#F7F7F7',
    position: 'relative',
    zIndex: 999,
  },
})(({ classes, children, ...rest }) => {
  return (
    <div {...rest} className={classNames(classes.wrapperSearch, 'container')}>
      {children}
    </div>
  )
})
class SearchBarLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      shouldShowLatestSearches: false,
      hideByExpiration: false,
    }
  }

  hideByExpirationSignature = () => {
    this.setState({ hideByExpiration: true })
  }

  showLatestSearches = () => {
    this.setState({ shouldShowLatestSearches: !this.state.shouldShowLatestSearches })
  }

  spokedPhrase = (spokedPhrase) => {
    if (spokedPhrase) this.props.changeTerm(spokedPhrase)
  }

  hideLatestSearches = () => {
    this.setState({ shouldShowLatestSearches: false })
  }

  runSearch = (query) => {
    this.hideLatestSearches()
    this.setState({ searchTerm: query })
    this.props.changeTerm(query)
  }

  changePopper = () => {
    this.props.changeClosePopper(false)
    // this.props.popperOpen = false
  }

  latestSearchesIcon = () => {
    return this.state.shouldShowLatestSearches ? '#757575' : '#7575759c'
  }

  constructDate = (stringData) => {
    let arrData = stringData.split('/')
    let stringFormatada = arrData[1] + '-' + arrData[0] + '-' + arrData[2]
    return stringFormatada
  }

  render() {
    const { shouldShowLatestSearches, hideByExpiration } = this.state
    const {
      runSearch,
      showLatestSearches,
      spokedPhrase,
      latestSearchesIcon,
      constructDate,
      hideByExpirationSignature,
    } = this
    const { searchTerm = '' } = this.props

    return (
      <Query query={userQueries.GET_USER} fetchPolicy={'cache-and-network'}>
        {({ loading, error, data = {} }) => {
          const { user = {} } = data

          if (user.expirationDate) {
            let dataExpiracao = user.expirationDate
            let dataAtual = new Date()
            if (dataAtual > dataExpiracao) {
              hideByExpirationSignature()
            }
          } else {
            user.expirationDate = '12-12-2021'
          }

          if (!hideByExpiration && user.status !== 'expirado') {
            return (
              <WrapperSearch>
                {!hideByExpiration && user.status === 'trial' ? (
                  <SubscriptionBox daysRemaining={user.daysRemaining} />
                ) : null}
                <RelativeWrapper>
                  <CustomSearchbar
                    searchTerm={searchTerm}
                    runSearch={runSearch}
                    changePopper={this.changePopper}
                  />
                  <HistoryWrapperAndIcon
                    showLatestSearches={showLatestSearches}
                    searchTerm={searchTerm}
                    latestSearchesIcon={latestSearchesIcon}
                    changePopper={this.changePopper}
                  />
                  <SpeechToText
                    spokedPhrase={spokedPhrase}
                    searchTerm={searchTerm}
                    runSearch={runSearch}
                    changePopper={this.changePopper}
                  />
                </RelativeWrapper>
                <RelativeWrapper>
                  <LatestSearchesBox
                    runSearch={runSearch}
                    shouldShowBox={shouldShowLatestSearches}
                  />
                </RelativeWrapper>
              </WrapperSearch>
            )
          } else {
            return <WrapperSearch></WrapperSearch>
          }
        }}
      </Query>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    searchTerm: state.search.term,
    popperOpen: state.search.popperOpen,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeTerm: (term) => {
      dispatch(searchActions.changeTerm(term))
    },
    changeClosePopper: (popper) => {
      dispatch(searchActions.changeClosePopper(popper))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBarLayout)
