import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Snackbar from '@material-ui/core/Snackbar'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import OnlyOnline from './OnlyOnline'
import CircularProgress from '@material-ui/core/CircularProgress'
import Slide from '@material-ui/core/Slide'
import IconButton from '@material-ui/core/IconButton'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import { userMutations } from '../graphql/mutations'
import * as authService from '../services/auth'
import Mutation from 'react-apollo/Mutation'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

const styles = (theme) => ({
  paper: {
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    '& > div': {
      overflow: 'inherit',
    },
  },
  progress: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto',
  },
  content: {
    minHeight: 60,
    position: 'relative',
  },
  toolBar: {
    padding: '0 8px',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  toolBarRight: {
    marginLeft: 'auto',
    margin: '0 8px',
  },
  closeButton: {
    position: 'absolute',
    top: -20,
    right: -20,
    '&, &:hover, &:focus': {
      background: '#229FFF',
      color: '#fff',
      width: 40,
      height: 40,
      marginLeft: 'auto',
    },
  },
  appBar: {
    backgroundColor: '#002d72',
    zIndex: theme.zIndex.drawer + 1,
  },
  deleteIcon: {
    color: '#808080',
    fontSize: 64,
    marginBottom: 20,
  },
})

function Transition(props) {
  return <Slide direction="up" {...props} />
}

class DeleteAccountdDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      errorMessage: '',
    }
  }

  renderSpinner = () => {
    const { classes } = this.props
    return <CircularProgress className={classes.progress} size={30} />
  }

  closeErrorMessage = () => {
    this.setState({
      errorMessage: '',
    })
  }

  renderDeleteOption = ({ loading, classes }) => {
    const { innerWidth: width, innerHeight: height } = window

    if (loading) {
      return this.renderSpinner()
    }

    return (
      <Mutation
        mutation={userMutations.DELETE_ACCOUNT}
        onCompleted={(data) => {
          authService.onLogout()
          window.location.pathname = '/'
        }}
        onError={(errors) => {
          this.setState({ errorMessage: 'Ocorreu um erro inesperado. Por favor, tente novamente.' })
        }}
      >
        {(deleteUser, { loading, error, data }) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: height - 90,
              }}
            >
              <div>
                <div
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <DeleteForeverIcon className={classes.deleteIcon} />

                  <Typography>Você tem certeza que realmente deseja excluir sua conta ?</Typography>
                  <Typography style={{ paddingTop: 12 }}>
                    Lembre-se que essa ação é irreversível.
                  </Typography>

                  <Button
                    style={{
                      backgroundColor: '#ff4c4c',
                      color: loading ? 'rgba(255,255,255,.4)' : '#fff',
                      fontSize: width > 800 ? 16 : 12,
                      marginTop: 30,
                    }}
                    color={'white'}
                    disabled={loading}
                    onClick={() => {
                      deleteUser()
                    }}
                  >
                    Confirmar exclusão da minha conta
                  </Button>
                </div>
              </div>
            </div>
          )
        }}
      </Mutation>
    )
  }

  render() {
    const { classes, message, ...other } = this.props
    const { errorMessage, loading } = this.state

    return (
      <Dialog
        maxWidth="xs"
        fullScreen
        aria-labelledby="confirmation-dialog-title"
        TransitionComponent={Transition}
        className={classes.root}
        {...other}
      >
        <AppBar className={classes.appBar}>
          <Toolbar disableGutters={true} className={classNames(classes.toolbar, 'container')}>
            <IconButton color="inherit" onClick={this.props.onClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="title" color="inherit" className={classes.flex}>
              Excluir conta
            </Typography>
            <div className={classes.toolBarRight} />
          </Toolbar>
        </AppBar>
        <OnlyOnline>
          <DialogContent className={classNames(classes.content, 'container')}>
            <div className={classes.toolbar} />
            {this.renderDeleteOption({ loading, classes })}
          </DialogContent>
        </OnlyOnline>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={3500}
          onClose={() => {
            this.closeErrorMessage()
          }}
          open={!!errorMessage}
          ContentProps={{
            'aria-describedby': 'login-error',
          }}
          message={<span id="login-error">{errorMessage}</span>}
        />
      </Dialog>
    )
  }
}

export default withStyles(styles)(DeleteAccountdDialog)
