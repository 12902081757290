import React, { useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import appleStoreIcon from '../../../icons/i_apple.png'
import playStoreIcon from '../../../icons/i_google.png'
import classNames from 'classnames'
import Link from 'react-router-dom/Link'

const styles = (theme) => ({
  root: {
    // ...theme.mixins.gutters(),
    marginBottom: 15,
    background: '#5AB9F7',
    height: 'auto',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 4,
    opacity: 1,
    padding: 15,
    margin: 15,
  },
  container: {
    display: 'inline-table',
    margin: '0 auto',
    textAlign: 'center',
    paddingTop: 22,
  },
  text: {
    color: '#FFFFFF',
    display: 'flex',
    fontSize: '1.0em',
    minHeight: 42,
    fontWeight: 'bold',
    alignItems: 'center',
    maxWidth: 165,
  },
  signatureButton: {
    background: '#124397',
    maxHeight: 42,
    height: '100%',
    color: '#FFFFFF',
    fontSize: '0.9em',
    fontWeight: 'bold',
    maxWidth: 184,
    float: 'right',
    '&, &:hover, &:focus': {
      background: '#124397',
    },
  },
  textTop: {
    textAlign: 'center',
    padding: 10,
    color: '#fff',
  },
  containerBox: {
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: 'center',
  },
  storeIcons: {
    width: '125px',
    margin: '2px',
  },
  warnIconBox: {
    color: '#fff',
    textAlign: 'center',
  },
  warnIcon: {
    fontSize: '7vh',
  },
  butons: {
    marginBottom: '6vh',
  },
})

const SearchButton = withStyles({
  root: {
    border: '1px solid #124397',
    color: '#124397',
    width: '45%',
  },
})(Button)

const SubscriptionWarn = (props) => {
  const { classes } = props
  const [showSubscriptionDialog, setSubscriptionDialog] = useState(false)

  return (
    <>
      <div className={classes.container}>
        <div className={classes.butons}>
          <SearchButton fullWidth component={Link} to="/busca-de-cid" variant="outlined">
            Buscar CID
          </SearchButton>
          <div style={{ display: 'inline-block', height: 'auto', width: 10 }} />
          {/* <SearchButton fullWidth component={Link} to="/covid-19" variant="outlined">
            COVID-19
          </SearchButton> */}
        </div>
        <Paper className={classes.root} elevation={2}>
          <div style={{ marginBottom: 39 }}>
            <div className={classes.warnIconBox}>
              <ErrorOutlineIcon className={classes.warnIcon} />
            </div>
            <div className={classes.textTop}>
              <strong>Seu periodo gratuito terminou</strong>
            </div>
          </div>
          <div>
            <div className={classes.textTop}>
              <strong>Faça sua assinatura através de nosso aplicativo.</strong>
            </div>
          </div>

          <div className={classNames(classes.actionsWrapper, classes.containerBox)}>
            <a
              target="_blank"
              href="//apps.apple.com/br/app/informed/id1467783750"
              rel="noopener noreferrer"
            >
              <img src={appleStoreIcon} alt={'Apple Store'} className={classes.storeIcons} />
            </a>
            <a
              target="_blank"
              href="//play.google.com/store/apps/details?id=digital.informed.app"
              rel="noopener noreferrer"
            >
              <img src={playStoreIcon} alt={'Google Play'} className={classes.storeIcons} />
            </a>
          </div>
        </Paper>
      </div>
      {/* <SubscriptionDialog
        open={showSubscriptionDialog}
        onClose={() => {
          setSubscriptionDialog(false)
        }}
      /> */}
    </>
  )
}

export default withStyles(styles, { withTheme: true })(SubscriptionWarn)
