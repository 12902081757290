import { userActions } from '../actions'
const { GET_USER } = userActions

const getInitialState = () => ({
  user: {
    userid: '',
    email: '',
    name: '',
    lastname: '',
    gender: '',
    speciality: [],
    origin: '',
    daysRemaining: '',
    expirationDate: '',
    status: '',
  },
})

const user = (state = getInitialState(), action = '') => {
  switch (action.type) {
    case GET_USER:
      return { ...state, user: action.user }
    default:
      return state
  }
}

export default user
