import React from 'react'
import HistoryIcon from '@material-ui/icons/History'
import withStyles from '@material-ui/core/styles/withStyles'

const HistoryWrapperAndIcon = withStyles({
  root: {
    position: 'absolute',
    top: 12,
    bototm: 0,
    right: 12,
    background: 'transparent',
    border: 0,
    padding: 0,
  },
})(
  ({
    classes,
    children,
    searchTerm,
    showLatestSearches,
    latestSearchesIcon,
    changePopper,
    ...rest
  }) => (
    <button
      {...rest}
      onClick={() => {
        changePopper()
        showLatestSearches()
      }}
      className={classes.root}
      style={{ display: searchTerm ? 'none' : 'flex' }}
    >
      <HistoryIcon style={{ color: latestSearchesIcon() }} />
    </button>
  )
)

export default HistoryWrapperAndIcon
