import React from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import IconButton from '@material-ui/core/IconButton'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import Query from 'react-apollo/Query'
import { messagesQueries } from '../../graphql/queries'

const styles = () => ({
  notificationButton: {
    position: 'relative'
  },
  notificationActive: {
    background: '#FF0000',
    display: 'block',
    width: 8,
    height: 8,
    border: '2px solid #002d72',
    position: 'absolute',
    borderRadius: '50%',
    top: 12,
    right: 15
  }
})

class NotificationButton extends React.PureComponent {
  render() {
    const { children, classes, ...rest } = this.props

    return (
      <IconButton {...rest} className={classNames(classes.menuButton, classes.notificationButton)}>
        <Query query={messagesQueries.GET_MESSAGES} fetchPolicy={'cache-and-network'}>
          {({ loading, error, data = {} }) => {
            const { messages = [] } = data
            if (loading || error || !messages.length) {
              return null
            }
            return <span className={classes.notificationActive} />
          }}
        </Query>
        <NotificationsNoneIcon />
      </IconButton>
    )
  }
}

export default withStyles(styles)(NotificationButton)
