import React from 'react'
import SearchResultPlaceholder from './SearchResultPlaceholder'
import withStyles from '@material-ui/core/styles/withStyles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ResultItemIcon from './ResultItemIcon'
import ReactPlaceholder from 'react-placeholder'
import { cidQueries } from '../../../graphql/queries'
import { searchCidActions } from '../../../actions'
import Query from 'react-apollo/Query'
import ErrorHandling from '../../../components/ErrorHandling'
import { connect } from 'react-redux'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import SearchResultListScroll from './SearchResultListScroll'
import SearchResultListPlaceholder from '../../../components/Placeholder/SearchResult/List'
import LoadMorePlaceholder from '../../../components/Placeholder/SearchResult/LoadMore'
import { changeFilter } from '../../../actions/search'

const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex',
    paddingBottom: 15
  },
  list: {
    paddingTop: 0,
    width: '100%'
  },
  item: {
    flex: 1,
    paddingLeft: 22,
    paddingRight: 22,
    alignItems: 'center',
    wordBreak: 'break-word'
  },
  itemTitle: {
    color: '#002D72',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginTop: 4,
    '& span': {
      display: '-webkit-box',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 2,
      '-webkit-box-orient': 'vertical'
    }
  },
  resultPlaceholderWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  itemDescription: {
    fontSize: 14,
    marginTop: 4,
    '& em': {
      fontWeight: 'bold',
      fontStyle: 'normal'
    },
    '& > div': {
      display: '-webkit-box',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 3,
      '-webkit-box-orient': 'vertical'
    }
  },
  title: {
    color: '#505152',
    padding: 0,
    borderTop: '1px solid #e8e8e8',
    borderBottom: '1px solid #e8e8e8',
    paddingLeft: 22,
    paddingRight: 0,
    display: 'flex',
    minHeight: 46,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  numberResults: {
    color: '#002D72',
    fontSize: 12,
    fontWeight: 'bold'
  },
  switchControlLabel: {
    '& > span': {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    },
    '&:last-child': {
      marginRight: 0
    }
  }
})

class SearchResultList extends React.Component {
  handleChangeFilter = (name, value) => {
    const { filter, changeFilter } = this.props
    const newFilter = { ...filter, [name]: !value }

    if (!newFilter.cid10 && !newFilter.cid11) {
      return changeFilter({ cid10: !filter.cid10, cid11: !filter.cid11 })
    }
    changeFilter(newFilter)
  }

  componentWillUnmount() {
    this.props.changeTerm('')
    this.props.resetFilter()
  }

  renderSearchResultTitle = data => {
    const { classes, filter } = this.props

    return (
      <div>
        <div className={classes.title}>
          <span className={classes.numberResults}>{(data.cid || {}).total || 0} resultados</span>
          {/* <div>
            <FormControlLabel
              value="start"
              className={classes.switchControlLabel}
              control={
                <Switch
                  checked={filter.cid10}
                  onChange={e => this.handleChangeFilter('cid10', filter.cid10)}
                  value="cid10"
                  color="secondary"
                />
              }
              label="CID 10"
              labelPlacement="start"
            />
            <FormControlLabel
              value="start"
              className={classes.switchControlLabel}
              control={
                <Switch
                  checked={filter.cid11}
                  onChange={e => this.handleChangeFilter('cid11', filter.cid11)}
                  value="cid11"
                  color="secondary"
                />
              }
              label="CID 11"
              labelPlacement="start"
            />
          </div> */}
        </div>
      </div>
    )
  }

  getFilterQueryVariable = () =>
    Object.entries(this.props.filter)
      .reduce((accumulator, [key, value]) => {
        return value ? [...accumulator, key] : [...accumulator]
      }, [])
      .join(',')

  render() {
    const { classes, searchTerm, filter } = this.props
    return (
      <Query
        query={cidQueries.SEARCH_CID}
        variables={{
          term: searchTerm,
          offset: 0,
          filter: this.getFilterQueryVariable()
        }}
      >
        {({ loading, error, data, refetch, networkStatus, fetchMore }) => {
          if (loading) {
            return (
              <ReactPlaceholder
                children={<div />}
                showLoadingAnimation={true}
                type="media"
                ready={false}
                rows={2}
                customPlaceholder={SearchResultListPlaceholder}
              />
            )
          }

          if (error) {
            return (
              <ErrorHandling
                error={error}
                refetch={() => refetch()}
                networkStatus={networkStatus}
              />
            )
          }

          if (!((data.cid || {}).items || []).length) {
            return (
              <div className={classes.resultPlaceholderWrapper}>
                {this.renderSearchResultTitle(data)}
                <SearchResultPlaceholder />
              </div>
            )
          }

          return (
            <SearchResultListScroll
              loadMore={() => {
                fetchMore({
                  variables: {
                    offset: data.cid.items.length
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev
                    return {
                      ...prev,
                      cid: {
                        ...prev.cid,
                        items: [...prev.cid.items, ...fetchMoreResult.cid.items]
                      }
                    }
                  }
                })
              }}
              useWindow={false}
              hasMore={data.cid.items.length < data.cid.total}
              loader={
                <div key={'placeholder-loading'}>
                  <ReactPlaceholder
                    children={<div />}
                    showLoadingAnimation={true}
                    type="media"
                    ready={false}
                    rows={2}
                    customPlaceholder={LoadMorePlaceholder}
                  />
                </div>
              }
            >
              {this.renderSearchResultTitle(data)}
              <div className={classes.root}>
                <List className={classes.list}>
                  {data.cid.items.map(({ id, tipo, codigo, nome }, index) => {
                    return (
                      <div key={id}>
                        <ListItem className={classes.item} component={'div'}>
                          <ResultItemIcon tipo={tipo} id={id} />
                          <div>
                            <div className={classes.itemTitle}>
                              <span>{codigo}</span>
                            </div>
                            <div className={classes.itemDescription}>
                              <span>{nome}</span>
                            </div>
                          </div>
                        </ListItem>
                        <Divider />
                      </div>
                    )
                  })}
                </List>
              </div>
            </SearchResultListScroll>
          )
        }}
      </Query>
    )
  }
}

const mapStateToProps = state => {
  return {
    filter: state.searchCid.filter
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeFilter: filter => {
      dispatch(searchCidActions.changeFilter(filter))
    },
    resetFilter: () => {
      dispatch(searchCidActions.resetFilter())
    },
    changeTerm: term => {
      dispatch(searchCidActions.changeTerm(term))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(withStyles(styles, { withTheme: true })(SearchResultList))
