import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Query from 'react-apollo/Query'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import IconClose from '../icons/IconClose'
import appleStoreIcon from '../icons/i_apple.png'
import playStoreIcon from '../icons/i_google.png'
import classNames from 'classnames'
import Button from '@material-ui/core/Button'

const styles = (theme) => ({
  paper: {
    overflow: 'inherit',
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    '& > div': {
      overflow: 'inherit',
      alignItems: 'center',
      padding: 30,
    },
  },
  progress: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto',
  },
  content: {
    minHeight: 60,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  actionsWrapper: {
    width: '90% !important',
  },
  container: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    width: '85%',
    textAlign: 'center',
    maxWidth: 320,
  },
  storeIcons: {
    width: '125px',
    margin: '2px',
  },
  header: {
    padding: 0,
    textAlign: 'left',
    color: '#003884',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    wordBreak: 'break-word',
  },
  closeButton: {
    position: 'absolute',
    top: -20,
    right: -20,
    '&, &:hover, &:focus': {
      background: '#229FFF',
      color: '#fff',
      width: 40,
      height: 40,
      marginLeft: 'auto',
    },
  },
  subHeaderInfo: {
    marginTop: 10,
    marginBottom: 10,
    fontWeight: 500,
  },
  isbn: {
    fontWeight: 500,
    marginTop: 10,
    marginBottom: 10,
  },
  progressWrapper: {
    padding: 54,
  },
  detailBlock: {
    marginTop: 10,
    marginBottom: 10,
  },
  autoresList: {
    paddingLeft: 0,
    margin: '5px 0 0 0',
    display: 'flex',
    flexDirection: 'column',
    '& > li': {
      marginTop: '.3em',
      listStyle: 'none',
      display: 'inline-flex',
      alignItems: 'flex-start',
      '&::before': {
        content: '"\\2022"',
        marginRight: '.2em',
        fontSize: '1.6em',
        lineHeight: '0.8em',
      },
    },
  },
  infoBox: {
    marginTop: 20,
    marginBottom: 10,
    paddingTop: 10,
    borderTop: '1px solid #ddd',
  },
  tituloSecao: {
    marginTop: 10,
    marginBottom: 10,
    color: '#434242',
    fontSize: 16,
    textAlign: 'center',
    letterSpacing: 0,
    opacity: 1,
    marginBottom: 38,
  },
  tituloLivro: {
    marginTop: 10,
    marginBottom: 10,
  },
  editores: {
    marginTop: 10,
  },
})

const SearchButton = withStyles({
  root: {
    border: '1px solid #002D72',
    color: '#002D72',
    width: '80%',
    borderRadius: 4,
    marginTop: 38,
  },
})(Button)

class SubscriptionDialog extends React.Component {
  handleCancel = () => {
    this.props.onClose()
  }

  renderSpinner = () => {
    const { classes } = this.props
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress className={classes.progress} size={30} />
      </div>
    )
  }

  render() {
    const { value, searchOrder, classes, ...other } = this.props

    return (
      <Dialog
        maxWidth="xs"
        fullWidth
        aria-labelledby="confirmation-dialog-title"
        className={classes.root}
        {...other}
      >
        <div>
          <div className={classes.tituloSecao}>
            <strong>Faça sua assinatura através de nosso aplicativo.</strong>
          </div>
        </div>

        <div className={classNames(classes.actionsWrapper, classes.container)}>
          <a
            target="_blank"
            href="//apps.apple.com/br/app/informed/id1467783750"
            rel="noopener noreferrer"
          >
            <img src={appleStoreIcon} alt={'Apple Store'} className={classes.storeIcons} />
          </a>
          <a
            target="_blank"
            href="//play.google.com/store/apps/details?id=digital.informed.app"
            rel="noopener noreferrer"
          >
            <img src={playStoreIcon} alt={'Google Play'} className={classes.storeIcons} />
          </a>
          <SearchButton fullWidth onClick={this.handleCancel} variant="outlined">
            FECHAR
          </SearchButton>
        </div>
      </Dialog>
    )
  }
}

export default withStyles(styles)(SubscriptionDialog)
