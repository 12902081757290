import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import bookmarkIcon from '../../icons/svg/bookmark.svg'
import penIcon from '../../icons/svg/pen.svg'
import Query from 'react-apollo/Query'
import contentTypeIcons from '../../icons/contentTypeIcons'
import { userInfoQueries } from '../../graphql/queries'

const styles = theme => ({
  root: {
    boxSizing: 'border-box',
    width: 48,
    height: 48,
    flex: 'none',
    marginRight: 16,
    borderRadius: '100%',
    border: '2px solid #184496',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    background: '#184496'
  },
  typeImage: {
    width: '40px'
  },
  imageFavorite: {
    width: 16,
    position: 'absolute',
    right: 0,
    bottom: -10
  },
  imageNote: {
    width: 16,
    position: 'absolute',
    left: 0,
    bottom: -10
  }
})

const ResultItemIcon = ({ classes, tipo, id }) => {
  return (
    <Query query={userInfoQueries.GET_USER_INFOS}>
      {({ loading, error, data: userInfos, refetch, networkStatus }) => {
        if (loading || error) return null
        const isFavorite = !!(userInfos.favorites || []).find(({ _id }) => _id === id)
        const hasNote = !!userInfos.notes.find(({ conteudo }) => id === conteudo._id)
        return (
          <div className={classes.root}>
            {isFavorite && (
              <img className={classes.imageFavorite} src={bookmarkIcon} alt={'favorito'} />
            )}
            {hasNote && <img className={classes.imageNote} src={penIcon} alt={'anotações'} />}
            <img className={classes.typeImage} src={contentTypeIcons[tipo]} alt={tipo} />
          </div>
        )
      }}
    </Query>
  )
}

export default withStyles(styles, { withTheme: true })(ResultItemIcon)
