import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

const RelativeWrapper = withStyles({
  root: {
    position: 'relative'
  }
})(({ classes, children, ...rest }) => (
  <div {...rest} className={classes.root}>
    {children}
  </div>
))

export default RelativeWrapper
