export const listYears = initial => {
  const currentYear = new Date().getFullYear()
  const years = []

  for (let i = initial; i <= currentYear + 1; i += 1) {
    years.push(i)
  }

  return years
}

export default listYears
