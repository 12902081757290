import React from 'react'
import classNames from 'classnames'
import * as ContentType from 'informed-shared/components/es/ContentType'
import {
  ContentVideo,
  ContentImage,
  ContentHtml,
  ContentDiretriz,
  ContentAudio
} from 'informed-shared/components/es/Content'
import ReactPlaceholder from 'react-placeholder'
import ContentPlaceholder from './Placeholder/Content'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'
import AverageRatingStars from './AverageRatingStars'
import { safeLocalStorage } from './../utils'
import TimerIcon from '@material-ui/icons/Timer'

class RenderCovidContent extends React.PureComponent {
  constructor(props) {
    super(props)
    this.contentPreviewRef = React.createRef()
    this.state = {
      initScrollTop: false,
      scrollIsRecycled: false
    }
  }

  recycleScroll = () => {
    const element = (this.contentPreviewRef || {}).current
    const { persistedContentId, contentId, recycleScrollMethod, history } = this.props
    const { initScrollTop } = this.state

    if (!history || !history.location || !history.location.state) return false

    const newLocation = {
      ...history.location,
      state: { ...history.location.state, recycle: false }
    }

    const recycleContentScroll = () => {
      this.setState({ scrollIsRecycled: true }, recycleScrollMethod)
      history.replace(newLocation)
    }

    if (!(contentId === persistedContentId)) {
      return this.setState({ scrollIsRecycled: true })
    } else {
      if (element && !initScrollTop) {
        this.setState({ initScrollTop: true }, () => {
          setTimeout(recycleContentScroll, 0)
        })
      }
    }
  }

  historyRecycle = () => {
    const { history, isStack } = this.props
    if (isStack) return
    return (((history || {}).location || {}).state || {}).recycle
  }

  setContentMinHeight = () => {
    const style = {}

    if (this.historyRecycle()) {
      style.minHeight = this.props.clientHeight
    }

    return style
  }

  render() {
    const { queryInfos, handleAuthorClick } = this.props
    const { loading, data } = queryInfos
    if (loading) {
      return (
        <ReactPlaceholder
          children={<div />}
          showLoadingAnimation={true}
          type="media"
          ready={false}
          rows={2}
          customPlaceholder={ContentPlaceholder}
        />
      )
    }
    const { covidContent = {} } = data
    const userFontSizeScale =
      !!safeLocalStorage() && Number(safeLocalStorage().getItem('font-size-scale'))
    const fontSizeScale = userFontSizeScale || 0

    if (this.historyRecycle()) {
      this.recycleScroll()
    }

    return (
      <div
        id={'ContentPreview-wrapper'}
        ref={this.contentPreviewRef}
        style={{
          '--content-base-font-size': `${0.7 + fontSizeScale / 10}rem`,
          transition: '.2s ease-in-out',
          ...this.setContentMinHeight()
        }}
        className={classNames('ContentPreview ContentPreview-responsive')}
      >
        <ContentType.Render type={covidContent.tipo} types={['aula', 'habilidades_clinicas']}>
          <ContentVideo
            content={covidContent}
            handleAuthorClick={handleAuthorClick}
            player="vimeo"
            ReadingTimeIconComponent={TimerIcon}
          />
        </ContentType.Render>

        <ContentType.Render type={covidContent.tipo} types={['webinar']}>
          <ContentVideo
            content={covidContent}
            handleAuthorClick={handleAuthorClick}
            player="youtube"
            ReadingTimeIconComponent={TimerIcon}
          />
        </ContentType.Render>

        <ContentType.Render type={covidContent.tipo} types={['podcast']}>
          {(covidContent.codigo || '').includes('soundcloud') ? (
            <ContentAudio
              content={covidContent}
              handleAuthorClick={handleAuthorClick}
              player="soundcloud"
              ReadingTimeIconComponent={TimerIcon}
            />
          ) : (
            <ContentVideo
              content={covidContent}
              handleAuthorClick={handleAuthorClick}
              player="vimeo"
              ReadingTimeIconComponent={TimerIcon}
            />
          )}
        </ContentType.Render>

        <ContentType.Render type={covidContent.tipo} types={['figura', 'tabela', 'fluxograma']}>
          <ContentImage
            content={covidContent}
            handleAuthorClick={handleAuthorClick}
            ReadingTimeIconComponent={TimerIcon}
          />
        </ContentType.Render>

        <ContentType.Render
          type={covidContent.tipo}
          types={[
            'medicamento',
            'capitulo',
            'artigo',
            'consulta_rapida_e_recomendacoes',
            'diagnostico_e_tratamento'
          ]}
        >
          <ContentHtml
            content={covidContent}
            handleAuthorClick={handleAuthorClick}
            ReadingTimeIconComponent={TimerIcon}
          />
        </ContentType.Render>

        <ContentType.Render type={covidContent.tipo} types={['diretriz']}>
          <ContentDiretriz content={covidContent} ReadingTimeIconComponent={TimerIcon} />
        </ContentType.Render>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    scrollTop: state.contentScroll.scrollTop,
    clientHeight: state.contentScroll.clientHeight,
    persistedContentId: state.contentScroll.contentId
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(RenderCovidContent)
)
