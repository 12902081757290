import React from 'react'
import SearchResultPlaceholder from './SearchResultPlaceholder'
import withStyles from '@material-ui/core/styles/withStyles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ResultItemIcon from './ResultItemIcon'
import ReactPlaceholder from 'react-placeholder'
import { contentQueries, typeGroupQueries, readingTimeQueries } from '../../graphql/queries'
import { searchActions } from '../../actions'
import Query from 'react-apollo/Query'
import ErrorHandling from '../../components/ErrorHandling'
import * as textUtils from '../../utils/text'
import Link from 'react-router-dom/Link'
import { connect } from 'react-redux'
import SearchResultListScroll from './SearchResultListScroll'
import SearchResultListPlaceholder from '../../components/Placeholder/SearchResult/List'
import LoadMorePlaceholder from '../../components/Placeholder/SearchResult/LoadMore'
import CustomTabs from '../../components/CustomTabs'
import Tab from '@material-ui/core/Tab'
import * as loggerClient from '../../services/loggerClient'
import { startAnimation } from '../../fixGestureNavigationIOS'
import { availableSpecialties, googleAnalytics } from '../../utils'
import makeFilter from '../../utils/filter'
import TimerIcon from '@material-ui/icons/Timer'
import {
  compute,
  prepareOptions,
  stringfyPositiveOptions,
  readingTimesFilterApplyed,
  resetFilterReadingTimes,
} from '../../utils/readingTimeFilter'
import OptionMenu from '../SearchResultList/OptionsMenu'
import SearchResultPlaceholderWithFilter from './SearchResultPlaceholderWithFilter'
import { resetFilter } from '../../actions/search'
import Button from '@material-ui/core/Button'
import ClearIcon from '@material-ui/icons/Clear'

const styles = (theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    paddingBottom: 15,
  },
  list: {
    paddingTop: 0,
    width: '100%',
  },
  item: {
    flex: 1,
    paddingLeft: 22,
    paddingRight: 22,
    alignItems: 'center',
    wordBreak: 'break-word',
  },
  itemTitle: {
    color: '#002D72',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginTop: 4,
    '& span': {
      display: '-webkit-box',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 2,
      '-webkit-box-orient': 'vertical',
    },
  },
  resultPlaceholderWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  itemChapter: {
    fontStyle: 'italic',
    marginTop: 4,
    '& span': {
      display: '-webkit-box',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 2,
      '-webkit-box-orient': 'vertical',
    },
  },
  itemBook: {
    fontSize: 14,
    marginTop: 4,
    '& span': {
      display: '-webkit-box',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 2,
      '-webkit-box-orient': 'vertical',
    },
  },
  itemAuthors: {
    color: '#606060',
    fontSize: 14,
    marginTop: 4,
    '& span': {
      display: '-webkit-box',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 2,
      '-webkit-box-orient': 'vertical',
    },
  },
  itemDescription: {
    fontSize: 14,
    marginTop: 4,
    '& em': {
      fontWeight: 'bold',
      fontStyle: 'normal',
    },
    '& > div': {
      display: '-webkit-box',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 3,
      '-webkit-box-orient': 'vertical',
    },
  },
  wrapperIcon: {
    boxSizing: 'border-box',
    width: 48,
    height: 48,
    flex: 'none',
    marginRight: 16,
    borderRadius: '100%',
    border: '2px solid #184496',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '40px',
    },
    background: '#184496',
  },
  errorWrapper: {
    display: 'flex',
    height: '100%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  optionsButton: {
    color: '#fff',
    fontSize: 13,
    height: 'auto',
    boxShadow: 'none',
    textTransform: 'none',
    marginLeft: 5,
    minHeight: 28,
    '&:hover, &:focus': {
      background: '#959595',
    },
  },
  actionButton: {
    display: 'flex',
    flex: 1,
    justifyContent: 'left',
    color: '#454545',
    fontSize: 13,
    height: 'auto',
    boxShadow: 'none',
    textTransform: 'none',
    padding: '10px',
    paddingRight: '35px',
    margin: '5px',
    minHeight: 28,
  },
  optionsButtonIcon: {
    fontSize: 22,
  },
  actionButtonIcon: {
    width: 25,
    height: 25,
    marginRight: 15,
  },
  title: {
    color: '#505152',
    borderTop: '1px solid #e8e8e8',
    borderBottom: '1px solid #e8e8e8',
    paddingLeft: 22,
    paddingRight: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  numberResults: {
    color: '#002D72',
    fontSize: 12,
    fontWeight: 'bold',
  },
  copyright: {
    fontSize: 14,
    color: '#2e415f',
    marginTop: 5,
    alignItems: 'center',
    display: 'flex',
  },
  copyrightIcon: {
    width: '1rem',
    verticalAlign: 'middle',
    marginLeft: 5,
    marginRight: 3,
  },
  customTab: {
    flex: 1,
  },
  optionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  clearButton: {
    marginRight: '12px',
    padding: 0,
  },
  actionsLine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 12px',
  },
  clearButton: {
    padding: '0 16px',
  },
})

class FavoriteList extends React.Component {
  constructor(props) {
    super(props)
    this.searchLoggerTimeoutKey = undefined
    this.state = {
      orderIsOpen: false,
      filterIsOpen: false,
      advancedSearchIsOpen: false,
      lastTerm: '',
      anchorEl: null,
      popperOpen: false,
    }
  }

  handleClick = (event) => {
    const currentTarget = event.currentTarget
    this.setState((state) => {
      return {
        anchorEl: currentTarget,
        popperOpen: !state.popperOpen,
      }
    })
  }

  handleClickClose = (event) => {
    const currentTarget = event.currentTarget
    this.setState((state) => {
      return {
        anchorEl: currentTarget,
        popperOpen: false,
      }
    })
  }

  closeOrder = () => {
    this.setState({
      orderIsOpen: false,
      anchorEl: null,
      popperOpen: false,
    })
  }

  closeFilter = () => {
    this.setState({
      filterIsOpen: false,
      anchorEl: null,
      popperOpen: false,
    })
  }

  closeAdvancedSearch = () => {
    this.setState({
      advancedSearchIsOpen: false,
      anchorEl: null,
      popperOpen: false,
    })
  }

  openOrder = () => {
    this.setState({
      orderIsOpen: true,
    })
  }

  openFilter = () => {
    this.setState({
      filterIsOpen: true,
    })
  }

  openAdvancesSearch = () => {
    this.setState({
      advancedSearchIsOpen: true,
    })
  }

  closeDropdown = () => {
    if (this.state.popperOpen) {
      this.setState({ popperOpen: false })
    }
  }

  handleTitle = (tipo, titulo, principioAtivo, codigo) => {
    if (tipo === 'cid10' || tipo === 'cid11') {
      return textUtils.truncateEllipsis(codigo || '', 120, true)
    } else {
      return textUtils.truncateEllipsis(titulo || principioAtivo || '', 120, true)
    }
  }

  handleCopyright = (tipo, copyright, _titulo, minutosLeitura) => {
    const { classes } = this.props

    if (minutosLeitura == null) {
      minutosLeitura = 1
    }

    if (tipo === 'podcast') {
      return (
        <div className={classes.copyright}>
          Realização: {copyright} {<TimerIcon className={classes.copyrightIcon} />} {minutosLeitura}{' '}
          min de duração
        </div>
      )
    } else if (tipo === 'aula' || tipo === 'habilidades_clinicas' || tipo === 'webinar') {
      return (
        <div className={classes.copyright}>
          Publicação: {copyright} {<TimerIcon className={classes.copyrightIcon} />} {minutosLeitura}{' '}
          min de duração
        </div>
      )
    } else {
      return (
        <div className={classes.copyright}>
          Publicação: {copyright} {<TimerIcon className={classes.copyrightIcon} />} {minutosLeitura}{' '}
          min de leitura
        </div>
      )
    }
  }

  resetFilterAllChecked = (filter, filterReadingTimes) => {
    const newOptions = resetFilterReadingTimes(filterReadingTimes)
    this.props.changeReadingTime(newOptions)
    this.props.resetFilter(filter.getPlainAllFiltersChecked())
  }

  renderSearchResultTitle = (data) => {
    const {
      classes,
      searchFilter,
      searchRange,
      changeFilter,
      changeReadingTime,
      searchOrder,
      searchSpecialty,
      changeSpecialty,
      filterLenght,
      readingTimesStoredOptions,
    } = this.props

    return (
      <Query query={readingTimeQueries.GET_READING_TIMES}>
        {({ loading, error, data: { readingTime = [] } = {} }) => {
          if (loading || error) {
            return null
          }

          const readingTimesOptions = prepareOptions(readingTime)

          const filterApplyed = readingTimesFilterApplyed(readingTimesStoredOptions)

          const readingTimes = compute({
            options: readingTimesOptions,
            storedOptions: readingTimesStoredOptions,
          })

          return (
            <Query query={typeGroupQueries.SEARCH_GROUP_TYPES}>
              {({ loading, error, data }) => {
                if (loading || error) {
                  return null
                }

                const filterOptions = ((data || {}).typeGroup || [])
                  .sort(function (a, b) {
                    return a.ordem > b.ordem ? 1 : b.ordem > a.ordem ? -1 : 0
                  })
                  .map(({ key, nome, tipos }) => ({
                    key,
                    label: nome,
                    value: tipos.join(','),
                  }))

                const filter = makeFilter(filterOptions)

                const filterOrOrderApplied = () => {
                  return filter.filterOrOrderApplied({ searchFilter, searchRange })
                }

                return (
                  <div>
                    <div className={classes.actionsLine}>
                      <span className={classes.numberResults}>{filterLenght || 0} resultados</span>
                      <div style={{ display: 'flex' }}>
                        {filterOrOrderApplied() || filterApplyed ? (
                          <Button
                            aria-label="Filtrar"
                            className={classes.clearButton}
                            color={'secondary'}
                            onClick={() => this.resetFilterAllChecked(filter, readingTimesOptions)}
                          >
                            <ClearIcon className={classes.actionButtonIcon} />
                            Limpar filtro
                          </Button>
                        ) : null}
                        <OptionMenu
                          filterOrOrderApplied={filterOrOrderApplied()}
                          readingTimesFilterApplyed={filterApplyed}
                          filter={filter}
                          searchOrder={searchOrder}
                          changeFilter={changeFilter}
                          searchRange={searchRange}
                          searchFilter={searchFilter}
                          resetFilter={resetFilter}
                          filterOptions={filterOptions}
                          readingTimes={readingTimes}
                          changeReadingTime={changeReadingTime}
                          handleClick={this.handleClick}
                          orderIsOpen={this.state.orderIsOpen}
                          filterIsOpen={this.state.filterIsOpen}
                          advancedSearchIsOpen={this.state.advancedSearchIsOpen}
                          anchorEl={this.state.anchorEl}
                          popperOpen={this.state.popperOpen}
                          closeOrder={this.closeOrder}
                          closeFilter={this.closeFilter}
                          closeAdvancedSearch={this.closeAdvancedSearch}
                          openOrder={this.openOrder}
                          openFilter={this.openFilter}
                          openAdvancesSearch={this.openAdvancesSearch}
                        />
                      </div>
                    </div>
                    <CustomTabs
                      value={searchSpecialty || 0}
                      onChange={(event, newValue) => changeSpecialty(newValue)}
                      aria-label={'Especialidades'}
                      indicatorColor="primary"
                      textColor="primary"
                      centered
                    >
                      {availableSpecialties.map((specialty) => (
                        <Tab className={classes.customTab} label={specialty} />
                      ))}
                    </CustomTabs>
                  </div>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }

  render() {
    const {
      classes,
      searchTerm,
      searchOrder,
      searchFilter,
      searchRange,
      searchSpecialty,
      readingTimesStoredOptions,
      resetFilter,
    } = this.props

    const { lastTerm = '', lastFilter = '' } = this.state
    return (
      <Query query={typeGroupQueries.SEARCH_GROUP_TYPES}>
        {({ loading, error, data }) => {
          if (loading || error) {
            return null
          }

          const filterOptions = ((data || {}).typeGroup || [])
            .sort(function (a, b) {
              return a.ordem > b.ordem ? 1 : b.ordem > a.ordem ? -1 : 0
            })
            .map(({ key, nome, tipos }) => ({
              key,
              label: nome,
              value: tipos.join(','),
            }))

          const filter = makeFilter(filterOptions)

          const filterOrOrderApplied = () => {
            return filter.filterOrOrderApplied({ searchFilter, searchRange })
          }
          return (
            <Query
              query={contentQueries.SEARCH_CONTENT}
              variables={{
                term: searchTerm,
                offset: 0,
                order: searchOrder,
                filter: searchFilter,
                range: searchRange,
                specialty: availableSpecialties[searchSpecialty],
                readingTimes: stringfyPositiveOptions(readingTimesStoredOptions),
              }}
              onCompleted={(data = {}) => {
                const { contents = {} } = data || {}
                const { total = 0 } = contents || {}
                this.props.changeFilterLenght(total)
                const termo = (searchTerm || '').trim()
                const filtro = (searchFilter || '').trim()
                if ((lastTerm || '').trim() !== termo || (lastFilter || '').trim() != filtro) {
                  this.setState({ lastTerm: searchTerm, lastFilter: searchFilter }, () => {
                    this.searchLoggerTimeoutKey && clearTimeout(this.searchLoggerTimeoutKey)
                    this.searchLoggerTimeoutKey = setTimeout(() => {
                      if (termo.length >= 2) {
                        loggerClient.onSearch({
                          correspondencias: total,
                          termo,
                          filtro: searchFilter.split(','),
                          filtroLeitura: stringfyPositiveOptions(readingTimesStoredOptions).split(
                            ','
                          ),
                        })
                      }
                    }, 3000)
                  })
                }
              }}
            >
              {({ loading, error, data, refetch, networkStatus, fetchMore }) => {
                if (loading) {
                  return (
                    <ReactPlaceholder
                      children={<div />}
                      showLoadingAnimation={true}
                      type="media"
                      ready={false}
                      rows={2}
                      customPlaceholder={SearchResultListPlaceholder}
                    />
                  )
                }

                if (error)
                  return (
                    <ErrorHandling
                      error={error}
                      refetch={() => refetch()}
                      networkStatus={networkStatus}
                    />
                  )

                if (!((data.contents || {}).items || []).length) {
                  if (filterOrOrderApplied()) {
                    return (
                      <div className={classes.resultPlaceholderWrapper}>
                        {this.renderSearchResultTitle(data)}
                        <SearchResultPlaceholderWithFilter
                          resetFilter={resetFilter}
                          filter={filter}
                        />
                      </div>
                    )
                  } else {
                    return (
                      <div className={classes.resultPlaceholderWrapper}>
                        {this.renderSearchResultTitle(data)}
                        <SearchResultPlaceholder />
                      </div>
                    )
                  }
                }

                // AQUI VAI O LOG
                googleAnalytics(
                  'acao_busca',
                  'home',
                  'Se digita algo. A contabilização pode ser feito ao digitar ou ao vir o resultado',
                  'Digitação'
                )

                return (
                  <SearchResultListScroll
                    loadMore={() => {
                      fetchMore({
                        variables: {
                          offset: data.contents.items.length,
                        },
                        updateQuery: (prev, { fetchMoreResult }) => {
                          if (!fetchMoreResult) return prev
                          return {
                            ...prev,
                            contents: {
                              ...prev.contents,
                              items: [...prev.contents.items, ...fetchMoreResult.contents.items],
                            },
                          }
                        },
                      })
                    }}
                    useWindow={false}
                    hasMore={data.contents.items.length < data.contents.total}
                    loader={
                      <div key={'placeholder-loading'}>
                        <ReactPlaceholder
                          children={<div />}
                          showLoadingAnimation={true}
                          type="media"
                          ready={false}
                          rows={2}
                          customPlaceholder={LoadMorePlaceholder}
                        />
                      </div>
                    }
                  >
                    {this.renderSearchResultTitle(data)}
                    <div className={classes.root}>
                      <List className={classes.list}>
                        {data.contents.items.map(
                          (
                            {
                              titulo,
                              principioAtivo,
                              tipo,
                              id,
                              autores,
                              tituloCapitulo,
                              tituloLivro,
                              descricao,
                              highlight,
                              score,
                              autoresDiretriz,
                              copyright,
                              deletedAt,
                              codigo,
                              minutosLeitura = 1,
                              minutosLeituraClassificacaoTitulo,
                            },
                            index
                          ) => {
                            return (
                              <div key={id}>
                                <ListItem
                                  button
                                  className={classes.item}
                                  component={Link}
                                  to={{
                                    pathname: `/conteudo/${id}`,
                                    state: {
                                      from: 'busca',
                                      termo: searchTerm,
                                      posicao: index + 1,
                                      correspondencias: data.contents.total,
                                      score,
                                      contexto: 'conteudo',
                                      filtro: searchFilter.split(','),
                                      filtroLeitura: stringfyPositiveOptions(
                                        readingTimesStoredOptions
                                      ).split(','),
                                    },
                                  }}
                                  onClick={() => {
                                    startAnimation()
                                    loggerClient.onClickItem({
                                      termo: searchTerm,
                                      correspondencias: data.contents.total,
                                      posicao: index + 1,
                                      conteudo: `${id}`,
                                      tipo,
                                      score,
                                      origem: 'busca',
                                      contexto: 'conteudo',
                                      filtro: searchFilter.split(','),
                                      filtroLeitura: stringfyPositiveOptions(
                                        readingTimesStoredOptions
                                      ).split(','),
                                    })
                                    googleAnalytics(
                                      'acao_busca_item',
                                      'home',
                                      'Se clica no item da lista obtida no resultado da busca',
                                      'Clique'
                                    )
                                  }}
                                >
                                  <ResultItemIcon tipo={tipo} id={id} />
                                  <div>
                                    <div className={classes.itemTitle}>
                                      <span>
                                        {this.handleTitle(tipo, titulo, principioAtivo, codigo)}
                                      </span>
                                    </div>
                                    {tituloCapitulo && (
                                      <div className={classes.itemChapter}>
                                        <span>
                                          {textUtils.truncateEllipsis(tituloCapitulo, 120, true)}
                                        </span>
                                      </div>
                                    )}

                                    {tituloLivro && (
                                      <div className={classes.itemBook}>
                                        <span>
                                          Livro:{' '}
                                          {textUtils.truncateEllipsis(tituloLivro, 120, true)}
                                        </span>
                                      </div>
                                    )}
                                    <div className={classes.itemAuthors}>
                                      <span>
                                        {textUtils.truncateEllipsis(
                                          autoresDiretriz || autores,
                                          120,
                                          true
                                        )}
                                      </span>
                                    </div>
                                    <div className={classes.itemDescription}>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: textUtils.truncateEllipsis(
                                            highlight || descricao || '',
                                            160,
                                            true
                                          ),
                                        }}
                                      />
                                    </div>
                                    {this.handleCopyright(
                                      tipo,
                                      copyright,
                                      titulo,
                                      minutosLeitura,
                                      minutosLeituraClassificacaoTitulo
                                    )}
                                  </div>
                                </ListItem>
                                <Divider />
                              </div>
                            )
                          }
                        )}
                      </List>
                    </div>
                  </SearchResultListScroll>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    searchOrder: state.search.order,
    searchFilter: state.search.filter.filter,
    readingTimesStoredOptions: state.search.readingTime || [],
    searchRange: state.search.filter.range,
    searchSpecialty: state.search.filter.specialty,
    filterLenght: state.search.filterLenght,
    closePopper: state.search.closePopper,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeFilter: (filter) => {
      dispatch(searchActions.changeFilter(filter))
    },
    resetFilter: (filter) => {
      dispatch(searchActions.resetFilter(filter))
    },
    changeSpecialty: (specialty) => {
      dispatch(searchActions.changeSpecialty(specialty))
    },
    changeFilterLenght: (filterLenght) => {
      dispatch(searchActions.changeFilterLenght(filterLenght))
    },
    changeReadingTime: (readingTime) => {
      dispatch(searchActions.changeReadingTime(readingTime))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(withStyles(styles, { withTheme: true })(FavoriteList))
