import React from 'react'
import BaseQuote from './BaseQuote'

const ABNT = React.memo(props => {
  const { contentId, onConfirm } = props

  const label = 'ABNT'
  const successMessage = 'Citação padrão ABNT copiada com sucesso'

  return (
    <BaseQuote
      label={label}
      content={contentId}
      type={label.toLowerCase()}
      successMessage={successMessage}
      onConfirm={onConfirm}
    />
  )
})

export default ABNT
