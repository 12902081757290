import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import penIcon from '../../icons/svg/pen.svg'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    minHeight: '100%',
    flex: 1,
    padding: 8
  },
  placeholderTitle: {
    color: '#5f7aa4',
    fontSize: 20,
    fontWeight: 400,
    maxWidth: '90%'
  },
  placeholderText: {
    fontSize: 16,
    maxWidth: 250,
    color: '#002D72',
    fontWeight: 'bold'
  },
  placeholderImage: {
    maxWidth: '100%',
    marginBottom: 36,
    marginTop: 20,
    width: 80,
    display: 'block',
    '& .cls-1': {
      fill: '#5f7aa4'
    },

    '& .cls-2, & .cls-3': {
      stroke: 'none'
    },

    '& .cls-3': {
      fill: '#f2f2f2'
    }
  }
})

const FavoritePlaceholder = ({ classes }) => {
  return (
    <div className={classes.root}>
      <img src={penIcon} alt={'Anotações'} className={classes.placeholderImage} />
      <Typography variant={'title'} gutterBottom className={classes.placeholderTitle}>
        Sua página de anotações está vazia
      </Typography>
      <Typography className={classes.placeholderText}>
        Você pode fazer anotações ao longo de sua leitura, e elas ficarão reunidas nesta seção
      </Typography>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(FavoritePlaceholder)
