export const CHANGE_TERM = 'CHANGE_TERM'
export const CHANGE_ORDER = 'CHANGE_ORDER'
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const RESET_FILTER = 'RESET_FILTER'
export const CHANGE_SPECIALTY = 'CHANGE_SPECIALTY'
export const FILTER_LENGHT = 'FILTER_LENGHT'
export const CHANGE_READING_TIME = 'CHANGE_READING_TIME'
export const CHANGE_CLOSE_POPPER = 'CHANGE_CLOSE_POPPER'

export const changeTerm = (term) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_TERM, term })
  }
}

export const changeOrder = (order) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_ORDER, order })
  }
}

export const changeFilter = (filter) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_FILTER, filter })
  }
}

export const resetFilter = (filter) => {
  return (dispatch) => {
    dispatch({ type: RESET_FILTER, filter })
  }
}

export const changeSpecialty = (specialty) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_SPECIALTY, specialty })
  }
}

export const changeFilterLenght = (filterLenght) => {
  return (dispatch) => {
    dispatch({ type: FILTER_LENGHT, filterLenght })
  }
}

export const changeReadingTime = (readingTime) => (dispatch) =>
  dispatch({ type: CHANGE_READING_TIME, readingTime })

export const changeClosePopper = (popper) => (dispatch) => {
  dispatch({ type: CHANGE_CLOSE_POPPER, popper })
}
