import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Query from 'react-apollo/Query'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import IconClose from '../icons/IconClose'
import { ABNT, Vancouver } from './Quotes'
import { contentQueries } from '../graphql/queries'
import * as ContentType from 'informed-shared/components/es/ContentType'

const styles = (theme) => ({
  paper: {
    overflow: 'inherit',
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    '& > div': {
      overflow: 'inherit',
    },
  },
  progress: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto',
  },
  content: {
    minHeight: 60,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    padding: 0,
    textAlign: 'left',
    color: '#003884',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    wordBreak: 'break-word',
  },
  closeButton: {
    position: 'absolute',
    top: -20,
    right: -20,
    '&, &:hover, &:focus': {
      background: '#229FFF',
      color: '#fff',
      width: 40,
      height: 40,
      marginLeft: 'auto',
    },
  },
  subHeaderInfo: {
    marginTop: 10,
    marginBottom: 10,
    fontWeight: 500,
  },
  isbn: {
    fontWeight: 500,
    marginTop: 10,
    marginBottom: 10,
  },
  progressWrapper: {
    padding: 54,
  },
  detailBlock: {
    marginTop: 10,
    marginBottom: 10,
  },
  autoresList: {
    paddingLeft: 0,
    margin: '5px 0 0 0',
    display: 'flex',
    flexDirection: 'column',
    '& > li': {
      marginTop: '.3em',
      listStyle: 'none',
      display: 'inline-flex',
      alignItems: 'flex-start',
      '&::before': {
        content: '"\\2022"',
        marginRight: '.2em',
        fontSize: '1.6em',
        lineHeight: '0.8em',
      },
    },
  },
  infoBox: {
    marginTop: 20,
    marginBottom: 10,
    paddingTop: 10,
    borderTop: '1px solid #ddd',
  },
  tituloSecao: {
    marginTop: 10,
    marginBottom: 10,
  },
  tituloLivro: {
    marginTop: 10,
    marginBottom: 10,
  },
  editores: {
    marginTop: 10,
  },
})

class ContentInfoDialog extends React.Component {
  handleCancel = () => {
    this.props.onClose()
  }
  renderDetail = (detail, contentType, contentId) => {
    const { classes } = this.props
    return (
      <div>
        <ContentType.Render type={contentType} types={['aula', 'habilidades_clinicas']}>
          {!!detail.tituloCurso && <div className={classes.header}>{detail.tituloCurso}</div>}
          {!!(detail.edicaoCurso && detail.copyrightCurso) && (
            <div className={classes.subHeaderInfo}>
              Edição<span> {Number(detail.edicaoCurso) || ''}</span> -{' '}
              <span>{detail.copyrightCurso}</span>
            </div>
          )}
          {!!(detail.autoresCurso || []).length && (
            <div className={classes.autores}>
              <strong>Autores:</strong>
              <ul className={classes.autoresList}>
                {detail.autoresCurso.map((autor) => {
                  return <li>{autor.nome}</li>
                })}
              </ul>
            </div>
          )}
          {!!(detail.tituloSecao || detail.tituloModulo) && (
            <div className={classes.infoBox}>
              {!!detail.tituloSecao && (
                <div className={classes.tituloSecao}>
                  <strong>Título da seção: </strong>
                  {detail.tituloSecao}
                </div>
              )}
              {!!detail.tituloModulo && (
                <div className={classes.tituloLivro}>
                  <strong>Título do módulo: </strong>
                  {detail.tituloModulo}
                </div>
              )}
            </div>
          )}
        </ContentType.Render>
        <ContentType.Render type={contentType} types={['webinar']}>
          {!!detail.tituloCurso && <div className={classes.header}>{detail.tituloCurso}</div>}
          {!!detail.copyrightCurso && (
            <div className={classes.subHeaderInfo}>
              Ano de realização: <span>{detail.copyrightCurso}</span>
            </div>
          )}

          {!!(detail.autoresCurso || []).length && (
            <div className={classes.autores}>
              <strong>Coordenadores do curso:</strong>
              <ul className={classes.autoresList}>
                {detail.autoresCurso.map((autor) => {
                  return <li>{autor.nome}</li>
                })}
              </ul>
            </div>
          )}
        </ContentType.Render>
        <ContentType.Render
          type={contentType}
          types={['figura', 'tabela', 'fluxograma', 'medicamento', 'capitulo']}
        >
          {!!detail.tituloLivro && <div className={classes.header}>{detail.tituloLivro}</div>}
          {!!(detail.edicaoLivro && detail.copyrightLivro) && (
            <div className={classes.subHeaderInfo}>
              Edição<span> {Number(detail.edicaoLivro)}</span> -{' '}
              <span>{detail.copyrightLivro}</span>
            </div>
          )}
          {!!detail.isbn && <div className={classes.isbn}>ISBN: {detail.isbn}</div>}
          {!!(detail.autoresLivro || []).length && (
            <div className={classes.autores}>
              <strong>Editores/Autores:</strong>
              <ul className={classes.autoresList}>
                {detail.autoresLivro.map((autor) => {
                  return <li>{autor.nome}</li>
                })}
              </ul>
            </div>
          )}
          {!!(
            ((detail.autores || []).length &&
              ['figura', 'tabela', 'fluxograma'].includes(contentType)) ||
            detail.tituloSecao ||
            detail.tituloCapitulo
          ) && (
            <div className={classes.infoBox}>
              {!!detail.tituloSecao && (
                <div className={classes.tituloSecao}>
                  <strong>Título da seção: </strong>
                  {detail.tituloSecao}
                </div>
              )}
              {!!detail.tituloCapitulo && (
                <div className={classes.tituloLivro}>
                  <strong>Título do capítulo: </strong>
                  {detail.tituloCapitulo}
                </div>
              )}

              <ContentType.Render type={contentType} types={['figura', 'tabela', 'fluxograma']}>
                {!!(detail.autores || []).length && (
                  <div className={classes.autores}>
                    <strong>Autores do capítulo:</strong>
                    <ul className={classes.autoresList}>
                      {detail.autores.map((autor) => {
                        return <li>{autor.nome}</li>
                      })}
                    </ul>
                  </div>
                )}
              </ContentType.Render>
            </div>
          )}
        </ContentType.Render>
        <ContentType.Render
          type={contentType}
          types={['artigo', 'consulta_rapida_e_recomendacoes']}
        >
          {!!detail.anoPublicacao && (
            <div className={classes.subHeaderInfo}>
              Publicação: <span>{detail.anoPublicacao}</span>
            </div>
          )}

          {!!detail.dataAtualizacao && (
            <div className={classes.subHeaderInfo}>
              Atualização: <span>{detail.dataAtualizacao}</span>
            </div>
          )}
          <div className={classes.infoBox} />
          {!!detail.isbn && <div className={classes.isbn}>ISBN: {detail.isbn}</div>}
          {!!detail.issn && <div className={classes.isbn}>ISSN: {detail.issn}</div>}
        </ContentType.Render>
        <ContentType.Render type={contentType} types={['diagnostico_e_tratamento']}>
          {!!detail.dataAtualizacao && (
            <div className={classes.subHeaderInfo}>
              Atualização: <span>{detail.dataAtualizacao}</span>
            </div>
          )}
          {!!(detail.autores || []).length && (
            <div className={classes.autores}>
              <strong>Autores:</strong>
              <ul className={classes.autoresList}>
                {detail.autores.map((autor) => {
                  return <li>{autor.nome}</li>
                })}
              </ul>
            </div>
          )}
          {!!(detail.editores || []).length && (
            <div className={classes.editores}>
              <strong>Editores:</strong>
              <ul className={classes.autoresList}>
                {detail.editores.map((editor) => {
                  return <li>{editor.nome}</li>
                })}
              </ul>
            </div>
          )}
          <div className={classes.infoBox} />
          {!!detail.isbn && <div className={classes.isbn}>ISBN: {detail.isbn}</div>}
          {!!detail.issn && <div className={classes.isbn}>ISSN: {detail.issn}</div>}
        </ContentType.Render>
        <ContentType.Render type={contentType} types={['diretriz']}>
          {!!detail.dataPublicacao && (
            <div className={classes.subHeaderInfo}>
              Publicação: <span>{detail.dataPublicacao}</span>
            </div>
          )}

          {!!detail.instituicao && (
            <div className={classes.subHeaderInfo}>
              Instituição: <span>{detail.instituicao}</span>
            </div>
          )}
        </ContentType.Render>
        <ContentType.Render type={contentType} types={['podcast']}>
          {!!detail.tituloModulo && <div className={classes.header}>{detail.tituloModulo}</div>}
          {!!detail.copyrightModulo && (
            <div className={classes.subHeaderInfo}>
              Ano de realização: <span>{detail.copyrightModulo}</span>
            </div>
          )}
          {!!(detail.tituloSecao || detail.tituloModulo) && (
            <div className={classes.infoBox}>
              {!!detail.tituloSecao && (
                <div className={classes.tituloSecao}>
                  <strong>Título da seção: </strong>
                  {detail.tituloSecao}
                </div>
              )}
              {!!(detail.autoresModulo || []).length && (
                <div className={classes.autores}>
                  <strong>Coordenadores do programa:</strong>
                  <ul className={classes.autoresList}>
                    {detail.autoresModulo.map((autor) => {
                      return <li>{autor.nome}</li>
                    })}
                  </ul>
                </div>
              )}
            </div>
          )}
        </ContentType.Render>
        {/* <div className={classes.infoBox}>
          <div className={classes.tituloSecao}>
            <strong>Citações: </strong>
          </div>
          <ABNT contentId={contentId} onConfirm={this.handleCancel} />
          <Vancouver contentId={contentId} onConfirm={this.handleCancel} />
        </div> */}
      </div>
    )
  }

  renderSpinner = () => {
    const { classes } = this.props
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress className={classes.progress} size={30} />
      </div>
    )
  }

  render() {
    const { value, searchOrder, classes, content, ...other } = this.props

    return (
      <Dialog
        maxWidth="xs"
        fullWidth
        aria-labelledby="confirmation-dialog-title"
        className={classes.root}
        {...other}
      >
        <IconButton className={classes.closeButton} onClick={this.handleCancel} aria-label="Sair">
          <IconClose style={{ fontSize: 20 }} viewBox={'0 0 20 20'} />
        </IconButton>
        <Query
          query={contentQueries.GET_DETAILS}
          fetchPolicy={'cache-and-network'}
          variables={{ content: this.props.contentId }}
        >
          {({ loading, error, data = {} }) => {
            const { detail = {} } = data
            return (
              <React.Fragment>
                <DialogTitle>Informações</DialogTitle>
                <DialogContent className={classes.content}>
                  {loading
                    ? this.renderSpinner()
                    : this.renderDetail(detail, content.tipo, this.props.contentId)}
                </DialogContent>
              </React.Fragment>
            )
          }}
        </Query>
      </Dialog>
    )
  }
}

export default withStyles(styles)(ContentInfoDialog)
