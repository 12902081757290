import gql from 'graphql-tag'

export const SEARCH_GROUP_TYPES = gql`
  query typeGroup {
    typeGroup {
      nome
      key
      ordem
      tipos
    }
  }
`
