import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import HospitalIcon from '@material-ui/icons/LocalHospital'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    minHeight: '100%',
    flex: 1,
    paddingTop: 20,
    paddingBottom: 20
  },
  icon: {
    fontSize: 80,
    color: theme.palette.primary.main
  },
  placeholderTitle: {
    color: '#5f7aa4',
    fontWeight: 400,
    maxWidth: '90%'
  },
  placeholderText: {
    fontSize: 16,
    maxWidth: 250,
    color: '#002D72',
    fontWeight: 'bold'
  },
  placeholderImage: {
    maxWidth: '100%',
    marginBottom: 36,
    width: 80,
    '& .cls-1': {
      opacity: 0.398
    },
    '& .cls-2': {
      fill: '#5f7aa4'
    },
    '& .cls-3': {
      fill: '#f2f2f2'
    },
    '& .cls-4, & .cls-5': {
      stroke: 'none'
    },
    '& .cls-5': {
      fill: '#002d72'
    }
  }
})

const FavoritePlaceholder = ({ classes }) => {
  return (
    <div className={classes.root}>
      <HospitalIcon className={classes.icon} />
      <Typography variant={'title'} gutterBottom className={classes.placeholderTitle}>
        Busca de CID no InforMED.
      </Typography>
      <Typography className={classes.placeholderText}>
        Você pode pesquisar por código ou descrição da doença.
      </Typography>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(FavoritePlaceholder)
