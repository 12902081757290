import React, { useRef, useEffect } from 'react'
import classNames from 'classnames'
import * as ContentType from 'informed-shared/components/es/ContentType'
import {
  ContentVideo,
  ContentImage,
  ContentHtml,
  ContentDiretriz,
  ContentAudio,
} from 'informed-shared/components/es/Content'
import ReactPlaceholder from 'react-placeholder'
import ContentPlaceholder from './Placeholder/Content'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'
import Feedback from './Feedback'
import AverageRatingStars from './AverageRatingStars'
import { safeLocalStorage, googleAnalytics } from './../utils'
import TimerIcon from '@material-ui/icons/Timer'

class RenderContent extends React.PureComponent {
  constructor(props) {
    super(props)
    this.contentPreviewRef = React.createRef()
    this.state = {
      initScrollTop: false,
      scrollIsRecycled: false,
      isIntersecting: false,
    }
  }

  recycleScroll = () => {
    const element = (this.contentPreviewRef || {}).current
    const { persistedContentId, contentId, recycleScrollMethod, history } = this.props
    const { initScrollTop } = this.state

    if (!history || !history.location || !history.location.state) return false

    const newLocation = {
      ...history.location,
      state: { ...history.location.state, recycle: false },
    }

    const recycleContentScroll = () => {
      this.setState({ scrollIsRecycled: true }, recycleScrollMethod)
      history.replace(newLocation)
    }

    if (!(contentId === persistedContentId)) {
      return this.setState({ scrollIsRecycled: true })
    } else {
      if (element && !initScrollTop) {
        this.setState({ initScrollTop: true }, () => {
          setTimeout(recycleContentScroll, 0)
        })
      }
    }
  }

  historyRecycle = () => {
    const { history, isStack } = this.props
    if (isStack) return
    return (((history || {}).location || {}).state || {}).recycle
  }

  setContentMinHeight = () => {
    const style = {}

    if (this.historyRecycle()) {
      style.minHeight = this.props.clientHeight
    }

    return style
  }

  useOnScreen(ref) {
    const observer = new IntersectionObserver(([entry]) =>
      this.setState({ isIntersecting: entry.isIntersecting })
    )

    useEffect(() => {
      observer.observe(ref.current)
      // Remove the observer as soon as the component is unmounted
      return () => {
        observer.disconnect()
      }
    }, [])

    return this.state.isIntersecting
  }

  viewedComplete = () => {
    const ref = useRef()
    const isVisible = this.useOnScreen(ref)

    if (isVisible) {
      googleAnalytics(
        'acao_ctdo_texto_scroll',
        'integra do conteudo',
        'Se o usuário vai até o fim do conteúdo',
        'Comportamento'
      )
    }

    return <span ref={ref}>{isVisible && ` `}</span>
  }

  render() {
    const { queryInfos, handleAuthorClick } = this.props
    const { loading, data } = queryInfos
    if (loading) {
      return (
        <ReactPlaceholder
          children={<div />}
          showLoadingAnimation={true}
          type="media"
          ready={false}
          rows={2}
          customPlaceholder={ContentPlaceholder}
        />
      )
    }
    const { content = {} } = data
    const userFontSizeScale =
      !!safeLocalStorage() && Number(safeLocalStorage().getItem('font-size-scale'))
    const fontSizeScale = userFontSizeScale || 0

    if (this.historyRecycle()) {
      this.recycleScroll()
    }

    return (
      <div
        id={'ContentPreview-wrapper'}
        ref={this.contentPreviewRef}
        style={{
          '--content-base-font-size': `${0.7 + fontSizeScale / 10}rem`,
          transition: '.2s ease-in-out',
          ...this.setContentMinHeight(),
        }}
        className={classNames('ContentPreview ContentPreview-responsive')}
      >
        <ContentType.Render type={content.tipo} types={['aula', 'habilidades_clinicas']}>
          <ContentVideo
            content={content}
            handleAuthorClick={handleAuthorClick}
            player="vimeo"
            ReadingTimeIconComponent={TimerIcon}
          />
        </ContentType.Render>

        <ContentType.Render type={content.tipo} types={['webinar']}>
          <ContentVideo
            content={content}
            handleAuthorClick={handleAuthorClick}
            player="youtube"
            ReadingTimeIconComponent={TimerIcon}
          />
        </ContentType.Render>

        <ContentType.Render type={content.tipo} types={['podcast']}>
          {(content.codigo || '').includes('soundcloud') ? (
            <ContentAudio
              content={content}
              handleAuthorClick={handleAuthorClick}
              player="soundcloud"
              ReadingTimeIconComponent={TimerIcon}
            />
          ) : (
            <ContentVideo
              content={content}
              handleAuthorClick={handleAuthorClick}
              player="vimeo"
              ReadingTimeIconComponent={TimerIcon}
            />
          )}
        </ContentType.Render>

        <ContentType.Render type={content.tipo} types={['figura', 'tabela', 'fluxograma']}>
          <ContentImage
            content={content}
            handleAuthorClick={handleAuthorClick}
            ReadingTimeIconComponent={TimerIcon}
          />
        </ContentType.Render>

        <ContentType.Render
          type={content.tipo}
          types={[
            'medicamento',
            'capitulo',
            'artigo',
            'consulta_rapida_e_recomendacoes',
            'diagnostico_e_tratamento',
          ]}
        >
          <ContentHtml
            content={content}
            handleAuthorClick={handleAuthorClick}
            ReadingTimeIconComponent={TimerIcon}
          />
        </ContentType.Render>

        <ContentType.Render type={content.tipo} types={['diretriz']}>
          <ContentDiretriz content={content} ReadingTimeIconComponent={TimerIcon} />
        </ContentType.Render>

        <ContentType.Render type={content.tipo} types={['cid10', 'cid11']}>
          <div style={{ marginTop: '32px', marginBottom: '32px' }}>
            <h1>{content.codigo}</h1>
            <p>{content.titulo}</p>
          </div>
        </ContentType.Render>
        <this.viewedComplete />
        {content.avaliacaoMedia && <AverageRatingStars average={content.avaliacaoMedia} />}

        {content.tipo !== 'cid10' && content.tipo !== 'cid11' && (
          <Feedback contentId={this.props.match.params.id} />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    scrollTop: state.contentScroll.scrollTop,
    clientHeight: state.contentScroll.clientHeight,
    persistedContentId: state.contentScroll.contentId,
  }
}

export default withRouter(connect(mapStateToProps, null)(RenderContent))
