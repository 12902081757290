import createMuiTheme from '@material-ui/core/styles/createMuiTheme'

export const theme = createMuiTheme({
  overrides: {
    MuiDrawer: {
      paperAnchorRight: {
        height: '100%'
      }
    }
  },
  palette: {
    primary1Color: '#002d72',
    primary2Color: '#002d72',
    secondary1Color: '#229FFF',
    secondary2Color: '#229FFF',
    primary: {
      light: '#002d72',
      main: '#002d72',
      dark: '#002d72',
      contrastText: '#fff'
    },
    secondary: {
      light: 'rgb(34, 159, 255)',
      main: 'rgb(34, 159, 255)',
      dark: 'rgb(34, 159, 255)',
      contrastText: '#fff'
    },
    pickerHeaderColor: '#002d72'
  }
})

export default theme
