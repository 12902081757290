import gql from 'graphql-tag'

export const GET_READING_TIMES = gql`
  query readingTime {
    readingTime {
      nome
      chave
      tempo
    }
  }
`
