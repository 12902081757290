import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Snackbar from '@material-ui/core/Snackbar'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import { userMutations } from '../graphql/mutations'
import OnlyOnline from './OnlyOnline'
import { validators, formatContentSelectValue, filterOptionsFunction } from '../utils'
import CircularProgress from '@material-ui/core/CircularProgress'
import Slide from '@material-ui/core/Slide'
import Mutation from 'react-apollo/Mutation'
import { userQueries, specialtiesQueries } from '../graphql/queries'
import FormHelperText from '@material-ui/core/FormHelperText'
import IconButton from '@material-ui/core/IconButton'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from 'react-select'
import Query from 'react-apollo/Query'

const styles = theme => ({
  root: {
    '& > div': {
      overflow: 'inherit'
    }
  },
  progress: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto'
  },
  content: {
    minHeight: 60,
    position: 'relative'
  },
  toolBar: {
    padding: '0 8px'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  toolBarRight: {
    marginLeft: 'auto',
    margin: '0 8px'
  },
  closeButton: {
    position: 'absolute',
    top: -20,
    right: -20,
    '&, &:hover, &:focus': {
      background: '#229FFF',
      color: '#fff',
      width: 40,
      height: 40,
      marginLeft: 'auto'
    }
  },
  form: {
    marginTop: 8
  },
  formControl: {
    marginTop: 8,
    marginBottom: 8
  },
  appBar: {
    backgroundColor: '#002d72',
    zIndex: theme.zIndex.drawer + 1
  },
  chip: {
    margin: 1
  }
})

function Transition(props) {
  return <Slide direction="up" {...props} />
}

class EditUserDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      formUser: {
        name: '',
        lastname: '',
        email: '',
        gender: '',
        speciality: []
      },
      selectedOption: null,
      selectedOptionGender: null
    }
  }

  handleCancel = () => {
    this.props.onClose()
  }

  handleOk = () => {
    this.props.onClose()
  }

  changeNote = anotacao => {
    this.setState({ anotacao })
  }

  renderSpinner = () => {
    const { classes } = this.props
    return <CircularProgress className={classes.progress} size={30} />
  }

  changeUser = (event = {}) => {
    const { target = {} } = event
    const { value, name } = target
    this.setState(state => ({
      formUser: {
        ...state.formUser,
        [name]: value
      }
    }))
  }

  validatesForm = ({ name, lastname, email, gender }) => {
    const errors = {}

    if (!name) {
      errors.name = 'Informe seu nome.'
    }

    if (!lastname) {
      errors.lastname = 'Informe seu sobrenome.'
    }

    if (!validators.email(email)) {
      errors.email = 'Informe um e-mail válido.'
    }

    if (!email) {
      errors.email = 'Informe seu e-mail.'
    }

    if (!gender) {
      errors.gender = 'Informe o gênero.'
    }

    return {
      errors,
      valid: JSON.stringify(errors) === '{}'
    }
  }

  handleChange = selectedOption => {
    let onlyIdsSpecialties = []

    for (const specialty of selectedOption) {
      onlyIdsSpecialties.push(specialty.value)
    }

    this.setState(state => ({
      formUser: {
        ...state.formUser,
        speciality: onlyIdsSpecialties
      }
    }))

    this.setState({ selectedOption })
  }

  handleChangeGender = selectedOptionGender => {
    this.setState(state => ({
      formUser: {
        ...state.formUser,
        gender: selectedOptionGender.value
      }
    }))

    this.setState({ selectedOptionGender })
  }

  renderUserForm = () => {
    const { classes } = this.props
    let { formUser: user = {}, selectedOptionGender } = this.state
    const { errors } = this.validatesForm(user)

    const generos = [
      {
        value: 'M',
        label: 'Masculino'
      },
      {
        value: 'F',
        label: 'Feminino'
      },
      {
        value: 'O',
        label: 'Outros'
      }
    ]

    selectedOptionGender = generos.filter(item => item.value === user.gender)

    return (
      <form className={classes.form}>
        <FormControl fullWidth error={errors.name} className={classes.formControl}>
          <InputLabel htmlFor="user-name">Nome</InputLabel>
          <Input id="user-name" name={'name'} value={user.name} onChange={this.changeUser} />
          {errors.name && <FormHelperText>{errors.name}</FormHelperText>}
        </FormControl>

        <FormControl fullWidth error={errors.lastname} className={classes.formControl}>
          <InputLabel htmlFor="user-lastname">Sobrenome</InputLabel>
          <Input
            id="user-lastname"
            name={'lastname'}
            value={user.lastname}
            onChange={this.changeUser}
          />
          {errors.lastname && <FormHelperText>{errors.lastname}</FormHelperText>}
        </FormControl>

        <FormControl fullWidth className={classes.formControl}>
          <div style={{ position: 'relative', marginTop: '-12px' }}>
            <InputLabel htmlFor="user-gender" style={{ fontSize: 12 }}>
              Género
            </InputLabel>
            <div style={{ marginTop: '45px' }}>
              <Select
                value={selectedOptionGender}
                onChange={this.handleChangeGender}
                options={generos}
              />
            </div>
          </div>
        </FormControl>

        <Query query={specialtiesQueries.GET_SPECIALTIES} fetchPolicy={'cache-and-network'}>
          {({ loading, error, data = {} }) => {
            const { specialties = [] } = data
            let { selectedOption } = this.state
            let specialtiesSeleted = []

            if (((user || {}).speciality || []).length) {
              for (const specialty of user.speciality) {
                specialtiesSeleted.push(
                  specialties.filter(item => item.value === specialty)[0] || []
                )
              }
              selectedOption = specialtiesSeleted
            }

            return (
              <FormControl fullWidth className={classes.formControl}>
                <div style={{ position: 'relative', marginTop: '-12px' }}>
                  <InputLabel htmlFor="user-specialties" style={{ fontSize: 12 }}>
                    Especialidades
                  </InputLabel>
                  <div style={{ marginTop: '45px' }}>
                    <Select
                      placeholder={'Especialidades...'}
                      isMulti
                      value={selectedOption}
                      onChange={this.handleChange}
                      options={specialties}
                    />
                  </div>
                </div>
              </FormControl>
            )
          }}
        </Query>
      </form>
    )
  }

  handleEntering = () => {
    const { user } = this.props
    this.setState({
      formUser: user,
      errorMessage: ''
    })
  }

  openErrorMessage = message => {
    this.setState({
      errorMessage: message
    })
  }

  onSuccess = () => {
    this.props.onSuccess('Perfil alterado com sucesso.')
  }

  closeErrorMessage = () => {
    this.setState({
      errorMessage: ''
    })
  }

  renderUser = ({ error, loading }) => {
    if (loading) {
      return this.renderSpinner()
    }

    return this.renderUserForm()
  }

  handleSubmit = ({ updateUser, formUser }) => {
    if (this.validatesForm(formUser).valid) {
      updateUser({
        variables: {
          name: formUser.name,
          lastname: formUser.lastname,
          email: formUser.email,
          gender: formUser.gender,
          speciality: formUser.speciality
        }
      })
    } else {
      this.openErrorMessage('Preencha todos os campos corretamente')
    }
  }

  render() {
    const { value, searchOrder, classes, message, ...other } = this.props
    const { formUser, errorMessage } = this.state

    return (
      <Mutation
        mutation={userMutations.UPDATE_USER}
        update={(cache, { data: updateUser }) => {
          if (!updateUser) return
          const { user } = cache.readQuery({
            query: userQueries.GET_USER
          })

          cache.writeQuery({
            query: userQueries.GET_USER,
            data: {
              user: { ...user, ...formUser }
            }
          })
          this.onSuccess()
        }}
        onError={errors => {
          this.openErrorMessage('Parâmetros inválidos')
        }}
      >
        {(updateUser, { loading, error, data }) => {
          return (
            <Dialog
              maxWidth="xs"
              fullScreen
              onEntering={this.handleEntering}
              aria-labelledby="confirmation-dialog-title"
              TransitionComponent={Transition}
              className={classes.root}
              {...other}
            >
              <AppBar className={classes.appBar}>
                <Toolbar disableGutters={true} className={classNames(classes.toolbar, 'container')}>
                  <IconButton color="inherit" onClick={this.props.onClose} aria-label="Close">
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="title" color="inherit" className={classes.flex}>
                    Alterar dados
                  </Typography>
                  <div className={classes.toolBarRight}>
                    <Button
                      color="inherit"
                      disabled={loading}
                      style={{ color: loading ? 'rgba(255,255,255,.4)' : '#fff' }}
                      onClick={() => {
                        this.handleSubmit({ updateUser, formUser })
                      }}
                    >
                      Salvar
                    </Button>
                  </div>
                </Toolbar>
              </AppBar>
              <OnlyOnline>
                <DialogContent className={classNames(classes.content, 'container')}>
                  <div className={classes.toolbar} />
                  {this.renderUser({ error, loading })}
                </DialogContent>
              </OnlyOnline>
              <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={3500}
                onClose={() => {
                  this.closeErrorMessage()
                }}
                open={!!errorMessage}
                ContentProps={{
                  'aria-describedby': 'login-error'
                }}
                message={<span id="login-error">{errorMessage}</span>}
              />
            </Dialog>
          )
        }}
      </Mutation>
    )
  }
}

export default withStyles(styles)(EditUserDialog)
