export const exactSearch = term => {
  if (!term) return (term || '').trim()

  const resettedPhrase = (term || '').replace(/"/g, '')
  const phrase = resettedPhrase.replace(',', '').trim()

  return `"${phrase}"`
}

export const orSearch = term => {
  if (!term) return (term || '').trim()

  const words = (term || '')
    .replace()
    .replace(/\s+(OR|\|)\s+/g, ' ')
    .trim()

  const resettedWords = words.match(/[\u0021-\u00FF]+|"[^"]+"/g)

  const final = resettedWords.join().replace(/,/g, ' OR ')

  return final.trim()
}

export const notSearch = term => {
  if (!term) return (term || '').trim()

  const words = (term || '').replace(',', '').trim()
  const resettedWords = words
    .replace(/NOT|-/g, ' ')
    .replace(/\s+/g, ' ')
    .trim()

  const formattedWords = resettedWords
    .split(' ')
    .map(newWord => `-${newWord}`)
    .join(' ')

  return (formattedWords || '').trim()
}
