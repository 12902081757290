import { searchActions } from '../actions'
const {
  CHANGE_TERM,
  CHANGE_ORDER,
  CHANGE_FILTER,
  RESET_FILTER,
  CHANGE_SPECIALTY,
  FILTER_LENGHT,
  CHANGE_READING_TIME,
  CHANGE_CLOSE_POPPER,
} = searchActions

const getIinitialState = () => ({
  term: '',
  order: '',
  filter: {
    range: '',
    filter:
      'diagnostico_e_tratamento,artigo,capitulo,consulta_rapida_e_recomendacoes,aula,podcast,habilidades_clinicas,webinar,medicamento,figura,fluxograma,tabela,diretriz,cid,cid10,cid11',
    specialty: 0,
  },
  readingTime: [],
  filterLenght: 0,
  popperOpen: false,
})

const categories = (state = getIinitialState(), action = '') => {
  switch (action.type) {
    case CHANGE_TERM:
      return { ...state, term: action.term }
    case CHANGE_ORDER:
      return { ...state, order: action.order }
    case CHANGE_FILTER:
      return { ...state, filter: action.filter }
    case RESET_FILTER:
      return {
        ...state,
        filter: { ...getIinitialState().filter, filter: action.filter },
        readingTime: state.readingTime.map((option) => ({ ...option, checked: true })),
      }
    case CHANGE_SPECIALTY:
      return { ...state, filter: { ...state.filter, specialty: action.specialty } }
    case FILTER_LENGHT:
      return { ...state, filterLenght: action.filterLenght }
    case CHANGE_READING_TIME:
      return { ...state, readingTime: action.readingTime }
    case CHANGE_CLOSE_POPPER:
      return { ...state, popperOpen: action.popper }
    default:
      return state
  }
}

export default categories
