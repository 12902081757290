const regexes = {
  and: /.*/g,
  exact: /"(.*?)"/g,
  or: /([\u0021-\u00FF]*|(\"[^\"]+\"))\s+(OR|\|)\s+((".*?")|[\u0021-\u00FF]*)/g,
  not: /((NOT ?)|-)[\u0021-\u00FF]*/g
}

const andSearch = term => {
  const words = (term || '')
    .replace(regexes.or, '')
    .replace(regexes.exact, '')
    .replace(regexes.not, '')
    .match(regexes.and)

  if (!words) return ''

  return words.join(' ').trim()
}

const exactSearch = term => {
  const exactPhrases = (term || '').replace(regexes.or, '').match(regexes.exact)

  if (!exactPhrases) return ''

  return exactPhrases
    .join(' ')
    .replace(/"/g, '')
    .trim()
}

const orSearch = term => {
  const words = (term || '').match(regexes.or)

  if (!words) return ''

  return words
    .join(' ')
    .replace(/OR|\|/g, '')
    .replace(/\s+/g, ' ')
    .trim()
}

const notSearch = term => {
  const words = (term || '').match(regexes.not)

  if (!words) return ''

  return words
    .join(' ')
    .replace(/NOT|-/g, '')
    .trim()
}

export const unformattedValues = term => {
  return {
    and: andSearch(term),
    exact: exactSearch(term),
    or: orSearch(term),
    not: notSearch(term)
  }
}
