import React from 'react'
import MicIcon from '@material-ui/icons/Mic'
import withStyles from '@material-ui/core/styles/withStyles'

const SpeechToTextIcon = withStyles({
  root: {
    position: 'absolute',
    top: 12,
    bototm: 0,
    right: 48,
    background: 'transparent',
    border: 0,
    padding: 0,
  },
})(
  ({
    classes,
    children,
    searchTerm,
    enabledSpeech,
    micIcon,
    changePopper,
    ...rest
  }) => (
    <button
      {...rest}
      onClick={() => {
        changePopper()
        enabledSpeech()
      }}
      className={classes.root}
      style={{ display: searchTerm ? 'none' : 'flex' }}
    >
      <MicIcon style={{ color: micIcon() }} />
    </button>
  )
)

export default SpeechToTextIcon
