import React, { useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import SubscriptionDialog from './SubscriptionDialog'

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    marginBottom: 15,
    background: '#5AB9F7',
    maxHeight: 65,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 4,
    opacity: 1,
    width: '40%',
    '@media only screen and (max-width: 435px)': {
      width: '55%',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  text: {
    color: '#FFFFFF',
    display: 'flex',
    fontSize: 14,
    minHeight: 42,
    fontWeight: 'bold',
    alignItems: 'center',
    maxWidth: 165,
    '@media only screen and (max-width: 290px)': {
      fontSize: 13,
    },
  },
  signatureButton: {
    background: '#124397',
    maxHeight: 42,
    height: '100%',
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: 'bold',
    maxWidth: 184,
    float: 'right',
    '&, &:hover, &:focus': {
      background: '#124397',
    },
    '@media only screen and (max-width: 290px)': {
      fontSize: 13,
    },
  },
})

const SubscriptionBox = (props) => {
  const { classes, daysRemaining } = props
  const [showSubscriptionDialog, setSubscriptionDialog] = useState(false)

  // let textRemain = `ocê ainda tem ${daysRemaining}`

  return (
    <>
      <div className={classes.container}>
        <Paper className={classes.root} elevation={2}>
          <div style={{ display: 'flex' }}>
            <p className={classes.text}>Você ainda tem {daysRemaining} dias gratuitos</p>
          </div>
          <div style={{ display: 'flex', marginLeft: 8 }}>
            <Button
              className={classes.signatureButton}
              onClick={() => {
                setSubscriptionDialog(true)
              }}
            >
              Assine agora
            </Button>
          </div>
        </Paper>
      </div>
      <SubscriptionDialog
        open={showSubscriptionDialog}
        onClose={() => {
          setSubscriptionDialog(false)
        }}
      />
    </>
  )
}

export default withStyles(styles, { withTheme: true })(SubscriptionBox)
