import gql from 'graphql-tag'

export const GET_LATEST_CONTENTS = gql`
  query {
    latestContents {
      conteudos {
        _id
        tipo
        titulo
        principioAtivo
      }
    }
  }
`
