import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import contentTypeIcons from '../icons/contentTypeIcons'

const styles = theme => ({
  root: {
    boxSizing: 'border-box',
    width: 50,
    height: 48,
    flex: 'none',
    marginRight: 16,
    borderRadius: '100%',
    border: '2px solid #184496',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '40px'
    },
    background: '#184496'
  }
})

const ItemIcon = ({ classes, tipo }) => {
  return (
    <div className={classes.root}>
      <img src={contentTypeIcons[tipo]} alt={tipo} />
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(ItemIcon)
