import * as favoriteQueries from './favorite'
import * as contentQueries from './content'
import * as noteQueries from './note'
import * as userInfoQueries from './userInfo'
import * as authorQueries from './author'
import * as messagesQueries from './messages'
import * as userQueries from './user'
import * as notificationQueries from './notification'
import * as faqQueries from './faq'
import * as menuQueries from './menu'
import * as metatagsQueries from './metatags'
import * as feedbackQueries from './feedback'
import * as latestContentQueries from './latestContent'
import * as latestSearchQueries from './latestSearch'
import * as quotesQueries from './quotes'
import * as cidQueries from './cid'
import * as sessionQueries from './session'
import * as covidQueries from './covid'
import * as typeGroupQueries from './groupTypes'
import * as readingTimeQueries from './readingTime'
import * as specialtiesQueries from './specialties'

export {
  favoriteQueries,
  contentQueries,
  noteQueries,
  userInfoQueries,
  messagesQueries,
  authorQueries,
  userQueries,
  notificationQueries,
  faqQueries,
  menuQueries,
  metatagsQueries,
  feedbackQueries,
  latestContentQueries,
  latestSearchQueries,
  quotesQueries,
  cidQueries,
  sessionQueries,
  covidQueries,
  typeGroupQueries,
  readingTimeQueries,
  specialtiesQueries
}
