import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import SearchResultList from '../SearchResultList'
import OnlyOnline from '../../components/OnlyOnline'
import { connect } from 'react-redux'
import withApollo from 'react-apollo/withApollo'
import NotFoundPlaceholder from './NotFoundPlaceholder'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    background: '#F7F7F7',
    boxSizing: 'border-box',
    paddingBottom: 15
  },
  title: {
    color: '#505152',
    padding: 8,
    borderTop: '1px solid #e8e8e8',
    borderBottom: '1px solid #e8e8e8',
    paddingLeft: 22
  }
})

class Favorites extends Component {
  constructor(props) {
    super(props)
    this.client = null
  }

  render() {
    const { classes, searchTerm } = this.props
    return (
      <OnlyOnline>
        {!searchTerm ? (
          <div className={classes.root}>
            <NotFoundPlaceholder className={classes.root} />
          </div>
        ) : (
          <div className={classes.root}>
            <SearchResultList />
          </div>
        )}
      </OnlyOnline>
    )
  }
}

const mapStateToProps = state => {
  return {
    searchTerm: state.search.term
  }
}

export default connect(
  mapStateToProps,
  null
)(withStyles(styles, { withTheme: true })(withApollo(Favorites)))
