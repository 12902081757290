import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import classNames from 'classnames'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import { searchActions } from '../actions'
import { advancedSearch } from '../utils'

const styles = theme => ({
  paper: {
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper
  },
  root: {
    '& > div': {
      overflow: 'inherit'
    }
  },
  progress: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto'
  },
  content: {
    flex: 1,
    position: 'relative',
    marginTop: 50
  },
  closeButton: {
    position: 'absolute',
    top: -20,
    right: -20,
    '&, &:hover, &:focus': {
      background: '#229FFF',
      color: '#fff',
      width: 40,
      height: 40,
      marginLeft: 'auto'
    }
  },
  appBar: {
    backgroundColor: '#002d72',
    zIndex: theme.zIndex.drawer + 1
  },
  form: {
    marginTop: 8
  },
  formControl: {
    marginTop: 50,
    marginBottom: 8
  },
  label: {
    fontWeight: 600,
    textTransform: 'lowercase'
  },
  instructions: {
    color: '#757575'
  },
  submitButton: {
    backgroundColor: '#229FFF',
    color: '#fff',
    width: '85%'
  }
})

function Transition(props) {
  return <Slide direction="up" {...props} />
}

class AdvancedSearchDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      andSearch: '',
      exactSearch: '',
      orSearch: '',
      notSearch: ''
    }
  }

  handleCancel = () => {
    this.props.onClose()
  }

  handleOk = () => {
    this.props.onClose()
  }

  handleAndSearch = (event = {}) => {
    const { target = {} } = event
    const { value } = target

    this.setState({ andSearch: value })
  }

  handleExactSearch = (event = {}) => {
    const { target = {} } = event
    const { value } = target

    this.setState({ exactSearch: value })
  }

  handleOrSearch = (event = {}) => {
    const { target = {} } = event
    const { value } = target

    this.setState({ orSearch: value })
  }

  handleNotSearch = (event = {}) => {
    const { target = {} } = event
    const { value } = target

    this.setState({ notSearch: value })
  }

  handleSubmit = () => {
    const exactTerm = advancedSearch.formatters.exactSearch(this.state.exactSearch) || ''
    const orTerm = advancedSearch.formatters.orSearch(this.state.orSearch) || ''
    const notTerm = advancedSearch.formatters.notSearch(this.state.notSearch) || ''

    const newTerm = `${this.state.andSearch} ${exactTerm} ${orTerm} ${notTerm}`.replace(/\s+/g, ' ')

    this.props.changeTerm(newTerm)
    this.props.onClose()
  }

  _setupSearchValues = values => {
    const andSearch = values.and
    const exactSearch = values.exact
    const orSearch = values.or
    const notSearch = values.not

    this.setState({ andSearch, exactSearch, orSearch, notSearch })
  }

  componentWillMount = () => {
    this._setupSearchValues(advancedSearch.unformatters.unformattedValues(this.props.searchTerm))
  }

  render() {
    const { value, classes, ...other } = this.props
    const { andSearch, exactSearch, orSearch, notSearch } = this.state
    const {
      handleAndSearch,
      handleExactSearch,
      handleOrSearch,
      handleNotSearch,
      handleSubmit
    } = this

    return (
      <Dialog
        maxWidth="xs"
        fullWidth
        fullScreen
        onEntering={this.handleEntering}
        aria-labelledby="confirmation-dialog-title"
        TransitionComponent={Transition}
        className={classes.root}
        {...other}
      >
        <AppBar className={classes.appBar}>
          <Toolbar disableGutters={true} className={classNames(classes.toolbar, 'container')}>
            <IconButton color="inherit" onClick={this.props.onClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="title" color="inherit" className={classes.flex}>
              Pesquisa Avançada
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.content}>
          <form
            class={classNames(classes.form, 'container')}
            onSubmit={e => {
              e.preventDefault()
              handleSubmit()
            }}
          >
            <FormControl fullWidth className={classes.formControl}>
              <Typography color="inherit" className={classes.label}>
                Todas essas palavras
              </Typography>
              <Input
                name={'andSearch'}
                type={'text'}
                value={andSearch}
                onChange={handleAndSearch}
              />
              <Typography color="inherit" className={classes.instructions}>
                Digite as palavras importantes: fibrilação atrial
              </Typography>
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
              <Typography color="inherit" className={classes.label}>
                Esta expressão ou frase exata
              </Typography>
              <Input
                name={'andSearch'}
                type={'text'}
                value={exactSearch}
                onChange={handleExactSearch}
              />
              <Typography color="inherit" className={classes.instructions}>
                Coloque palavras ou frases exatas entre aspas: "fibrilação atrial"
              </Typography>
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
              <Typography color="inherit" className={classes.label}>
                Qualquer uma destas palavras
              </Typography>
              <Input name={'andSearch'} type={'text'} value={orSearch} onChange={handleOrSearch} />
              <Typography color="inherit" className={classes.instructions}>
                Digite OR entre todas as palavras que você procura: fibrilação OR atrial
              </Typography>
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
              <Typography color="inherit" className={classes.label}>
                Nenhuma destas palavras
              </Typography>
              <Input
                name={'andSearch'}
                type={'text'}
                value={notSearch}
                onChange={handleNotSearch}
              />
              <Typography color="inherit" className={classes.instructions}>
                Coloque um sinal de menos antes das palavras que você não deseja: -fibrilação
                -atrial
              </Typography>
            </FormControl>
            <FormControl
              fullWidth
              className={classes.formControl}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Button type="submit" variant="contained" className={classes.submitButton}>
                Aplicar
              </Button>
            </FormControl>
          </form>
        </DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = state => {
  return {
    searchTerm: state.search.term
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeTerm: term => {
      dispatch(searchActions.changeTerm(term))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AdvancedSearchDialog))
