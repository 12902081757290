import gql from 'graphql-tag'

export const SEARCH_CONTENT = gql`
  query covid($term: String!, $offset: Int, $specialty: SpecialtyEnum) {
    covid(term: $term, offset: $offset, specialty: $specialty) {
      total
      items {
        id
        tipo
        titulo
        autores
        autoresDiretriz
        highlight
        descricao
        tituloCapitulo
        tituloLivro
        score
        copyright
        minutosLeitura
      }
    }
  }
`

export const GET_CONTENT = gql`
  query covidContent($id: String!) {
    covidContent(_id: $id) {
      tipo
      titulo
      principioAtivo
      instituicao
      autoresDiretriz
      conteudoHtml
      descricao
      url
      tituloCurso
      codigo
      videoURI
      videoPoster
      deletedAt
      avaliacaoMedia
      detalhesAula
      minutosLeitura
      autores {
        _id
        nome
      }
    }

    metatag(chave: "disclaimer") {
      valor
    }
  }
`

export const GET_CONTENT_TITLE = gql`
  query covidContent($id: String!) {
    covidContent(_id: $id) {
      titulo
      principioAtivo
    }
  }
`
