import React from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import AppBar from './AppBar'
import MessagesDrawer from '../MessagesDrawer'
import AppMenuDrawer from '../AppMenuDrawer'

import { BaseRoutes } from '../../routes'
import withRouter from 'react-router-dom/withRouter'
import { connect } from 'react-redux'
import { drawerActions } from '../../actions'
import SearchBarLayout from './SearchBar'
import { googleAnalytics } from '../../utils'

const styles = (theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
})

const Wrapper = withStyles({
  root: {
    flexGrow: 1,
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    boxSizing: 'border-box',
  },
})(({ classes, children, ...rest }) => {
  return (
    <div
      {...rest}
      className={classNames(classes.root, 'transition-item list-page')}
      style={{ background: '#f7f7f7' }}
    >
      {children}
    </div>
  )
})

const ChildrenWrapper = withStyles({
  childrenWrapper: {
    width: '100%',
    height: 'calc(100% - 55px)',
    '-webkit-overflow-scrolling': 'touch',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '@media only screen and (max-width: 900px) and (orientation: landscape)': {
      height: 'calc(100% - 47px)',
    },
  },
})(({ classes, children }) => {
  return (
    <div id={'infinite-scroll-wrapper'} className={classNames(classes.childrenWrapper)}>
      {children}
    </div>
  )
})

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  handleDrawerOpen = () => {
    this.setState({ open: true })
  }

  handleDrawerClose = () => {
    this.setState({ open: false })
  }

  getMainContentStyles = () => {
    const { safeArea = true } = this.props
    const styles = {}
    if (!safeArea) {
      styles.padding = 0
    }
    Object.freeze(styles)
    return styles
  }
  toggleDrawer = (open) => () => {
    this.setState({
      open,
    })
  }

  handleDrawerMessageClose = () => {
    this.setState({ messagesOpen: false })
  }

  showSearchBar = () => {
    const { location = {} } = this.props
    const { pathname } = location

    const pathNames = [
      '/duvidas-frequentes',
      '/tutorial',
      '/curadores',
      '/termos-de-uso',
      '/politica-de-privacidade',
      '/cid',
      '/medicamentos',
    ]

    return !pathNames.find((path) => path === pathname)
  }

  toggleDrawerMessage = (messagesOpen) => () => {
    this.setState({
      messagesOpen,
    })
    googleAnalytics('acao_boletim_item_janela_del')
  }

  handleDrawerMessageOpen = () => {
    googleAnalytics('acao_boletim', 'home', 'Se clica para abrir menu boletins', 'Clique')
    this.setState({ messagesOpen: true })
  }

  render() {
    const { classes, location } = this.props

    const showSearchBar = this.showSearchBar()

    const mainContentStyles = this.getMainContentStyles()

    return (
      <Wrapper>
        {location.pathname !== '/tutorial' && (
          <AppBar
            handleMenuOpen={this.props.openMenuDrawer}
            handleDrawerMessageOpen={this.handleDrawerMessageOpen}
          />
        )}
        {!this.state.open && (
          <MessagesDrawer
            classes={classes}
            open={this.state.messagesOpen}
            onClose={this.toggleDrawerMessage(false)}
            onOpen={this.toggleDrawerMessage(true)}
          />
        )}
        {!this.state.messagesOpen && (
          <AppMenuDrawer
            classes={classes}
            open={this.props.menuDrawerOpen}
            onClose={this.props.closeMenuDrawer}
            onOpen={this.props.openMenuDrawer}
          />
        )}
        <main className={classes.content} style={mainContentStyles}>
          <div className={classNames(classes.toolbar, 'hidden-print')} />
          <ChildrenWrapper>
            {!!showSearchBar && <SearchBarLayout />}
            <BaseRoutes />
          </ChildrenWrapper>
        </main>
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    menuDrawerOpen: state.drawer.menuDrawerOpen,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openMenuDrawer: () => {
      dispatch(drawerActions.openMenuDrawer())
    },
    closeMenuDrawer: () => {
      dispatch(drawerActions.closeMenuDrawer())
    },
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Layout))
)
