import React from 'react'
import Query from 'react-apollo/Query'
import withStyles from '@material-ui/core/styles/withStyles'
import ContentCopyIcon from '@material-ui/icons/ContentCopy'
import CircularProgress from '@material-ui/core/CircularProgress'
import { quotesQueries } from '../../graphql/queries'
import { connect } from 'react-redux'
import { snackbarActions } from '../../actions'

const styles = theme => ({
  wrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingBottom: 5
  },
  label: {
    marginRight: 5
  },
  contentCopyIcon: {
    width: 16
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    border: 0,
    backgroundColor: 'transparent',
    paddingLeft: 0,
    marginLeft: 0,
    cursor: 'pointer'
  }
})

const copyToClipboard = (element, callbackToRun) => {
  element.current.select()
  document.execCommand('copy')

  callbackToRun()
}

const BaseQuote = React.memo(props => {
  const { label, content, type, successMessage, onConfirm, classes, showSnackbar } = props
  let fakeTextarea = React.createRef()
  const isRTL = document.documentElement.getAttribute('dir') == 'rtl'

  const textAreaStyle = {
    fontSize: '12pt',
    border: '0',
    padding: '0',
    margin: '0',
    position: 'absolute',
    top: '12pt',
    fontSize: '12pt',
    fontSize: '12pt',
    [isRTL ? 'right' : 'left']: '-9999px',
    top: `${window.pageYOffset || document.documentElement.scrollTop}px`
  }

  return (
    <Query query={quotesQueries.GET_QUOTE} variables={{ content, type }}>
      {({ loading, error, data = {}, refetch }) => {
        if (loading) return <CircularProgress size={30} />
        if (error) return null

        const { quotes = {} } = data
        const reference = quotes.reference

        if (!reference) return null

        return (
          <div className={classes.wrapper}>
            <textarea ref={fakeTextarea} style={textAreaStyle} readOnly>
              {reference}
            </textarea>

            <button
              className={classes.button}
              onClick={() => {
                showSnackbar(successMessage)
                copyToClipboard(fakeTextarea, onConfirm)
              }}
            >
              <span className={classes.label}>{label}</span>
              <ContentCopyIcon className={classes.contentCopyIcon} color={'secondary'} />
            </button>
          </div>
        )
      }}
    </Query>
  )
})

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: message => {
      dispatch(snackbarActions.showSnackbar(message))
    }
  }
}

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(BaseQuote))
