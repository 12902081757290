import { searchCidActions } from '../actions'
const { CHANGE_TERM_CID, CHANGE_FILTER_CID, RESET_FILTER_CID } = searchCidActions

const getInitialState = () => ({
  term: '',
  filter: { cid10: true, cid11: false }
})

const searchCid = (state = getInitialState(), action = '') => {
  switch (action.type) {
    case CHANGE_TERM_CID:
      return { ...state, term: action.term }
    case CHANGE_FILTER_CID:
      return { ...state, filter: action.filter }
    case RESET_FILTER_CID:
      return { ...state, filter: getInitialState().filter }
    default:
      return state
  }
}

export default searchCid
