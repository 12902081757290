import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import ErrorOutlineIcon from '@material-ui/icons/CloudOff'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const styles = theme => ({
  root: {
    display: 'flex',
    height: '100%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  icon: {
    fontSize: 70,
    color: '#757575'
  }
})

const ErrorHandling = ({ error, errorMessage, classes, refetch }) => {
  let errorTitle, refreshButtonText
  ;((error || {}).graphQLErrors || []).forEach(err => {
    if (err.message === 'AlreadyLoggedIn') {
      errorTitle = 'É permitido apenas um acesso por conta.'
      errorMessage = 'Parece que você já está utilizando o InforMED em outro dispositivo.'
      refreshButtonText = 'Atualizar'
    } else if (err.message) {
      errorTitle = 'Ocorreu um erro.'
      errorMessage = err.message
      refreshButtonText = 'Atualizar'
    }
  })

  return (
    <div className={classes.root}>
      <ErrorOutlineIcon className={classes.icon} />
      <Typography gutterBottom className={classes.title} variant={'title'}>
        {errorTitle || 'Ooops'}
      </Typography>
      <Typography
        gutterBottom
        className={classes.title}
        style={{ fontSize: 16, textAlign: 'center' }}
      >
        {errorMessage || 'Estamos com problemas.'}
      </Typography>
      <Button
        color="primary"
        onClick={() => {
          refetch()
        }}
        className={classes.button}
      >
        {refreshButtonText || 'Tentar novamente'}
      </Button>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(ErrorHandling)
