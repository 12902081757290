import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import SearchResultList from './SearchResultList'
import OnlyOnline from '../../components/OnlyOnline'
import SearchBarCid from './SearchBar'
import { connect } from 'react-redux'
import { searchCidActions } from '../../actions'
import CidPlaceholder from './CidPlaceholder'

const styles = theme => ({
  root: {
    width: '100%',
    flex: 1,
    display: 'flex',
    minHeight: '100%',
    background: '#F7F7F7',
    boxSizing: 'border-box',
    flexDirection: 'column',
    paddingBottom: 15,
    minHeight: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  header: {
    marginTop: 20,
    marginBottom: 20,
    paddingLeft: 20,
    paddingRight: 20
  },
  wrapperPanels: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20
  },
  progressWrapper: {
    height: 'calc(100vh - 54px)',
    position: 'relative'
  },
  progress: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto'
  },
  container: {
    paddingLeft: 20,
    paddingRight: 20
  },
  videoWrapper: {
    overflow: 'hidden',
    height: 0,
    paddingTop: '56.25%',
    position: 'relative',
    marginLeft: 20,
    marginRight: 20
  },
  iframe: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%'
  }
})

const Cid = props => {
  const { classes, searchTerm } = props
  return (
    <OnlyOnline>
      <>
        <SearchBarCid />
        <div className={classes.root}>
          {!searchTerm ? (
            <div className={classes.root}>
              <CidPlaceholder />
            </div>
          ) : (
            <div className={classes.root}>
              <SearchResultList />
            </div>
          )}
        </div>
      </>
    </OnlyOnline>
  )
}

const mapStateToProps = state => {
  return {
    searchTerm: state.searchCid.term
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeTerm: term => {
      dispatch(searchCidActions.changeTerm(term))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Cid))
