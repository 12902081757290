import { connect } from 'react-redux'
import SearchResultList from './SearchResultList'
import withDebouncedProps from '../../components/withDebouncedProps'

const options = {
  props: [{ name: 'searchTerm', delay: 500, defaultValue: '' }]
}

const mapStateToProps = state => {
  return {
    searchTerm: state.search.term
  }
}

export default connect(
  mapStateToProps,
  null
)(withDebouncedProps(SearchResultList, options))
