import React, { Component } from 'react'
import withRouter from 'react-router-dom/withRouter'
import Route from 'react-router-dom/Route'
import { AnimatedSwitch, spring } from 'react-router-transition'

import {
  Login,
  NotFound,
  Favorites,
  Faq,
  Notes,
  Credits,
  PrivacyPolicy,
  TermsOfUse,
  Home,
  Cid
} from '../containers'

const bounceTransition = {
  // start in a transparent, upscaled state
  atEnter: {
    opacity: 0
    // transform: 100
  },
  // leave in a transparent, downscaled state
  atLeave: {
    opacity: bounce(1)
    // transform: -100
  },
  // and rest at an opaque, normally-scaled state
  atActive: {
    opacity: bounce(1)
    // transform: 0
  }
}

function mapStyles(styles) {
  return {
    opacity: styles.opacity
    // transform: `translateX(${styles.transform}%)`
  }
}

// wrap the `spring` helper to use a bouncy config
function bounce(val) {
  return spring(val, {
    stiffness: 170,
    damping: 17
  })
}

class BaseRoutes extends Component {
  render() {
    return (
      <AnimatedSwitch
        atEnter={bounceTransition.atEnter}
        atLeave={bounceTransition.atLeave}
        atActive={bounceTransition.atActive}
        mapStyles={mapStyles}
        location={this.props.location}
        className="switch-wrapper switch-wrapper-search"
      >
        <Route exact path="/anotacoes" component={Notes} />
        <Route exact path="/cid" component={Cid} />
        <Route exact path="/duvidas-frequentes" component={Faq} />
        <Route exact path="/curadores" component={Credits} />
        <Route exact path="/politica-de-privacidade" component={PrivacyPolicy} />
        <Route exact path="/termos-de-uso" component={TermsOfUse} />
        <Route exact path="/alterar-dados" component={Login} />
        <Route exact path="/favoritos" component={Favorites} />
        <Route exact path="/" component={Home} />
        <Route component={NotFound} />
      </AnimatedSwitch>
    )
  }
}

export default withRouter(BaseRoutes)
