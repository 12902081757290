import InfiniteScroll from 'react-infinite-scroller'

export default class SearchResultListScroll extends InfiniteScroll {
  getParentElement(el) {
    return document.getElementById('infinite-scroll-wrapper')
  }

  render() {
    return super.render()
  }
}
