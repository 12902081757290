import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import bookmarkIcon from '../../../icons/svg/bookmark.svg'
import penIcon from '../../../icons/svg/pen.svg'
import contentTypeIcons from '../../../icons/contentTypeIcons'
import { userInfoQueries } from '../../../graphql/queries'

const styles = theme => ({
  root: {
    boxSizing: 'border-box',
    width: 48,
    height: 48,
    flex: 'none',
    marginRight: 16,
    borderRadius: '100%',
    border: '2px solid #184496',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    background: '#184496'
  },
  typeImage: {
    width: '40px'
  },
  imageFavorite: {
    width: 16,
    position: 'absolute',
    right: 0,
    bottom: -10
  },
  imageNote: {
    width: 16,
    position: 'absolute',
    left: 0,
    bottom: -10
  }
})

const ResultItemIcon = ({ classes, tipo }) => {
  return (
    <div className={classes.root}>
      <img className={classes.typeImage} src={contentTypeIcons[tipo]} alt={tipo} />
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(ResultItemIcon)
