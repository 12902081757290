import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Query from 'react-apollo/Query'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import classNames from 'classnames'
import CloseIcon from '@material-ui/icons/Close'
import CircularProgress from '@material-ui/core/CircularProgress'
import { noteQueries, userInfoQueries } from '../graphql/queries'
import TextField from '@material-ui/core/TextField'
import { noteMutations } from '../graphql/mutations'
import DeleteButton from '../components/DeleteButton'
import Mutation from 'react-apollo/Mutation'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import * as loggerClient from '../services/loggerClient'
import { googleAnalytics } from '../utils'

const styles = (theme) => ({
  paper: {
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    '& > div': {
      overflow: 'inherit',
    },
  },
  progress: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto',
  },
  content: {
    flex: 1,
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: -20,
    right: -20,
    '&, &:hover, &:focus': {
      background: '#229FFF',
      color: '#fff',
      width: 40,
      height: 40,
      marginLeft: 'auto',
    },
  },
  textField: {
    width: '100%',
    height: '100%',
    border: 'none',
    outline: 'none',
    lineHeight: 1.3,
    resize: 'none',
    boxSizing: 'border-box',
  },
  appBar: {
    backgroundColor: '#002d72',
    zIndex: theme.zIndex.drawer + 1,
  },
  toolBarRight: {
    marginLeft: 'auto',
    margin: '0 8px',
  },
  form: {
    flex: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
})

function Transition(props) {
  return <Slide direction="up" {...props} />
}

class NoteDialog extends React.Component {
  constructor(props) {
    super(props)
    this.anotacaoInputRef = React.createRef()
  }

  handleCancel = () => {
    this.props.onClose()
    googleAnalytics(
      'acao_ctdo_menu_back',
      'menu superior da integra do conteudo',
      'Se clica na opcao X - para voltar a home - em anotações',
      'Clique'
    )
  }

  handleOk = () => {
    this.props.onClose()
  }

  renderNote = ({ loading, error, data = {}, addingNote }) => {
    const { classes } = this.props
    const { note = {} } = data
    if (loading || addingNote) {
      return <CircularProgress className={classes.progress} size={30} />
    }

    return (
      <div style={{ height: '100%' }}>
        <textarea
          placeholder="Insira suas anotações"
          autoFocus
          defaultValue={note.anotacao || ''}
          className={classes.textField}
          ref={this.anotacaoInputRef}
        />
      </div>
    )
  }

  logUpdateNote = ({ newNote, lastNote }) => {
    const { contentId = '', contentType = '' } = this.props
    if (!newNote) {
      return loggerClient.onInteract({
        evento: 'remocao',
        contexto: 'anotacoes',
        conteudo: contentId,
        tipo: contentType,
      })
    }

    if (!lastNote) {
      return loggerClient.onInteract({
        evento: 'inclusao',
        contexto: 'anotacoes',
        conteudo: contentId,
        tipo: contentType,
      })
    }

    return loggerClient.onInteract({
      evento: 'atualizacao',
      contexto: 'anotacoes',
      conteudo: contentId,
      tipo: contentType,
    })
  }

  render() {
    const { value, searchOrder, classes, contentId, ...other } = this.props

    return (
      <Dialog
        maxWidth="xs"
        fullWidth
        fullScreen
        onEntering={this.handleEntering}
        aria-labelledby="confirmation-dialog-title"
        TransitionComponent={Transition}
        className={classes.root}
        {...other}
      >
        <Query
          query={noteQueries.GET_NOTE}
          fetchPolicy={'cache-and-network'}
          variables={{ content: contentId }}
        >
          {({ loading, error, data = {} }) => {
            return (
              <Mutation
                mutation={noteMutations.UPDATE_NOTE}
                update={(cache, { data }) => {
                  if (!data.updateNote) return
                  const { notes } = cache.readQuery({
                    query: userInfoQueries.GET_USER_INFOS,
                  })

                  if (data.updateNote.anotacao) {
                    cache.writeQuery({
                      query: userInfoQueries.GET_USER_INFOS,
                      data: {
                        notes: notes.find(
                          (note) => note.conteudo._id === data.updateNote.conteudo._id
                        )
                          ? notes.map((note) => {
                              if (note.conteudo._id === data.updateNote.conteudo._id) {
                                return data.updateNote
                              }
                              return note
                            })
                          : notes.concat(data.updateNote),
                      },
                    })
                  } else {
                    cache.writeQuery({
                      query: userInfoQueries.GET_USER_INFOS,
                      data: {
                        notes: notes.filter(
                          (note) => note.conteudo._id !== data.updateNote.conteudo._id
                        ),
                      },
                    })
                  }

                  this.handleOk()
                }}
              >
                {(updateNote, { loading: addingNote }) => {
                  return (
                    <React.Fragment>
                      <AppBar className={classes.appBar}>
                        <Toolbar
                          disableGutters={true}
                          className={classNames(classes.toolbar, 'container')}
                        >
                          <IconButton
                            color="inherit"
                            onClick={() => {
                              googleAnalytics(
                                'acao_ctdo_menu_back',
                                'menu superior da integra do conteudo',
                                'Se clica na opcao X - para voltar a home - em anotações',
                                'Clique'
                              )
                              this.props.onClose()
                            }}
                            aria-label="Close"
                          >
                            <CloseIcon />
                          </IconButton>
                          <Typography variant="title" color="inherit" className={classes.flex}>
                            Anotações
                          </Typography>
                          <div className={classes.toolBarRight}>
                            <Button
                              color="inherit"
                              disabled={loading}
                              style={{ color: loading ? 'rgba(255,255,255,.4)' : '#fff' }}
                              onClick={(e) => {
                                e.preventDefault()
                                this.logUpdateNote({
                                  newNote: this.anotacaoInputRef.current.value,
                                  lastNote: (data.note || {}).anotacao,
                                })
                                updateNote({
                                  variables: {
                                    content: contentId,
                                    note: this.anotacaoInputRef.current.value,
                                  },
                                })
                                googleAnalytics(
                                  'acao_ctdo_menu_note_save',
                                  'menu superior da integra do conteudo',
                                  'Se clica na opcao salvar anotações',
                                  'Clique'
                                )
                              }}
                            >
                              Salvar
                            </Button>
                          </div>
                        </Toolbar>
                      </AppBar>
                      <form
                        class={classNames(classes.form, 'container')}
                        disabled={loading || error}
                        onSubmit={(e) => {
                          e.preventDefault()
                          this.logUpdateNote({
                            newNote: this.anotacaoInputRef.current.value,
                            lastNote: (data.note || {}).anotacao,
                          })
                          updateNote({
                            variables: {
                              content: contentId,
                              note: this.anotacaoInputRef.current.value,
                            },
                          })
                        }}
                      >
                        <DialogTitle id="confirmation-dialog-title">Anotações</DialogTitle>
                        <DialogContent className={classes.content}>
                          {this.renderNote({ loading, error, data, addingNote })}
                        </DialogContent>
                        <DialogActions>
                          {(data.note || {}).anotacao ? (
                            <DeleteButton
                              onClick={() => {
                                googleAnalytics(
                                  'acao_ctdo_menu_note_del',
                                  'menu superior da integra do conteudo',
                                  'Se clica na opcao excluir anotações',
                                  'Clique'
                                )
                              }}
                              time={3000}
                              callback={() => {
                                this.logUpdateNote({
                                  newNote: '',
                                  lastNote: (data.note || {}).anotacao,
                                })
                                updateNote({
                                  variables: {
                                    content: contentId,
                                    note: '',
                                  },
                                })
                              }}
                            />
                          ) : (
                            <Button onClick={this.handleCancel} color={'default'}>
                              Cancelar
                            </Button>
                          )}

                          <Button disabled={loading} type={'submit'} color="primary">
                            Salvar
                          </Button>
                        </DialogActions>
                      </form>
                    </React.Fragment>
                  )
                }}
              </Mutation>
            )
          }}
        </Query>
      </Dialog>
    )
  }
}

export default withStyles(styles)(NoteDialog)
