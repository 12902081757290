import React from 'react'
import Query from 'react-apollo/Query'
import withStyles from '@material-ui/core/styles/withStyles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import { latestSearchQueries } from '../graphql/queries'
import * as textUtils from '../utils/text'

const styles = theme => ({
  wrapper: {
    width: '100%',
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    top: 10
  },
  box: {
    display: 'flex',
    flex: 1,
    maxHeight: 175,
    borderRadius: 5,
    overflow: 'auto'
  },
  textWrapper: {
    flex: 1,
    flexDirection: 'column',
    marginLeft: 15,
    marginRight: 15,
    marginTop: 10,
    marginBottom: 10
  },
  label: {
    backgroundColor: 'transparent',
    border: 0,
    color: '#757575',
    fontSize: 16,
    height: 30,
    display: 'block',
    textAlign: 'left',
    cursor: 'pointer',
    paddingLeft: 0
  },
  error: {
    color: '#757575',
    fontSize: 16,
    textAlign: 'left'
  }
})

const LatestSearchesBox = ({ classes, shouldShowBox, runSearch }) => {
  if (!shouldShowBox) return null

  return (
    <div className={classes.wrapper}>
      <Paper elevation={4} className={classes.box}>
        <div className={classes.textWrapper}>
          <Query query={latestSearchQueries.GET_LATEST_SEARCHES} fetchPolicy={'no-cache'}>
            {({ loading, error, data }) => {
              if (loading)
                return (
                  <CircularProgress
                    size={30}
                    style={{ marginTop: 5, marginLeft: 10, marginBottom: 0 }}
                  />
                )
              if (error) return <span className={classes.error}>Ocorreu um erro</span>

              const { latestSearches } = data
              const searchesToRender = latestSearches.buscas || []

              if (!searchesToRender.length)
                return <span className={classes.error}>Nenhuma busca recente</span>

              return (
                <>
                  {searchesToRender.reverse().map((searchTerm, index) => {
                    return (
                      <button
                        key={index}
                        className={classes.label}
                        onClick={() => runSearch(searchTerm)}
                      >
                        {textUtils.truncateEllipsis(searchTerm, 100)}
                      </button>
                    )
                  })}
                </>
              )
            }}
          </Query>
        </div>
      </Paper>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(LatestSearchesBox)
