import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import LatestContentsIcon from '../../icons/svg/latest_contents.svg'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    minHeight: '100%',
    flex: 1,
    paddingTop: 20,
    paddingBottom: 20
  },
  placeholderTitle: {
    color: '#5f7aa4',
    fontWeight: 400,
    maxWidth: '90%'
  },
  placeholderText: {
    fontSize: 16,
    maxWidth: 250,
    color: '#002D72',
    fontWeight: 'bold'
  },
  placeholderImage: {
    maxWidth: '100%',
    marginBottom: 36,
    width: 120,
    '& .cls-1': {
      opacity: 0.398
    },
    '& .cls-2': {
      fill: '#5f7aa4'
    },
    '& .cls-3': {
      fill: '#f2f2f2'
    },
    '& .cls-4, & .cls-5': {
      stroke: 'none'
    },
    '& .cls-5': {
      fill: '#002d72'
    }
  }
})

const HomePlaceholder = ({ classes }) => {
  return (
    <div className={classes.root}>
      <img
        className={classes.placeholderImage}
        src={LatestContentsIcon}
        alt={'Acessados recentemente'}
      />
      <Typography variant={'title'} gutterBottom className={classes.placeholderTitle}>
        Esta página ainda está vazia
      </Typography>
      <Typography className={classes.placeholderText}>
        Faça buscas por termos, artigos ou autores. Os últimos conteúdos acessados serão listados
        aqui
      </Typography>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(HomePlaceholder)
