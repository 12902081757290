import React, { useEffect } from 'react';

function DisablePrint() {
  useEffect(() => {
    const disableContextMenu = (e) => {
      e.preventDefault()
    };

    window.addEventListener('contextmenu', disableContextMenu)

    return () => {
      window.removeEventListener('contextmenu', disableContextMenu)
    }
  }, [])

  return null
}

export default DisablePrint;
