/* eslint-disable react/no-multi-comp */

import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { connect } from 'react-redux'
import { searchActions } from '../actions'

const options = [
  {
    label: 'Mais Recentes',
    value: 'recentes'
  },
  {
    label: 'Mais Antigos',
    value: 'antigos'
  },
  {
    label: 'Autores de A-Z',
    value: 'autores-asc'
  },
  {
    label: 'Autores de Z-A',
    value: 'autores-desc'
  },
  {
    label: 'Títulos de A-Z',
    value: 'titulo-asc'
  },
  {
    label: 'Títulos de Z-A',
    value: 'titulo-desc'
  }
]

class OrderDialog extends React.Component {
  radioGroupRef = null

  constructor(props) {
    super()
    this.state = {}
    this.state.value = props.value
  }

  handleEntering = () => {
    this.setState({ value: this.props.value })
    this.radioGroupRef.focus()
  }

  handleCancel = () => {
    const { onClose } = this.props
    onClose()
  }

  handleOk = () => {
    const { changeOrder, onClose } = this.props
    changeOrder(this.state.value)
    onClose(this.state.value)
  }

  handleChange = (event, value) => {
    this.setState({ value })
  }

  render() {
    const { value, searchOrder, changeOrder, ...other } = this.props

    return (
      <Dialog
        maxWidth="xs"
        onEntering={this.handleEntering}
        aria-labelledby="confirmation-dialog-title"
        {...other}
      >
        <DialogTitle id="confirmation-dialog-title">Ordenar por: </DialogTitle>
        <DialogContent>
          <RadioGroup
            ref={ref => {
              this.radioGroupRef = ref
            }}
            aria-label="Ringtone"
            name="ringtone"
            value={this.state.value}
            onChange={this.handleChange}
          >
            <FormControlLabel
              value={''}
              key={'order-relevancia'}
              control={<Radio />}
              label={'Relevância'}
            />
            {options.map(option => (
              <FormControlLabel
                value={option.value}
                key={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color="primary">
            Cancelar
          </Button>
          <Button onClick={this.handleOk} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

OrderDialog.propTypes = {
  onClose: PropTypes.func,
  value: PropTypes.string
}

const styles = theme => ({
  paper: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  }
})

const mapDispatchToProps = dispatch => {
  return {
    changeOrder: order => {
      dispatch(searchActions.changeOrder(order))
    }
  }
}

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(OrderDialog))
